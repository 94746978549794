import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

// @ts-ignore
import {ReactComponent as Graph} from "../../../img/ArgenCo_icon_graph_rgb.svg";
// @ts-ignore
import {ReactComponent as Plus} from "../../../img/ArgenCo_icon_plus_rgb.svg";
// @ts-ignore
import {ReactComponent as Windmill} from "../../../img/ArgenCo_icon_windmill_rgb.svg";
// @ts-ignore
import HeroImg from "../../../img/cut/ArgenCo_Waarom_Aandelen_Kopen_1024px.jpg";

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Cta,
  Footer,
  Hero,
  InputButton,
  Section,
  Waitlist
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const StockWhy: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [graphStopped, setGraphStopped] = useState(true);
  const [plusStopped, setPlusStopped] = useState(true);
  const [windmillStopped, setWindmillStopped] = useState(true);








  const whiteBox = (path:string, stopped:boolean, setStopped:(b:boolean) => void, Icon:any, title:string, text:string, cta:string) => {
    return <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: 4,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        paddingBottom: 8
      }}
      onClick={() => {
        navigate(path);
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
    >
      <Icon style={{height: '64px'}} />
      <Typography variant="h3" sx={{marginTop: 4}}>{title}</Typography>
      <Typography variant="body1" sx={{marginTop: 4, marginBottom: 4}}>{text}</Typography>
      <Box sx={{
        width: 'calc(100% - 64px)',
        position: 'absolute',
        bottom: 32,
        left: 32
      }}>
        <Box sx={{
          width: 'auto',
          display: 'inline-block',
          padding: 0.75,
          paddingLeft: 1.25,
          paddingRight: 1.25,
          backgroundColor: theme.palette.secondary.light,
          borderRadius: '6px'
        }}>
          <Typography variant="body2">{cta}</Typography>
        </Box>
        <Lottie
          options={{
            loop: false,
            autoplay: false, 
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            zIndex: 1205
          }}
          height={34}
          width={34}
          isClickToPauseDisabled
          isStopped={stopped}
        />
      </Box>
    </Box>;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      picLeft={HeroImg}
      children={<>
        <Typography variant="h5" html fuck_p_tags>{t("nw.reclame")}</Typography>
        <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.stock.why.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{marginBottom: 4, color: theme.palette.primary.dark}}>{t("nw.stock.why.hero.subsection")}</Typography>
        <Box>
          <DarkGreenButton
            label={t("nw.stock.why.hero.cta.subscribe")}
            id="sub_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
              display: {
                xs: 'block',
                md: 'inline-flex'
              }
            }}
            path="/buy"
          />
          <LightGreenButton
            label={t("nw.stock.why.hero.cta.how")}
            id="how_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              marginLeft: {
                xs: 0,
                md: 2.5
              },
              marginTop: {
                xs: 2.5,
                md: 0
              }
            }}
            path="/stock/how"
          />
        </Box>
      </>}
    />

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={6} lg={4}>
          {whiteBox(
            '/stock/dividend',
            graphStopped,
            setGraphStopped,
            Graph,
            t("nw.stock.why.graph.title"),
            t("nw.stock.why.graph.text"),
            t("nw.stock.why.graph.cta")
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {whiteBox(
            '/benefits',
            plusStopped,
            setPlusStopped,
            Plus,
            t("nw.stock.why.plus.title"),
            t("nw.stock.why.plus.text"),
            t("nw.stock.why.plus.cta")
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          {whiteBox(
            '/about/investments',
            windmillStopped,
            setWindmillStopped,
            Windmill,
            t("nw.stock.why.windmill.title"),
            t("nw.stock.why.windmill.text"),
            t("nw.stock.why.windmill.cta")
          )}
        </Grid>
      </Grid>
    </Section>

    <Section normalPage>
      <Waitlist />
    </Section>

    <Section normalPage>
      <Box sx={{
        padding: 4,
        border: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px'
      }}>
        <Typography variant="body1" html fuck_p_tags sx={{
          textAlign: 'center',
          fontSize: '0.8rem',
          lineHeight: '18px'
        }}>{t("nw.disclaimer_long")}</Typography>
      </Box>
    </Section>
    
    <Footer normalPage />
  </Page>;
}

