import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom, do_logout } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

// @ts-ignore
import LogoGreen from '../../../img/Argenco_logo_green_rgb.svg';
// @ts-ignore
import LogoWhite from '../../../img/Argenco_logo_white_rgb.svg';
// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_green_rgb.svg";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Box,
  Container,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';

import {
  LightGreenButton,
  TextButton,
  Typography
} from '../../../components/v2/styled';

// import { User, loginFields } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  LanguagePicker: (b:boolean, white?:boolean) => ReactElement;
  scrolled: boolean;
}

export const MenuDesktopInternal: FunctionComponent<Props> = ({LanguagePicker, scrolled}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [anchorEl, setAnchorEl] = React.useState<null | EventTarget & Element>(null);
  const [aboutAnchorEl, setAboutAnchorEl] = React.useState<null | EventTarget & Element>(null);
  const open = Boolean(anchorEl);
  const aboutOpen = Boolean(aboutAnchorEl);
  const [tabletOpen, setTabletOpen] = useState<boolean>(false);
  const whiteMode = window.location.pathname.indexOf("/about/mission") > -1 && !scrolled;

  // const {
  //   data: usr,
  //   // isLoading: adviceUploadsLoading,
  //   // isError: adviceUploadsError,
  //   // isSuccess: adviceUploadsSuccess,
  //   // refetch: adviceUploadsRefetch,
  // } = useQuery({
  //   queryKey: ["full_user", login?.id],
  //   queryFn: () =>
  //     fetch_one<User>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'user',
  //         fields: [...loginFields, 'last_diary_entry'],
  //         id: login?.id,
  //         ob: {
  //           id: login?.id
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!login,
  // });

  







  const SubMenuItem = (key:string, label:string, path:string) => {
    return (<MenuItem
      key={key}
      onClick={(e) => {
        setAnchorEl(null);
        setAboutAnchorEl(null);
        navigate(path);
        window.scrollTo(0, 0);
      }}
    >
      <Typography
        variant='body2'
        sx={{
          color: 'white'
        }}
      >{label}</Typography>
    </MenuItem>);
  }

  return <>
    <Box sx={{
      display: {
        xs: 'none',
        md: 'none',
        lg: 'block'
      },
      position: 'fixed',
      backgroundColor: theme.palette.background.default,
      width: '100%',
      height: '100px',
      zIndex: 1299
    }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          textAlign: 'right',
          height: '40px',
          lineHeight: '40px',
          paddingLeft: 1,
          paddingRight: 1,
          backgroundColor: theme.palette.secondary.light
        }}>
          <Container fixed>
            <Grid container spacing={0}>
              <Grid item xs={4} sx={{textAlign: 'left'}}>
                <Box sx={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  "& svg": {
                    transform: `rotate(-90deg)`,
                    height: '26px',
                    marginRight: 1,
                    marginBottom: '-8px'
                  }
                }} onClick={() => {
                  navigate("/");
                }}><Arrow /></Box>
                <Typography variant="body2" onClick={() => {
                  navigate("/");
                }} sx={{
                  display: 'inline',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}>{t("nw.menu.go_back_to_homepage")}</Typography>
              </Grid>
              <Grid item xs={8}>
                {!!login && <Typography variant="body2" sx={{
                  display: 'inline',
                  marginRight: 4
                }}>{login?.first_name} {login?.last_name} ({login?.vennoot_number})</Typography>}
                {!!login && <Typography variant="body2" html fuck_p_tags sx={{
                  display: 'inline',
                  marginRight: 4
                }}>{t("nw.menu.current_saldo").replace(/<[^>]*>?/gm, '')} {login?.stock_number_amount} EUR</Typography>}
                {LanguagePicker(false, !!whiteMode)}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item xs={12} sx={{
          height: '60px',
          paddingLeft: 1,
          paddingRight: 1,
          color: (scrolled ? theme.palette.primary.main : 'white')
        }}>
          <Container fixed>
            <Grid container spacing={0}>
              {/* DESKTOP */}
              <Grid item xs={12} sx={{marginTop: 1.5, textAlign: 'right'}}>
                {/* <img src={LogoWhite} style={{width: '200px', display: (scrolled ? 'none' : 'inline-block')}} />
                <img src={LogoGreen} style={{width: '200px', display: (scrolled ? 'inline-block' : 'none')}} /> */}
                <img src={whiteMode ? LogoWhite : LogoGreen} style={{width: '200px', float: 'left', cursor: 'pointer'}} onClick={() => {navigate('/'); window.scrollTo(0, 0);}} />

                <TextButton
                  id="overview"
                  label={t("nw.menu.profile.overview")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/profile") > -1}
                  path="/profile"
                />
                <TextButton
                  id="faq"
                  label={t("nw.menu.profile.benefits")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/benefits") > -1}
                  path="/benefits"
                />
                <TextButton
                  id="news"
                  label={t("nw.menu.profile.data")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/edit_profile") > -1}
                  path="/edit_profile"
                />
                <TextButton
                  id="contact"
                  label={t("nw.menu.profile.documents")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    // marginRight: 4,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/my_documents") > -1}
                  path="/my_documents"
                />
                <TextButton
                  id="contact"
                  label={t("nw.menu.profile.transactions")}
                  sx={{
                    marginTop: 0,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    // marginRight: 4,
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {})
                  }}
                  menuActive={window.location.pathname.indexOf("/my_transactions") > -1}
                  path="/my_transactions"
                />
                
                {!!login && <TextButton
                  id="log_out"
                  label={t("nw.menu.logout")}
                  sx={{
                    marginTop: 0,
                    marginLeft: 'auto',
                    ...(!!whiteMode ? {
                      color: 'white'
                    } : {}),
                    opacity: 0.5,
                    marginRight: 0,
                    paddingRight: 0
                  }}
                  onClick={() => {
                    do_logout((result) => {
                      setLogin(result);
                      window.location.reload();
                    });
                  }}
                />}
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Box>
  </>;
}

