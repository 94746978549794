import React, { FunctionComponent, ReactElement, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import HelpIcon from '@mui/icons-material/Help';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  Icon,
  InputAdornment,
  InputBase,
  Tooltip
} from '@mui/material';

import {
  Typography
} from '../styled';

var he = require('he');

type Props = {
  noCaption?: boolean;
  backend?: boolean;
  starred?: boolean;
  caption?: string;
  placeholder?: string;
  helperIcon?: string | ReactElement;
  type?: string;
  sx?: object;
  icon?: string;
  endIcon?: ReactElement;
  muiIcon?: ReactElement;
  error?: string;
  helperText?: string;
  id?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  value?: string;
  ref?: any;
  margin?: 'dense'|undefined;
  onChange?: (z:string) => void
  onKeyDown?: (z:any) => void;
  multiline?: boolean;
};

export const TextInput: FunctionComponent<Props> = ({onKeyDown, starred, endIcon, ref, disabled, required, margin, muiIcon, value, onChange, autoFocus, autoComplete, id, noCaption, backend, caption, placeholder, helperIcon, type, sx, icon, error, helperText, multiline}) => {
  const theme = useTheme();

  const [actualType, setActualType] = useState<string>(type === 'account' ? 'text' : (type || 'text'));
 
  return (<>
    {!noCaption && !!backend && (<Typography variant="caption" sx={{
      marginTop: 1,
      marginRight: 1
    }}>{he.decode(((!!caption ? caption : placeholder) || '').replace(/<[^>]*>?/gm, ''))}{starred && " *"}</Typography>)}

    {!noCaption && !backend && !!caption && (<Typography variant="caption" sx={{opacity: 0.5}}>{he.decode((caption).replace(/<[^>]*>?/gm, ''))}{starred && " *"}</Typography>)}

    {!!helperIcon && (<Tooltip title={helperIcon}><HelpIcon sx={{
      float: 'right',
      color: theme.palette.primary.main
    }} /></Tooltip>)}

    <InputBase
      id={id}
      name={id}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      value={value}
      placeholder={he.decode((placeholder || '').replace(/<[^>]*>?/gm, ''))}
      onChange={(e) => {if (!!onChange) onChange(e.target.value);}}
      onKeyDown={(e) => {if (!!onKeyDown) onKeyDown(e);}}
      margin={margin}
      required={required}
      disabled={disabled}
      multiline={multiline}
      rows={3}
      ref={ref}
      // {..._.omit(this.props, "icon", "error", "helperText", "type")}
      type={actualType}
      sx={{
        border: "0.5px solid transparent",
        backgroundColor: "rgb(244,248,247)",
        padding: `8px 16px`,
        width: "100%",
        borderRadius: "8px",
        marginTop: 3,
        "&.error": {
          border: "0.5px solid rgba(255, 0, 0, 0.5)"
        },
        ...(!!caption ? {
          marginTop: 0
        } : {}),
        ...(!!error ? {
          borderColor: theme.palette.secondary.main
        } : {}),
        ...sx
      }}
      endAdornment={!!endIcon ? endIcon : type === "password" ? <InputAdornment position="end">
        {actualType === 'password' && <VisibilityIcon
          sx={{
            cursor: 'pointer',
            color: "rgb(195,198,197)"
          }}
          onClick={() => {
            setActualType('text');
          }}
        />}
        {actualType === 'text' && <VisibilityOffIcon
          sx={{
            cursor: 'pointer',
            color: "rgb(195,198,197)"
          }}
          onClick={() => {
            setActualType('password');
          }}
        />}
        </InputAdornment> : (!!muiIcon ? muiIcon : (!!icon ? <InputAdornment position="start">
          <Icon
            sx={{
              color: "rgb(195,198,197)"
            }}
            className={`fa ${icon}`}
          />
        </InputAdornment> : null))}
      ></InputBase>

      {!!error && <Typography variant="caption" sx={{
        color: theme.palette.secondary.main,
        marginTop: 1,
        marginRight: 1
      }}>{error}</Typography>}

      {!!helperText && <Typography variant="caption" sx={{
        marginTop: 1,
        marginRight: 1
      }}>{helperText}</Typography>}
  </>);
}