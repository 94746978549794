import React, { FunctionComponent, useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';
import { getCookie, setCookie } from 'react-use-cookie';

// import { check_subdomain } from '../../lib/server_helper';
// import { loginAtom } from '../../lib/auth';
import { backdropAtom } from '../../lib/backdrop';

import {
  Backdrop,
  Box
} from '@mui/material';

// import {
//   Typography
// } from '../../components/v2/styled';
import { CookieNotice } from '../../components/v2/styled/CookieNotice';
import { SharedHeader } from './Header';

// const { DateTime } = require("luxon");

type Props = {
  sx?: object;
  children?: React.ReactNode;
  loginId?: number;
}

export const SharedLayout: FunctionComponent<Props> = ({sx, children}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const [currentBackdrop, setCurrentBackdrop] = useAtom(backdropAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const location = useLocation();
  const {t, i18n} = useTranslation(['translations']);
  // const nonmobile = useMediaQuery('(min-width:600px)');

  const [locale, setLocale] = useState<string>(i18n.resolvedLanguage || 'nl');

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [consentStuff, setConsentStuff] = useState<any>(!!getCookie('argenco_www_cookie') ? {
    security_storage: true,
    analytics_storage: (getCookie('argenco_www_cookie') === 'full' || (getCookie('argenco_www_cookie').indexOf('custom') > -1 && getCookie('argenco_www_cookie').split("|")[1] === '1')) ? true : 'denied', // anal
    functionality_storage: (getCookie('argenco_www_cookie') === 'full' || (getCookie('argenco_www_cookie').indexOf('custom') > -1 && getCookie('argenco_www_cookie').split("|")[2] === '1')) ? true : 'denied', // func
    personalization_storage: (getCookie('argenco_www_cookie') === 'full' || (getCookie('argenco_www_cookie').indexOf('custom') > -1 && getCookie('argenco_www_cookie').split("|")[2] === '1')) ? true : 'denied', // func
    ad_storage: (getCookie('argenco_www_cookie') === 'full' || (getCookie('argenco_www_cookie').indexOf('custom') > -1 && getCookie('argenco_www_cookie').split("|")[3] === '1')) ? true : 'denied', // mark
    ad_userdata: (getCookie('argenco_www_cookie') === 'full' || (getCookie('argenco_www_cookie').indexOf('custom') > -1 && getCookie('argenco_www_cookie').split("|")[3] === '1')) ? true : 'denied', // mark
    ad_personalization: (getCookie('argenco_www_cookie') === 'full' || (getCookie('argenco_www_cookie').indexOf('custom') > -1 && getCookie('argenco_www_cookie').split("|")[3] === '1')) ? true : 'denied' // mark
  } : false);

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale]);
  useEffect(() => {
    // console.log(consentStuff);
    // @ts-ignore
    if (!!window.dataLayer) window.dataLayer.push({
      event: 'pageview',
      page: {
        url: location.pathname,
        ...(!!consentStuff ? {
          consent: consentStuff
        } : {
          consent: {
            security_storage: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            ad_storage: 'denied',
            ad_userdata: 'denied',
            ad_personalization: 'denied'
          }
        })
      }
    });

    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <Box sx={{
    // display: 'flex',
    // flexWrap: 'wrap',
    height: '100%',
    // alignContent: 'baseline',
    // backgroundColor: 'white'
  }}>
  <style type="text/css">{` 
hml,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: clip;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.scrollable-content {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  width: 100%;
  overflow: hidden;
}

.scrollable-content__content {
  max-width: 36rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.scrollable-content__images {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.scrollable-content__images__image {
  border-radius: 9999px;
  background-color: #ff00ff;
  width: 7.5rem;
  height: 7.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  line-height: 7.5rem;
}
`}</style>


    <SharedHeader drawerOpen={menuVisible} setDrawerOpen={setMenuVisible} />
    {!menuVisible && <Box sx={{
      flexDirection: "column",
      display: "flex",
      flexGrow: 1,
      height: "100%",
      // maxHeight: "100vh",
      // overflowY: "scroll",
      // overflowX: "none",
      width: "100%",
      "-webkit-overflow-scrolling": "touch",
      // position: 'absolute',
      // zIndex: 999,
      // top: 0,
      // left: 0,
      // paddingTop: {
      //   xs: '56px',
      //   md: '70px'
      // },
      // padding: theme.spacing(3)
      "&>*": {
        minWidth: 300,
        overflowX: "hidden"
      }
    }} id="scroller">
      {children}
    </Box>}

    <Backdrop
      sx={{ color: '#fff', zIndex: 1500 }}
      open={!!currentBackdrop}
      onClick={() => {
        setCurrentBackdrop(undefined);
      }}
    >
      <img style={{maxWidth: '80%', width: '80%'}} src={currentBackdrop} />
    </Backdrop>
    {!getCookie('argenco_www_cookie') && <CookieNotice onChosen={(isStyle, isCkAnal, isCkFunc, isCkMark) => {
      setConsentStuff({
        security_storage: true,
        analytics_storage: !!isCkAnal ? true : 'denied', // anal
        functionality_storage: !!isCkFunc ? true : 'denied', // func
        personalization_storage: !!isCkFunc ? true : 'denied', // func
        ad_storage: !!isCkMark ? true : 'denied', // mark
        ad_userdata: !!isCkMark ? true : 'denied', // mark
        ad_personalization: !!isCkMark ? true : 'denied' // mark
      });

      // @ts-ignore
      if (!!window.dataLayer) window.dataLayer.push({
        event: 'pageview',
        page: {
          url: location.pathname,
          consent: {
            security_storage: true,
            analytics_storage: !!isCkAnal ? true : 'denied', // anal
            functionality_storage: !!isCkFunc ? true : 'denied', // func
            personalization_storage: !!isCkFunc ? true : 'denied', // func
            ad_storage: !!isCkMark ? true : 'denied', // mark
            ad_userdata: !!isCkMark ? true : 'denied', // mark
            ad_personalization: !!isCkMark ? true : 'denied' // mark
          }
        }
      });
      setCookie('argenco_www_cookie', isStyle);
    }} />}
  </Box>;
}

