import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../lib/v31lib";
import { loginAtom, do_login_otp } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
import {
  Cta,
  InputButton,
  Section,
  Waitlist,
  WhiteSection
} from '../../components/v2/custom';
import {
  Page,
  Typography
} from '../../components/v2/styled';
import { EmissionChoiceMe } from './emission/ChoiceMe';
import { EmissionChoiceMeExisting } from './emission/ChoiceMeExisting';
import { EmissionChoiceMeNewItsme } from './emission/ChoiceMeNewItsme';
import { EmissionChoiceMeNewNew } from './emission/ChoiceMeNewNew';
import { EmissionChoiceMeNew } from './emission/ChoiceMeNew';
import { EmissionChoiceMinor } from './emission/ChoiceMinor';
import { EmissionChoiceMinorExisting } from './emission/ChoiceMinorExisting';
import { EmissionChoiceMinorNew } from './emission/ChoiceMinorNew';
import { EmissionChoiceOffice } from './emission/ChoiceOffice';
import { EmissionChoiceOfficeExisting } from './emission/ChoiceOfficeExisting';
import { EmissionChoiceOfficeNew } from './emission/ChoiceOfficeNew';
import { EmissionForgotLink } from './emission/ForgotLink';
import { EmissionLanding } from './emission/Landing';
import { EmissionLoader } from './emission/Loader';
import { EmissionError } from './emission/Error';
import { EmissionErrorExisting } from './emission/ErrorExisting';
import { EmissionErrorItsme } from './emission/ErrorItsme';
import { EmissionErrorMinor } from './emission/ErrorMinor';

import { Guardian } from '../../models/Guardian';
import { User } from '../../models/User';

const { DateTime } = require("luxon");

type Props = {}

export const Emission: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();

  const [step, setStep] = useState<number>(!!state?.step ? state.step : -1);
  const [sub, setSub] = useState<string>(!!state?.sub ? state.sub : '');
  const [user, setUser] = useState<User>({
    id: undefined,
    auth_token: '',
    email: ''
  });
  const [guardian, setGuardian] = useState<Guardian>({
    id: undefined,
    email: ''
  });

  const testing = 0;

  const [email, setEmail] = useState<string>('');
  const [forgotMessage, setForgotMessage] = useState<string>('');
  const [forgotSuccess, setForgotSuccess] = useState<boolean>(false);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [vennr, setVennr] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [officeNr, setOfficeNr] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [showOtp, setShowOtp] = useState<boolean>(false);

  // STEP
  // -1 => loader
  // 0 => landing
  // 5 => for me
  // 6 => existing
  // 7 => new
  // 8 => new/itsme
  // 9 => new/new
  // 15 => for minor
  // 16 => existing
  // 17 => minor/new
  // 25 => for me
  // 26 => existing
  // 27 => new
  // 50 => stocks
  // 100 => forgot link
  // 404 => error
  // 999 => waitlist
  




  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      step: number;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      console.log(data);

      setShowOtp(false);

      if (variables.step === -1) {
        // initial fetch
        if (!!data.custom_result.enabled) {
          setStep(0);
        } else {
          setStep(999);
        }
      } else {
        if (variables.step === 7 || variables.step === 17 || variables.step === 27) {
          if (!!data.custom_result.stocks_url) {
            navigate(data.custom_result.stocks_url);
          } else {
            setStep(400 + variables.step);
          }
        } else {
          if (variables.step === 6) {
            if (!!data.custom_result.mfa_status_0_verification) {
              setShowOtp(true);
            } else {
              if (!!data.custom_result.stocks_url) {
                navigate(data.custom_result.stocks_url);
              } else {
                setStep(406);
              }
            }
          } else {
            if (variables.step === 8) {
              
            } else {
              if ( variables.step === 888) {
                if (!!data.custom_result.stocks_url) {
                  navigate(data.custom_result.stocks_url);
                } else {
                  setStep(408);
                }
              } else {
                if (variables.step === 16) {
                  if (!!data.custom_result.mfa_status_0_verification) {
                    setShowOtp(true);
                  } else {
                    if (!!data.custom_result.stocks_url) {
                      navigate(data.custom_result.stocks_url);
                    } else {
                      setStep(4016);
                    }
                  }
                } else {
                  if (variables.step === 26) {
                    if (!!data.custom_result.mfa_status_0_verification) {
                      setShowOtp(true);
                    } else {
                      if (!!data.custom_result.stocks_url) {
                        navigate(data.custom_result.stocks_url);
                      } else {
                        setStep(426);
                      }
                    }
                  } else {
                    if (variables.step === 100) {
                      // forgot
                      setForgotSuccess(true);
                      setForgotMessage(data.custom_result.message);
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (!!data.custom_result.user) {
        setUser({
          ...data.custom_result.user,
          birth_date: DateTime.fromISO(data.custom_result.user?.birth_date).toFormat("dd/LL/y")
        });
      }
    },
  });




  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    // console.log(step, state);
    mutationCustomAction.mutate({
      formData: {
        object: 'emission',
        class_action: 'custom_api_get_info',
        testing: testing,
        sub: sub,
        user_id: user.id
      },
      step: step
    });
  }, []);
  useEffect(() => {
    if (step === 7) setUser({
      auth_token: '',
      email: '',
      gender: 'M'
    });
    if (step === 15) {
      setUser({
        auth_token: '',
        email: '',
        gender: 'M'
      });
      setGuardian({
        email: ''
      });
    }
  }, [step]);
  useEffect(() => {
    if (step === 6) {
      if (parseInt(otp.replace(" ", "").replace("_", ""), 10).toString().length === 6) {
        // console.log('useeffect', otp.replace(" ", "").replace("_", ""));
        mutationCustomAction.mutate({
          formData: {
            object: 'user',
            class_action: 'custom_api_validate',
            username: username,
            password: password,
            birthdate: birthdate,
            vennr: vennr,
            otp: otp.replace(" ", "").replace("_", "")
          },
          step: 6
        });
      }
    }
  }, [otp]);

  return <Page>
    {step !== 999 && <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5} sx={{
        marginTop: {
          xs: 2,
          lg: 4
        }
      }}>
        {step === -1 && <EmissionLoader />}
        {step === 0 && <EmissionLanding
          setStep={setStep}
        />}
        {step === 5 && <EmissionChoiceMe
          setStep={setStep}
        />}
        {step === 6 && <EmissionChoiceMeExisting
          setStep={setStep}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          vennr={vennr}
          setVennr={setVennr}
          birthdate={birthdate}
          setBirthdate={setBirthdate}
          otp={otp}
          setOtp={setOtp}
          showOtp={showOtp}
          onSubmit={() => {
            // console.log('onsubmit', otp);
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_validate',
                username: username,
                password: password,
                birthdate: birthdate,
                vennr: vennr,
                otp: otp.replace(" ", "").replace("_", "")
              },
              step: 6
            });
          }}
        />}
        {step === 7 && <EmissionChoiceMeNew
          setStep={setStep}
        />}
        {step === 8 && <EmissionChoiceMeNewItsme
          setStep={setStep}
          user={user}
          setUser={setUser}
          loading={mutationCustomAction.isPending}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'emission',
                class_action: 'custom_api_get_info',
                testing: testing,
                mode: 'save_user',
                user: user
              },
              step: 888
            });
          }}
        />}
        {step === 9 && <EmissionChoiceMeNewNew
          setStep={setStep}
          user={user}
          setUser={setUser}
          loading={mutationCustomAction.isPending}
          onSubmit={() => {
            // formdata
            const ob = {
              object: 'emission',
              class_action: 'custom_api_get_info',
              testing: testing,
              mode: 'save_user',
              user: user
            };
            const formData = new FormData();
            Object.keys(ob).forEach(key => {
              if (key === 'user') {
                Object.keys(ob[key]).forEach(kkey => {
                  // @ts-ignore
                  formData.append(`user[${kkey}]`, ob[key][kkey]);
                });
              } else {
                // @ts-ignore
                formData.append(key, ob[key]);
              }
            });

            mutationCustomAction.mutate({
              formData: formData,
              step: 888
            });
          }}
        />}
        {step === 15 && <EmissionChoiceMinor
          setStep={setStep}
        />}
        {step === 16 && <EmissionChoiceMinorExisting
          setStep={setStep}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          vennr={vennr}
          setVennr={setVennr}
          birthdate={birthdate}
          setBirthdate={setBirthdate}
          otp={otp}
          setOtp={setOtp}
          showOtp={showOtp}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_validate',
                username: username,
                password: password,
                birthdate: birthdate,
                vennr: vennr,
                otp: otp,
                guardian: 1
              },
              step: 16
            });
          }}
        />}
        {step === 17 && <EmissionChoiceMinorNew
          setStep={setStep}
          user={user}
          setUser={setUser}
          guardian={guardian}
          setGuardian={setGuardian}
          loading={mutationCustomAction.isPending}
          onSubmit={() => {
            // formdata
            const ob = {
              object: 'emission',
              class_action: 'custom_api_get_info',
              testing: testing,
              mode: 'save_guardian',
              user: user,
              guardian: guardian
            };
            const formData = new FormData();
            Object.keys(ob).forEach(key => {
              if (key === 'user') {
                Object.keys(ob[key]).forEach(kkey => {
                  // @ts-ignore
                  formData.append(`user[${kkey}]`, ob[key][kkey]);
                });
              } else {
                if (key === 'guardian') {
                  Object.keys(ob[key]).forEach(kkey => {
                    // @ts-ignore
                    formData.append(`guardian[${kkey}]`, ob[key][kkey]);
                  });
                } else {
                  // @ts-ignore
                  formData.append(key, ob[key]);
                }
              }
            });

            mutationCustomAction.mutate({
              formData: formData,
              step: 17
            });
          }}
        />}
        {step === 25 && <EmissionChoiceOffice
          setStep={setStep}
        />}
        {step === 26 && <EmissionChoiceOfficeExisting
          setStep={setStep}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          vennr={vennr}
          setVennr={setVennr}
          officeNr={officeNr}
          setOfficeNr={setOfficeNr}
          otp={otp}
          setOtp={setOtp}
          showOtp={showOtp}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_validate',
                username: username,
                password: password,
                office_number: officeNr,
                vennr: vennr,
                otp: otp
              },
              step: 26
            });
          }}
        />}
        {step === 27 && <EmissionChoiceOfficeNew
          setStep={setStep}
          user={user}
          setUser={setUser}
          loading={mutationCustomAction.isPending}
          onSubmit={() => {
            // formdata
            const ob = {
              object: 'emission',
              class_action: 'custom_api_get_info',
              testing: testing,
              mode: 'save_office',
              user: user
            };
            const formData = new FormData();
            Object.keys(ob).forEach(key => {
              if (key === 'user') {
                Object.keys(ob[key]).forEach(kkey => {
                  // @ts-ignore
                  formData.append(`user[${kkey}]`, ob[key][kkey]);
                });
              } else {
                // @ts-ignore
                formData.append(key, ob[key]);
              }
            });

            mutationCustomAction.mutate({
              formData: formData,
              step: 27
            });
          }}
        />}
        {step === 100 && <EmissionForgotLink
          email={email}
          setEmail={setEmail}
          success={forgotSuccess}
          message={forgotMessage}
          setStep={setStep}
          onSubmit={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'emission',
                class_action: 'custom_api_get_info',
                testing: testing,
                mode: 'forgot',
                email: email
              },
              step: 100
            });
          }}
        />}
        {step == 404 && <EmissionError
          onBack={() => {
            setStep(5);
          }}
        />}
        {step == 407 && <EmissionError
          onBack={() => {
            setStep(7);
          }}
        />}
        {step == 417 && <EmissionError
          onBack={() => {
            setStep(17);
          }}
        />}
        {step == 426 && <EmissionError
          onBack={() => {
            setStep(26);
          }}
        />}
        {step == 427 && <EmissionError
          onBack={() => {
            setStep(27);
          }}
        />}
        {step == 406 && <EmissionErrorExisting
          onBack={() => {
            setStep(5);
            setUser({
              auth_token: '',
              email: ''
            });
            setVennr('');
            setBirthdate('');
          }}
        />}
        {step == 408 && <EmissionErrorItsme
          onBack={() => {
            setStep(5);
            setUser({
              auth_token: '',
              email: ''
            });
            setVennr('');
            setBirthdate('');
          }}
        />}
        {step == 4016 && <EmissionErrorMinor
          onBack={() => {
            setStep(15);
            setUser({
              auth_token: '',
              email: ''
            });
            setVennr('');
            setBirthdate('');
          }}
        />}
      </Grid>
    </WhiteSection>}

    {step === 999 && <Section normalPage sx={{
      marginTop: {
        xs: 2,
        lg: 4
      }
    }}>
      <Waitlist />
    </Section>}
  </Page>;
}

