import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";

import { fetch_one } from "../../../lib/v31lib";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  Box
} from '@mui/material';

import {
  Cta,
  InputButton,
} from '../custom';
import {
  Typography
} from '../styled';

// import { EmissionKey as EK } from '../../../models/EmissionKey';


type Props = {
  
};

export const Waitlist: FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [waitlistEmail, setWaitlistEmail] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>();








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setWaitlistEmail('');
      setSuccess(true);
      setSuccessMsg(data.custom_result.data);

      // @ts-ignore
      if (!!window.dataLayer) window.dataLayer.push({
        event: 'waitlist',
        eventProps: {
          category: 'waitlist',
          action: 'register_waitlist',
          label: 'success',
          value: 1
        }
      });
    },
  });






  return (<></>);

  return (<>
    <Cta sx={{
        marginTop: 20
      }}>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.home.section.waitlist.title")}</Typography>
        <Typography sx={{
          marginTop: {
            xs: 2,
            md: 3
          },
          textAlign: 'center'
        }} variant="body1" html fuck_p_tags>{t("nw.home.section.waitlist.text")}</Typography>

        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          {!success && <InputButton
            value={waitlistEmail}
            setValue={(v) => {
              setWaitlistEmail(v);
            }}
            btnLabel={t("nw.home.section.waitlist.cta_subscribe")}
            placeholder={t("nw.home.section.waitlist.cta_placeholder")}
            onClick={() => {
              mutationCustomAction.mutate({
                formData: {
                  object: 'waiting_list',
                  class_action: 'custom_api_register_waitlist',
                  email: waitlistEmail
                }
              });
            }}
          />}
          {!!success && <>
            <CheckCircleIcon color="primary" />
            <Typography>{t(successMsg || '')}</Typography>
          </>}
        </Box>
      </Cta>
  </>);
}