import React, { FunctionComponent } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  CircularProgress,
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  TextButton,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
}

export const EmissionChoiceMinor: FunctionComponent<Props> = ({setStep}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  return <>
    {/* <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h1" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_minor.title")}</Typography>
    </Grid> */}
    {/* <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_minor.subsection")}</Typography>
    </Grid> */}
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <DarkGreenButton
        id="new_member"
        label={t("nw.emission.choice_minor.new_member")}
        onClick={() => {
          setStep(17);
          window.scrollTo(0, 0);
          // @ts-ignore
          if (!!window.dataLayer) window.dataLayer.push({
            event: 'emission',
            eventProps: {
              category: 'buying_for_minor',
              action: 'not_a_member',
              label: 'success',
              value: 1
            }
          });
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
      <LightGreenButton
        id="existing_member"
        label={t("nw.emission.choice_minor.existing_member")}
        onClick={() => {
          setStep(16);
          window.scrollTo(0, 0);
          // @ts-ignore
          if (!!window.dataLayer) window.dataLayer.push({
            event: 'emission',
            eventProps: {
              category: 'buying_for_minor',
              action: 'is_a_member',
              label: 'success',
              value: 1
            }
          });
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
      <TextButton
        id="back_btn"
        label={t("nw.emission.forgot.cta_back")}
        onClick={() => {
          setStep(0);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
    </Grid>
  </>;
}

