import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Divider,
  Grid,
  MenuItem,
} from '@mui/material';
import {
  ProfileBox
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  Select,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';
import { Birthdate } from '../../../components/v2/styled/Birthdate';
import { ErrorBox } from '../../../components/v2/styled/ErrorBox';
import { ZichtAccount } from '../../../components/v2/styled/ZichtAccount';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {}

export const ProfileMyTransactions: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [user, setUser] = useState<User>();
  

  const {
    data: tempUser,
    // isLoading: fullBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: tempUserSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "user", 'transactions', login?.id],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'transactions'],
          sub_fields: {
            transactions: ['id', 'type', 'stock_number', 'stock_value', 'transaction_date', 'stock_type', 'stock_saldo', 'stock_saldo_value']
          },
          id: login?.id
        },
        login
      ),
    enabled: !!login?.id,
  });








  const tableRowHeader = () => {
    return <>
      <Grid item xs={3} md={2} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'left'
      }}>
        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: {xs: '0.7rem', md: '1rem'}}}>{t("nw.transactions.header.date")}</Typography>
      </Grid>
      <Grid item xs={3} md={4} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: 1,
        textAlign: 'left'
      }}>
        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: {xs: '0.7rem', md: '1rem'}}}>{t("nw.transactions.header.type")}</Typography>
      </Grid>
      <Grid item xs={1} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: {xs: '0.7rem', md: '1rem'}}}>{t("nw.transactions.header.stock_number")}</Typography>
      </Grid>
      <Grid item xs={2} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: {xs: '0.7rem', md: '1rem'}}}>{t("nw.transactions.header.stock_number_saldo")}</Typography>
      </Grid>
      <Grid item xs={1} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: {xs: '0.7rem', md: '1rem'}}}>{t("nw.transactions.header.stock_saldo")}</Typography>
      </Grid>
      <Grid item xs={2} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: {xs: '0.7rem', md: '1rem'}}}>{t("nw.transactions.header.stock_saldo_value")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>;
  }
  const tableRow = (transaction: {
    transaction_date: string;
    stock_type: "A"| "B";
    type: string;
    stock_saldo: number;
    stock_saldo_value: number;
    stock_number: number;
    stock_value: number;
  }, divider?: boolean) => {
    return <>
      <Grid item xs={3} md={2} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'left'
      }}>
        <Typography variant="body1" sx={{fontSize: {xs: '0.7rem', md: '1rem'}}}>{DateTime.fromISO(transaction.transaction_date).toFormat("dd/LL/y")}</Typography>
      </Grid>
      <Grid item xs={3} md={3} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: 1,
        textAlign: 'left'
      }}>
        <Typography variant="body1" sx={{fontSize: {xs: '0.7rem', md: '1rem'}}}>{{
          "Transaction::Bought": t("nw.transaction.buy").replace(/<[^>]*>?/gm, ''),
          "Transaction::Sold": t("nw.transaction.sold").replace(/<[^>]*>?/gm, ''),
          "Transaction::TransferSold": t("nw.transaction.trsold").replace(/<[^>]*>?/gm, ''),
          "Transaction::TransferBought": t("nw.transaction.trbuy").replace(/<[^>]*>?/gm, ''),
          "Transaction::Conversion": t("nw.transaction.conv").replace(/<[^>]*>?/gm, '')
        }[transaction.type]} {transaction.stock_type}</Typography>
      </Grid>
      <Grid item xs={1} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontSize: {xs: '0.7rem', md: '1rem'}}}>{transaction.stock_number}</Typography>
      </Grid>
      <Grid item xs={2} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontSize: {xs: '0.7rem', md: '1rem'}}}>{transaction.stock_value} EUR</Typography>
      </Grid>
      <Grid item xs={1} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontSize: {xs: '0.7rem', md: '1rem'}}}>{transaction.stock_saldo}</Typography>
      </Grid>
      <Grid item xs={2} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'right'
      }}>
        <Typography variant="body1" sx={{fontSize: {xs: '0.7rem', md: '1rem'}}}>{transaction.stock_saldo_value} EUR</Typography>
      </Grid>

      {!!divider && <Grid item xs={12}>
        <Divider />
      </Grid>}
    </>;
  }
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    if (!!tempUser) setUser(tempUser);
  }, [tempUser]);

  return <Page sx={{paddingTop: '100px', backgroundColor: theme.palette.background.default}}>
    <Grid container spacing={2.5} sx={{
      width: {
        xs: '100%',
        lg: '900px'
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
      marginBottom: 8
    }}>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Grid container spacing={2.5} sx={{
          filter: !login?.id ? 'blur(4px)' : 'blur(0)',
          position: 'relative',
          zIndex: 1100
        }}>
          <Grid item xs={12}sx={{
            paddingLeft: {
              xs: "0px !important",
              md: 2.5
            }
          }}>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '100%',
                  lg: '670px'
                },
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: 'white'
              }}
            >
              <Grid container spacing={0}>
                {tableRowHeader()}
                {(user?.transactions || []).map(transaction => tableRow(transaction, true))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Page>;
}

