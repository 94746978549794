import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import {
  Button as Btn,
  CircularProgress,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle
} from '@mui/material';
// import { Removal } from '../dialogs/Removal';

var he = require('he');

type Props = {
  sx?: object;
  label: string;
  id: string;
  secondary?: boolean;
  contained?: boolean;
  text?: boolean;
  fullwidth?: boolean;
  grayed_out?: boolean;
  disabled?: boolean;
  path?: string;
  size?: "large" | "medium" | "small";
  onClick?: (e:React.MouseEvent, f?:boolean) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  color?: "inherit" | "success" | "warning" | "error" | "info" | "primary" | "secondary";
  loading?: boolean;
};

export const Button: FunctionComponent<Props> = ({color, size, fullwidth, disabled, label, onClick, path, sx, id, contained, text, secondary, startIcon, endIcon, grayed_out, loading}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // const [open, setOpen] = useState(false);
  const [ctrlActive, setCtrlActive] = useState(false);

  // let setting_ask_destroy_confirm_raw = '0';
  // let setting_ignore_destroy_confirm = setting_ask_destroy_confirm_raw === "1";

  return (<>
    <Btn
      id={id}
      size={size}
      disabled={!!loading || disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      color={!!color ? color : !!secondary ? "secondary" : "primary"}
      variant={!!contained ? "contained" : !!text ? "text" : "outlined"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1) {
            if (!!path) window.open(path, "_BLANK");
          } else {
            window.scrollTo(0, 0);
            if (!!path) navigate(path);
          }
          window.scrollTo(0, 0);
          setCtrlActive(false);
        }
        if (!!onClick) {
          onClick(e);
          // if (!!destroyBtn && !setting_ignore_destroy_confirm) {
          //   setOpen(true);
          // } else {
          //   onClick(e);
          // }
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
      sx={{
        width: {
          xs: '100%',
          md: 'auto'
        },
        fontWeight: 400,
        paddingLeft: 3,
        paddingRight: 3,
        borderRadius: '8px',
        borderWidth: '1px',
        textTransform: 'none',
        ...(grayed_out ? {opacity: 0.6} : {}),
        ...(fullwidth ? {width: '100%'} : {}),
        ...sx
      }}
    >
      {!loading && he.decode(label.replace(/<[^>]*>?/gm, ''))}
      {!!loading && <>&nbsp;<CircularProgress sx={{height: '20px !important', width: '20px !important', color: theme.palette.primary.light}} />&nbsp;</>}
    </Btn>

    {/* {!!destroyBtn && <Removal
      open={open}
      setOpen={setOpen}
      execute={(e) => {
        if (!!onClick) onClick(e);
        setOpen(false);
      }}
    />} */}
  </>);
}