import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../lib/v31lib";
import do_login, { loginAtom, do_login_otp } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';
import {
  WhiteSection
} from '../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  TextButton,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { OtpCode } from '../../components/v2/styled/OtpCode';
import { ErrorBox } from '../../components/v2/styled/ErrorBox';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Login: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [benefitId, setBenefitId] = useState<number>(!!state?.benefitId ? state?.benefitId : undefined);
  






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      setShowError(true);
      return true;
    },
    onSuccess: (data, variables, context) => {
      console.log(data);

      if (!data.custom_result.exists) {
        setShowError(true);
      } else {
        if (!!data.custom_result.mfa_status_0_verification) {
          setShowOtp(true);
        } else {
          // @ts-ignore
          if (!!window.dataLayer) window.dataLayer.push({
            event: 'login',
            eventProps: {
              category: 'login',
              action: 'login',
              label: 'success',
              value: 1
            }
          });
  
          do_login_otp(username, password, otp, (result) => {
            setLogin(result);
            if (!!benefitId) {
              setTimeout(() => {
                navigate(`/benefit/${benefitId}`);
              }, 500);
            } else {
              setTimeout(() => {
                navigate("/profile");
              }, 500);
            }
          });
        }
      }
    },
  });






  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5} sx={{
        marginTop: {
          xs: 2,
          lg: 4
        }
      }}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h1" sx={{
            marginTop: 4,
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '440px'
            }
          }}>{t("nw.login.hero.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="subtitle1" html fuck_p_tags sx={{
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '440px'
            }
          }}>{t("nw.login.hero.subsection")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Box sx={{
            display: 'inline-block',
            width: {
              xs: '100%',
              lg: '440px'
            },
            textAlign: 'left'
          }}>
            <TextInput
              value={username}
              onChange={(v) => {
                setUsername(v);
              }}
              starred
              caption={t("nw.login.form.username")}
              // placeholder={t("nw.contact.form.placeholder.first_name")}
              sx={{
                borderRadius: "8px",
                width: '100%',
                border: "2px solid transparent",
                borderColor: theme.palette.primary.dark,
                paddingTop: 0.25,
                paddingBottom: 0.25,
                height: '40px',
                backgroundColor: 'transparent',
              }}
            />
            <TextInput
              value={password}
              onChange={(v) => {
                setPassword(v);
              }}
              starred
              caption={t("nw.login.form.password")}
              // placeholder={t("nw.contact.form.placeholder.first_name")}
              type="password"
              sx={{
                borderRadius: "8px",
                width: '100%',
                border: "2px solid transparent",
                borderColor: theme.palette.primary.dark,
                paddingTop: 0.25,
                paddingBottom: 0.25,
                height: '40px',
                backgroundColor: 'transparent',
              }}
            />
            {!!showOtp && <OtpCode
              value={otp || ''}
              onChange={(v) => {
                setOtp(v.target.value);
                if (parseInt(v.target.value.replace(" ", "").replace("_", ""), 10).toString().length === 6) {
                  setShowError(false);
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'user',
                      class_action: 'custom_api_validate',
                      username: username,
                      password: password,
                      otp: v.target.value.replace(" ", "").replace("_", "")
                    }
                  });
                }
              }}
              starred
              caption={t("nw.public.otp_code")}
              // placeholder={t("nw.contact.form.placeholder.first_name")}
              sx={{
                borderRadius: "8px",
                width: '100%',
                border: "2px solid transparent",
                borderColor: theme.palette.primary.dark,
                paddingTop: 0.25,
                paddingBottom: 0.25,
                height: '40px',
                backgroundColor: 'transparent',
              }}
            />}
          </Box>
        </Grid>
        {!!showError && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <ErrorBox
            type="error"
          />
        </Grid>}
        <Grid item xs={12} sx={{textAlign: 'right', marginBottom: 8}}>
          <Box sx={{
            display: 'block',
            width: '100%',
            maxWidth: {
              xs: '100%',
              lg: '440px'
            },
            marginTop: 2,
            marginRight: 'auto',
            marginLeft: 'auto',
            textAlign: 'right'
          }}>
            <Box sx={{
              display: 'inline-block',
              width: {
                xs: '100%',
                lg: '49%'
              },
              textAlign: 'left',
              float: 'left'
            }}>
              <TextButton
                label={t("nw.login.hero.cta_forgot_pass")}
                id="sub_btn"
                path="/first_time"
                sx={{
                  marginBottom: 1,
                  color: theme.palette.primary.dark,
                  textDecoration: 'underline',
                  "&:hover": {
                    color: theme.palette.primary.light,
                    backgroundColor: 'transparent'
                  },
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              />
            </Box>
            <Box sx={{
              display: 'inline-block',
              width: {
                xs: '100%',
                lg: '49%'
              },
              marginLeft: 'auto',
              textAlign: 'right'
            }}>
              <DarkGreenButton
                label={t("nw.login.hero.cta")}
                id="sub_btn"
                sx={{
                  marginBottom: 1,
                  borderColor: theme.palette.primary.dark,
                  "&:hover": {
                    borderColor: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.dark,
                  },
                }}
                onClick={() => {
                  setShowError(false);
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'user',
                      class_action: 'custom_api_validate',
                      username: username,
                      password: password,
                      otp: otp
                    }
                  });
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginTop: 4}}>
          <Typography variant="body1" sx={{
            display: 'inline-block',
            maxWidth: {
              xs: '100%',
              lg: '440px'
            }
          }}>{t("nw.login.register.title")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 4, paddingTop: "0px !important"}}>
          <TextButton
            label={t("nw.login.hero.cta_register")}
            id="reg_btn"
            path="/first_time"
            sx={{
              marginBottom: 1,
              color: theme.palette.primary.dark,
              textDecoration: 'underline',
              fontWeight: 'bold',
              "&:hover": {
                color: theme.palette.primary.light,
                backgroundColor: 'transparent'
              },
              paddingLeft: 0,
              paddingRight: 0
            }}
          />
        </Grid>
      </Grid>
    </WhiteSection>
  </Page>;
}

