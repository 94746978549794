import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../img/Argenco_pijl_green_rgb.svg";

import {
  Box,
  Grid,
  IconButton,
} from '@mui/material';

import {
  Cta,
  FaqBlock,
  Footer,
  InputButton,
  Section,
  WhiteSection
} from '../../components/v2/custom';
import {
  LightGreenButton,
  Page,
  TextButton,
  Typography
} from '../../components/v2/styled';

import { Faq as FaqItem } from '../../models/Faq';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const FaqDetail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();

  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentFilter, setCurrentFilter] = useState<number>(0);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  let count = 0;

  const {
    data: faqs,
    isLoading: faqsLoading,
    // isError: adviceUploadsError,
    isSuccess: faqsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "faqs", objectId, page, {search: currentSearch, filter: currentFilter}],
    queryFn: () =>
      fetch_all_with_count<FaqItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'faq',
          fields: ['id', 'created_at', 'alias_title', 'alias_body', 'tag|id/name'],
          per_page: 1000,
          page: page,
          order: 'id ASC',
          filter: {
            advanced: {
              enabled: 1,
              tag_id: objectId,
              catch_all: (searchParams.get("s") || '')
            }
          }
        },
        undefined
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!objectId,
  });







  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    setObjectId(params.id);
  }, [params.id]);
  
  return <Page>
    <Section normalPage>
      <Cta>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{(((faqs || [])[0] || {}).tag_name || {})[i18n.resolvedLanguage || 'nl']}</Typography>
      </Cta>
    </Section>

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <IconButton sx={{
            "& svg": {
              transform: "rotate(270deg)",
              width: '20px',
              marginTop: -1,
              marginLeft: -0.25
            },
            display: 'inline-block',
            marginRight: 'auto',
            height: '32px',
            width: '32px',
            paddingTop: '5px',
            backgroundColor: theme.palette.secondary.light,
          }} onClick={() => {
            navigate(`/faq${!!(searchParams.get("s") || '') ? `?s=${(searchParams.get("s") || '')}` : ""}`);
          }}>
            <Arrow />
          </IconButton>
          <TextButton
            id="back_btn"
            label={t("nw.benefit.detail.back_cta")}
            path={`/faq${!!(searchParams.get("s") || '') ? `?s=${(searchParams.get("s") || '')}` : ""}`}
            sx={{
              fontSize: '16px',
              textDecoration: 'underline',
              width: 'auto'
            }}
          />
        </Grid>
      </Grid>
    </Section>

    {!!faqsSuccess && faqs.map(faq => <WhiteSection isTransparent noMinHeight sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Box sx={{
        maxWidth: {
          xs: '100%',
          lg: '670px'
        },
        textAlign: 'left',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: 4,
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
        <Typography variant="h3" sx={{
          marginBottom: 4,
          color: theme.palette.primary.light
        }}>{faq.alias_title}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks>{faq.alias_body}</Typography>
      </Box>
    </WhiteSection>)}

    <Section normalPage>
      <Cta>
        <Typography variant="h2" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.faqs.bottom.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.faqs.bottom.subsection")}</Typography>
        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          <LightGreenButton
            id="goto_contact"
            label={t("nw.faqs.bottom.cta")}
            path="/contact"
          />
        </Box>
      </Cta>
    </Section>
  
    <Footer normalPage />
  </Page>;
}

