import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';

import {
  // Button as Btn,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle
} from '@mui/material';

import { Button } from '../styled';
// import { Removal } from '../dialogs/Removal';

type Props = {
  sx?: object;
  label: string;
  id: string;
  secondary?: boolean;
  contained?: boolean;
  text?: boolean;
  fullwidth?: boolean;
  grayed_out?: boolean;
  disabled?: boolean;
  white?: boolean;
  dark?: boolean;
  path?: string;
  size?: "large" | "medium" | "small";
  onClick?: (e:React.MouseEvent, f?:boolean) => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  color?: "inherit" | "success" | "warning" | "error" | "info" | "primary" | "secondary";
};

export const TransparentButton: FunctionComponent<Props> = ({color, dark, size, fullwidth, disabled, label, onClick, path, sx, id, contained, text, secondary, startIcon, endIcon, grayed_out}) => {
  const theme = useTheme();
  // const navigate = useNavigate();

  return (<>
    <Button
      label={label}
      id={id}
      size={size}
      color={color}
      fullwidth={fullwidth}
      disabled={disabled}
      onClick={onClick}
      path={path}
      contained={contained}
      text={text}
      secondary={secondary}
      startIcon={startIcon}
      endIcon={endIcon}
      grayed_out={grayed_out}
      sx={{
        borderColor: !!dark ? theme.palette.primary.main : theme.palette.primary.light,
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.dark,
        },
        width: 'auto',
        ...sx
      }}
    />
  </>);
}