import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import HeroImg from "../../img/cut/ArgenCo_Voordelen_1024px.jpg";

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Benefit,
  Cta,
  Footer,
  Hero,
  InputButton,
  Section,
  Waitlist,
  WhiteSection
} from '../../components/v2/custom';
import {
  Benefit as SkelBenefit,
} from '../../components/v2/skeleton';
import {
  Page,
  Pagination,
  TransparentButton,
  Typography
} from '../../components/v2/styled';

import { Benefit as Ben } from '../../models/Benefit';
import { ErrorBox } from '../../components/v2/styled/ErrorBox';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Benefits: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [currentFilter, setCurrentFilter] = useState<number>(0);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [currentSearchInput, setCurrentSearchInput] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  let count = 0;

  const {
    data: benefits,
    isLoading: benefitsLoading,
    // isError: adviceUploadsError,
    isSuccess: benefitsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "benefits", page, {search: currentSearch, filter: currentFilter}],
    queryFn: () =>
      fetch_all_with_count<Ben>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit',
          fields: ['id', 'created_at', 'nw_timing', 'nw_discount_tag', 'discount_name', 'get_item'],
          per_page: 9,
          page: page,
          order: 'weight DESC',
          filter: {
            advanced: {
              enabled: 1,
              tag_id: currentFilter,
              catch_all: currentSearch,
              for_lang: i18n.resolvedLanguage || 'nl'
            }
          }
        },
        undefined
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    // enabled: false,
  });
  const {
    data: benefitTags,
    // isLoading: benefitsLoading,
    // isError: adviceUploadsError,
    isSuccess: benefitTagsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "benefit_tags"],
    queryFn: () =>
      fetch_all<{id: number, name: {[s:string]:string}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit_tag',
          fields: ['id', 'name'],
          per_page: 1000,
          page: page,
          order: 'weight DESC, name ASC',
        },
        undefined
      ),
    // enabled: false,
  });







  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return <Page>
    <Hero
      picRight={HeroImg}
      children={<>
        <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.benefits.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{color: theme.palette.primary.dark}}>{t("nw.benefits.hero.subsection")}</Typography>
      </>}
    />

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={8} lg={8} sx={{overflow: 'hidden', position: 'relative'}}>
          <Typography variant="h5" sx={{marginBottom: 1.25}}>{t("nw.benefits.filter.title")}</Typography>
        
          <TransparentButton
            id="btn_filter_all"
            label={t("nw.benefits.filters.all")}
            sx={{
              textTransform: 'uppercase',
              ...(currentFilter === 0 ? {
                backgroundColor: theme.palette.primary.light,
                fontWeight: 'bold'
              } : {}),
              marginRight: 1,
              marginBottom: 1,
              padding: "2px 8px",
              // paddingLeft: 1,
              // paddingRight: 1,
              minWidth: '24px'
            }}
            onClick={() => {
              setCurrentFilter(0);
            }}
          />
          {!!benefitTagsSuccess && benefitTags.map(tag => <TransparentButton
            id="btn_filter_all"
            label={(tag.name || {})[i18n.resolvedLanguage || 'nl']}
            sx={{
              textTransform: 'uppercase',
              ...(currentFilter === tag.id ? {
                backgroundColor: theme.palette.primary.light,
                fontWeight: 'bold'
              } : {}),
              marginRight: 1,
              marginBottom: 1,
              padding: "2px 8px",
              // paddingLeft: 1,
              // paddingRight: 1,
              minWidth: '24px'
            }}
            onClick={() => {
              setCurrentFilter(tag.id);
            }}
          />)}
        </Grid>
        <Grid item xs={12} md={4} lg={4} sx={{textAlign: 'right'}}>
          <Typography variant="h5" sx={{marginBottom: 1.25}}>{t("nw.benefits.search.title")}</Typography>
          <InputButton
            value={currentSearchInput}
            setValue={(v) => {
              setCurrentSearchInput(v);
            }}
            btnLabel={t("nw.benefits.search.cta")}
            placeholder={t("nw.benefits.search.placeholder")}
            onClick={() => {
              setCurrentSearch(currentSearchInput);
            }}
            sx={{
              width: {
                xs: '100%',
                md: '200px'
              },
              marginTop: -0.25
            }}
          />
        </Grid>

        {!!benefitsLoading && [0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => <Grid item xs={12} md={6} lg={4}>
          <SkelBenefit />
        </Grid>)}
        {!benefitsLoading && benefitsSuccess && benefits.map(benefit => <Grid item xs={12} md={6} lg={4}>
          <Benefit benefit={benefit} sx={{
            minWidth: '99%',
            maxWidth: '99%'
          }} />
        </Grid>)}
        {!benefitsLoading && benefitsSuccess && benefits.length < 1 && <Grid item xs={12} sx={{textAlign: 'center'}}>
          <ErrorBox type="success" text={t("nw.benefits.search.no_results")} />
        </Grid>}

        <Grid item xs={12}>
          <Pagination
            count={count}
            page={page}
            setPage={setPage}
          />
        </Grid>
      </Grid>
    </WhiteSection>

    <Section normalPage>
      <Waitlist />
    </Section>

    <Footer normalPage />
  </Page>;
}

