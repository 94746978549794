import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { ZichtAccount } from '../../../components/v2/styled/ZichtAccount';
import { DropzoneArea } from 'mui-file-dropzone';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
  user?: User;
  setUser: (u:User) => void;
  onSubmit: () => void;
  loading?: boolean;
}

export const EmissionChoiceOfficeNew: FunctionComponent<Props> = ({setStep, user, setUser, onSubmit, loading}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [isOkay, setIsOkay] = useState<boolean>(false);







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      step: number;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      console.log(data);
      if (!!data.custom_result.stocks_url) {
        navigate(data.custom_result.stocks_url);
      } else {
        if (!!data.custom_result.user) setUser({
          ...user,
          ...data.custom_result.user
        });
      }
    }
  });







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_office")}</Typography>
    </Grid>
    {/* <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me.subsection")}</Typography>
    </Grid> */}
    <Grid item xs={6}>
      <TextInput
        value={user?.office_number || ''}
        disabled={!!user?.is_office}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            office_number: v.toString()
          });
        }}
        starred
        caption={t("nw.user_info.office_number")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        value={user?.fsma_number || ''}
        disabled={!!user?.is_office}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            fsma_number: v.toString()
          });
        }}
        starred
        caption={t("nw.user_info.fsma_number")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    {!user?.is_office && <>
      <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
        <TextButton
          id="back_btn"
          label={t("nw.emission.forgot.cta_back")}
          onClick={() => {
            setStep(25);
            window.scrollTo(0, 0);
          }}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            marginTop: 1
          }}
        />
        <DarkGreenButton
          id="next_btn"
          label={t("nw.emission.choice_me_new_itsme.cta_find")}
          loading={!!loading}
          disabled={
            !user?.office_number || !user.fsma_number
          }
          onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'office',
                class_action: 'custom_api_validate',
                office_number: user?.office_number,
                fsma_number: user?.fsma_number
              },
              step: 6
            });
          }}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            marginTop: 1,
            color: 'white !important'
          }}
        />
      </Grid>
    </>}
    {!!user?.is_office && <>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="subtitle1" sx={{
          display: 'inline-block',
          fontStyle: 'italic'
        }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra_email")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={user?.email || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              email: v
            });
          }}
          starred
          caption={t("nw.user_info.email")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={user?.repeat_email || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              repeat_email: v
            });
          }}
          starred
          caption={t("nw.user_info.repeat_email")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={user?.phone || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              phone: v.toString()
            });
          }}
          starred
          caption={t("nw.user_info.phone")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_address")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <TextInput
          value={user?.street || ''}
          disabled
          onChange={(v) => {
            
          }}
          starred
          caption={t("nw.user_info.street")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          value={user?.number || ''}
          disabled
          onChange={(v) => {
            
          }}
          starred
          caption={t("nw.user_info.number")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          value={user?.zip || ''}
          disabled
          onChange={(v) => {
            
          }}
          starred
          caption={t("nw.user_info.zip")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <TextInput
          value={user?.city || ''}
          disabled
          onChange={(v) => {
            
          }}
          starred
          caption={t("nw.user_info.city")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_banking")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <ZichtAccount
          value={user?.zicht_account || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              zicht_account: v.target.value
            });
          }}
          setExternalIsOkay={setIsOkay}
          starred
          caption={t("nw.user_info.zicht_account")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid><Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_identity")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{
        display: {
          xs: 'none', md: 'flex'
        },
        "& .cust_dropzonearea > div > div": {
          flexBasis: 'auto !important',
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }}>
        <DropzoneArea
          acceptedFiles={['image/gif', 'image/jpeg', 'image/png', '.pdf']}
          filesLimit={1}
          fileObjects={[]}
          dropzoneText={he.decode(t('nw.general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
          onChange={(files) => {
            // this.setState({user: {...this.state.user, identity: files[0]}});
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              identity: files[0]
            });
          }}
          maxFileSize={3000000}
          previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
          dropzoneClass="cust_dropzonearea"
        />
      </Grid>
      <Grid item xs={12} sx={{
        display: {
          xs: 'flex', md: 'none'
        },
        "& .cust_dropzonearea > div > div": {
          flexBasis: 'auto !important',
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      }}>
        <DropzoneArea
          acceptedFiles={['image/gif', 'image/jpeg', 'image/png', '.pdf']}
          filesLimit={1}
          fileObjects={[]}
          dropzoneText={he.decode(t('nw.general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
          onChange={(files) => {
            // this.setState({user: {...this.state.user, identity: files[0]}});
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              identity: files[0]
            });
          }}
          maxFileSize={3000000}
          previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
          dropzoneClass="cust_dropzonearea"
        />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_overview")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'left'}}>
        {!(!user?.phone || user?.email !== user.repeat_email) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
        {!!(!user?.phone || user?.email !== user.repeat_email) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
        <Typography variant="subtitle1" sx={{
          marginTop: 1,
          display: 'inline-block',
          color: (!user?.phone || user?.email !== user.repeat_email) ? theme.palette.warning.main : theme.palette.primary.light,
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'left'}}>
        {!(!user?.zicht_account || !isOkay) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
        {!!(!user?.zicht_account || !isOkay) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
        <Typography variant="subtitle1" sx={{
          marginTop: 1,
          display: 'inline-block',
          color: (!user?.zicht_account || !isOkay) ? theme.palette.warning.main : theme.palette.primary.light,
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_banking")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'left'}}>
        {!(!user?.identity) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
        {!!(!user?.identity) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
        <Typography variant="subtitle1" sx={{
          marginTop: 1,
          display: 'inline-block',
          color: (!user?.identity) ? theme.palette.warning.main : theme.palette.primary.light,
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_identity")}</Typography>
      </Grid>

      <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
        <TextButton
          id="back_btn"
          label={t("nw.emission.forgot.cta_back")}
          onClick={() => {
            setStep(25);
            window.scrollTo(0, 0);
          }}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            marginTop: 1
          }}
        />
        <DarkGreenButton
          id="next_btn"
          label={t("nw.emission.choice_me_new_itsme.cta_next")}
          loading={!!loading}
          disabled={
            !user?.phone || !user.zicht_account || !isOkay || !user.identity || user?.email !== user.repeat_email
          }
          onClick={() => {
            onSubmit();
          }}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            marginTop: 1,
            color: 'white !important'
          }}
        />
      </Grid>
    </>}
  </>;
}

