import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';

import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';

import {
  Footer,
  Hero,
  NewsItem as NewsItemTile,
  Section,
  WhiteSection,
} from '../../components/v2/custom';
import {
  BlockInfo,
  DarkGreenButton,
  IconButton,
  Page,
  TextButton,
  TextInput,
  Typography
} from '../../components/v2/styled';

// import { Benefit as Ben } from '../../../models/Benefit';
import { Raffle as Rffl } from '../../models/Raffle';
import { User } from '../../models/User';
import { ErrorBox } from '../../components/v2/styled/ErrorBox';
import { Vennr } from '../../components/v2/styled/Vennr';
import { Birthdate } from '../../components/v2/styled/Birthdate';
import { OtpCode } from '../../components/v2/styled/OtpCode';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {}

export const Raffle: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();

  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [user, setUser] = useState<User>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>();

  const [vennr, setVennr] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [otp, setOtp] = useState<string>('');
  const [showOtp, setShowOtp] = useState<boolean>(false);

  const [fieldPhone, setFieldPhone] = useState<string>('');
  const [fieldPhoneRepeat, setFieldPhoneRepeat] = useState<string>('');
  const [multi1, setMulti1] = useState<string>('');
  const [schifting, setSchifting] = useState<string>('');

  const {
    data: raffle,
    isLoading: raffleLoading,
    // isError: adviceUploadsError,
    isSuccess: raffleSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "raffle", objectId],
    queryFn: () =>
      fetch_one<Rffl>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'raffle',
          fields: ['id', 'name', 'visible_start', 'visible_end', 'description', 'raffle_start', 'raffle_end', 'is_schifting', 'schiftingsvraag', 'is_multi_option_1', 'multi_option_1_question', 'multi_option_1'],
          id: objectId
        },
        undefined
      ),
    enabled: !!objectId,
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.formData.class_action === "custom_api_validate") {
        if (!!data.custom_result.mfa_status_0_verification) {
          setShowOtp(true);
        } else {
          if (!!data.custom_result.exists) setUser(data.custom_result);
        }
      }
      if (variables.formData.class_action === "custom_api_subscribe") {
        setFinished(true);
        setSuccess(data.custom_result.success);
        setSuccessMsg(data.custom_result.message);
      }
    },
  });








  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    setObjectId(params.id);
  }, [params.id]);
  useEffect(() => {
    if (!!raffle) {
      setIsValid(DateTime.fromISO(raffle.visible_start).startOf('day') <= DateTime.now() && DateTime.fromISO(raffle.visible_end).endOf('day') >= DateTime.now());
      setIsVisible(DateTime.fromISO(raffle.raffle_start).startOf('day') <= DateTime.now() && DateTime.fromISO(raffle.raffle_end).endOf('day') >= DateTime.now());
    }
  }, [raffle]);
  useEffect(() => {
    if (!!login?.id) setUser(login);
  }, [login]);

  return <Page>
    {!raffle && <Box sx={{textAlign: 'center'}}>
      <CircularProgress />  
    </Box>}
    {!!raffle && <>
      <Hero
        children={<>
          <Typography variant="h1" sx={{
            maxWidth: {
              xs: '100%',
              lg: '670px'
            },
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>{(raffle.name || {})[i18n.resolvedLanguage || 'nl']}</Typography>
          {!!isValid && !!(raffle.description || {})[i18n.resolvedLanguage || 'nl'] && <Typography variant="subtitle1" sx={{
            color: theme.palette.primary.dark,
            marginTop: 4,
            maxWidth: {
              xs: '100%',
              lg: '670px'
            },
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>{(raffle.description || {})[i18n.resolvedLanguage || 'nl']}</Typography>}
        </>}
      />

      <WhiteSection>
        {!!isVisible && !!isValid && <Grid container item xs={12}>
          {!finished && <Grid item xs={12} sx={{marginTop: 3, textAlign: 'center'}}>
            {!user && <Typography html fuck_p_tags>{t("nw.raffle.sub_description")}</Typography>}
            {!!user && <Typography html fuck_p_tags>{t(
              objectId !== "4" ?
                ((objectId !== "8" && objectId !== "13") ?
                  "nw.raffle.sub_description_validated" :
                  "nw.raffle.sub_description_validated_cstm8") :
                "raffles_subscribe_description_validated_name"
              )}</Typography>}
          </Grid>}
          {!!finished && !!success && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="success"
              text={t("nw.raffle.sub_success")}
            />
          </Grid>}
          {!!finished && !success && successMsg === 'invisible' && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t("nw.raffle.error_not_found")}
            />
          </Grid>}
          {!!finished && !success && successMsg === 'closed' && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t("nw.raffle.error_closed")}
            />
          </Grid>}
          {!!finished && !success && successMsg === 'exists' && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t((objectId === '8' || objectId === '13') ? "nw.raffle.custom8.error_already_sub" : "nw.raffle.error_already_sub")}
            />
          </Grid>}
          {!!finished && !success && successMsg === 'age' && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t("nw.raffle.error_age")}
            />
          </Grid>}
          {!!finished && !success && successMsg === 'user_not_found' && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t("nw.raffle.error_user_not_found")}
            />
          </Grid>}
          {!!finished && !success && successMsg === 'tel' && <Grid item xs={12} sx={{marginTop: 4, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t((objectId === '8' || objectId === '13') ? 
                "nw.raffle.error_email" : 
                (objectId === '4' ? 
                  "nw.raffle.error_name" : 
                  "nw.raffle.error_tel"
                )
              )}
            />
          </Grid>}

          {!finished && <>
            {!user && <>
              <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
                <Box sx={{
                  display: 'inline-block',
                  width: {
                    xs: '100%',
                    lg: '440px'
                  },
                  textAlign: 'left'
                }}>
                  <Vennr
                    value={vennr || ''}
                    onChange={(v) => {
                      setVennr(v.target.value);
                    }}
                    starred
                    caption={t("nw.register.form.lidnummer")}
                    // placeholder={t("nw.contact.form.placeholder.first_name")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                    }}
                  />
                  <Birthdate
                    value={birthdate || ''}
                    onChange={(v) => {
                      setBirthdate(v.target.value);
                    }}
                    starred
                    caption={t("nw.user_info.birth_date")}
                    // placeholder={t("nw.contact.form.placeholder.first_name")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                    }}
                  />
                  {!!showOtp && <OtpCode
                    value={otp || ''}
                    onChange={(v) => {
                      setOtp(v.target.value);
                      if (parseInt(v.target.value.replace(" ", ""), 10).toString().length === 6) {
                        mutationCustomAction.mutate({
                          formData: {
                            object: 'user',
                            class_action: 'custom_api_validate',
                            birthdate: birthdate,
                            vennr: vennr,
                            otp: otp,
                            basic_info: 1
                          }
                        });
                      }
                    }}
                    starred
                    caption={t("nw.public.otp_code")}
                    // placeholder={t("nw.contact.form.placeholder.first_name")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                    }}
                  />}
                  <DarkGreenButton
                    label={t("nw.register.hero.cta")}
                    id="sub_btn"
                    sx={{
                      marginBottom: 1,
                      borderColor: theme.palette.primary.dark,
                      "&:hover": {
                        borderColor: theme.palette.primary.dark,
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                      },
                      "&.Mui-disabled": {
                        color: 'grey',
                      },
                      marginTop: 2,
                    }}
                    disabled={!vennr || !birthdate || (!!showOtp && !otp)}
                    onClick={() => {
                      mutationCustomAction.mutate({
                        formData: {
                          object: 'user',
                          class_action: 'custom_api_validate',
                          birthdate: birthdate,
                          vennr: vennr,
                          otp: otp,
                          basic_info: 1
                        }
                      });
                    }}
                  />
                </Box>
              </Grid>
            </>}
            {!!user && <>
              <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
                <Box sx={{
                  display: 'inline-block',
                  width: {
                    xs: '100%',
                    lg: '440px'
                  },
                  textAlign: 'left'
                }}>
                  <TextInput
                    value={fieldPhone || ''}
                    onChange={(v) => {
                      setFieldPhone(v);
                    }}
                    starred
                    caption={t((objectId === '8' || objectId === '13') ? 
                      "nw.user_info.email" : 
                      (objectId === '4' ? 
                        "nw.user_info.full_name" : 
                        "nw.user_info.phone"
                      )
                    )}
                    // placeholder={t("nw.contact.form.placeholder.email")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                      marginTop: 1
                    }}
                  />
                  {(objectId === '8' || objectId === '13') && <TextInput
                    value={fieldPhoneRepeat || ''}
                    onChange={(v) => {
                      setFieldPhoneRepeat(v);
                    }}
                    starred
                    caption={t("nw.user_info.repeat_email")}
                    // placeholder={t("nw.contact.form.placeholder.email")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                      marginTop: 1
                    }}
                  />}
                </Box>
              </Grid>
              {!!raffle.is_multi_option_1 && <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
                <Box sx={{
                  display: 'inline-block',
                  width: {
                    xs: '100%',
                    lg: '440px'
                  },
                  textAlign: 'left'
                }}>
                  <Typography html fuck_p_tags>{(raffle?.multi_option_1_question || {})[i18n.resolvedLanguage || 'nl']}</Typography>
                </Box>
              </Grid>}
              {!!raffle.is_multi_option_1 && <Grid item xs={12} sx={{textAlign: 'center'}}>
                <Box sx={{
                  display: 'inline-block',
                  width: {
                    xs: '100%',
                    lg: '440px'
                  },
                  textAlign: 'left'
                }}>
                  <RadioGroup value={multi1} sx={{width: '100%', display: 'block'}} onChange={(e) => {
                    setMulti1(e.target.value);
                  }}>
                    <Grid item container xs={12} spacing={0}>
                      {((raffle?.multi_option_1 || {})[i18n.resolvedLanguage || 'nl'] || '').split("\n").map(answer => (<Grid item xs={12} md={6}>
                        <FormControlLabel value={he.decode(answer.trim().replace(/<[^>]*>?/gm, ''))} control={<Radio />} label={he.decode(answer.trim().replace(/<[^>]*>?/gm, ''))} />
                      </Grid>))}
                    </Grid>
                  </RadioGroup>
                </Box>
              </Grid>}
              {!!raffle.is_schifting && <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
                <Box sx={{
                  display: 'inline-block',
                  width: {
                    xs: '100%',
                    lg: '440px'
                  },
                  textAlign: 'left'
                }}>
                  <Typography html fuck_p_tags>{(raffle?.schiftingsvraag || {})[i18n.resolvedLanguage || 'nl']}</Typography>
                </Box>
              </Grid>}
              {!!raffle.is_schifting && <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
                <Box sx={{
                  display: 'inline-block',
                  width: {
                    xs: '100%',
                    lg: '440px'
                  },
                  textAlign: 'left'
                }}>
                  <TextInput
                    value={schifting || ''}
                    onChange={(v) => {
                      setSchifting(v);
                    }}
                    starred
                    caption={t("nw.raffles.schiftingsvraag.answer")}
                    // placeholder={t("nw.contact.form.placeholder.email")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                      marginTop: 1
                    }}
                  />
                </Box>
              </Grid>}
              <Grid item xs={12} sx={{textAlign: 'center', marginTop: 2}}>
                <DarkGreenButton
                  label={t("nw.raffle.sub_cta")}
                  id="sub_btn"
                  sx={{
                    marginBottom: 1,
                    borderColor: theme.palette.primary.dark,
                    "&:hover": {
                      borderColor: theme.palette.primary.dark,
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.dark,
                    },
                    "&.Mui-disabled": {
                      color: 'grey',
                    },
                    marginTop: 2,
                  }}
                  disabled={(!!raffle.is_multi_option_1 && !multi1) || (!!raffle.is_schifting && !schifting)}
                  onClick={() => {
                    mutationCustomAction.mutate({
                      formData: {
                        object: 'raffle',
                        class_action: 'custom_api_subscribe',
                        id: objectId,
                        user_tel: fieldPhone,
                        schifting: schifting,
                        multi_option_1: multi1,
                        user_id: user?.id
                      }
                    });
                  }}
                />
              </Grid>
            </>}
          </>}
        </Grid>}
        {!!isValid && !isVisible && <Grid container item xs={12}>
          <Grid item xs={12} sx={{marginTop: 3, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t("nw.raffle.error_closed")}
            />
          </Grid>
        </Grid>}
        {!isValid && <Grid container item xs={12}>
          <Grid item xs={12} sx={{marginTop: 3, textAlign: 'center'}}>
            <ErrorBox
              type="error"
              text={t("nw.raffle.error_not_found")}
            />
          </Grid>
        </Grid>}
      </WhiteSection>
    </>}

    <Footer normalPage />
  </Page>;
}

