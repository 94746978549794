import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Container,
  Grid
} from '@mui/material';

type Props = {
  sx?: object;
  children?: any;
  picLeft?: any;
  picRight?: any;
  isTransparent?: boolean;
  noMinHeight?: boolean;
  dense?: boolean;
};

export const WhiteSection: FunctionComponent<Props> = ({sx, children, picLeft, picRight, isTransparent, noMinHeight, dense}) => {
  const theme = useTheme();

  return (<Box sx={{
    // width: "calc(100% + 48px)",
    // marginLeft: -3,
    // marginRight: -3,
    width: {
      xs: "calc(100% + 16px)",
      md: '100%'
    },
    marginLeft: {
      xs: -1,
      md: 0
    },
    // padding: 3,
    paddingTop: 1.25,
    paddingBottom: 1.25,
    paddingLeft: {
      xs: 0,
      md: 1.25
    },
    paddingRight: {
      xs: 0,
      md: 1.25
    },
    // borderRadius: '0px 0px 16px 16px',
    position: 'relative',
    backgroundColor: 'transparent',
    zIndex: 999,
    ...sx
  }}>
    <Container fixed sx={{
      paddingLeft: {
        xs: 0,
        md: 2
      },
      paddingRight: {
        xs: 0,
        md: 2
      }
    }}>
      <Grid container spacing={0} sx={{
        backgroundColor: !!isTransparent ? 'transparent' : 'white',
        borderRadius: '10px',
        padding: !!dense ? 0 : {
          xs: 2.5,
          md: 5,
          lg: 2
        }
      }}>
        {!!picLeft && <Grid item xs={12} lg={5} sx={{
          paddingRight: 2,
          display: {
            xs: 'none',
            lg: 'block'
          },
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: !!noMinHeight ? '200px' : '400px',
            borderRadius: '6px',
            background: `url(${picLeft})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: !!dense ? 'contain' : 'cover',
            backgroundSize: 'cover',
            backgroundPosition: !!dense ? 'top center' : 'center center'
          }} />
        </Grid>}
        <Grid item xs={12} lg={(!!picLeft || !!picRight) ? 7 : 12}>
          <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: !!noMinHeight ? '50px' : '400px',
            paddingLeft: {
              xs: 0,
              md: 0,
              lg: !!picRight ? 10 : 8
            },
            paddingRight: {
              xs: 0,
              md: 0,
              lg: !!picLeft ? 10 : 8
            },
            paddingTop: {
              xs: 2.5,
              md: 5,
              lg: 8
            },
            paddingBottom: {
              xs: 0,
              md: 0,
              lg: 8
            },
            ...((!picLeft && !picRight) || !!dense ? {
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0
            } : {}),
          }}>
            {children}
          </Box>
        </Grid>
        {!!picRight && <Grid item xs={12} lg={5} sx={{paddingLeft: {xs: 0, md: 2}}}>
          <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: !!noMinHeight ? '200px' : '400px',
            borderRadius: '6px',
            background: `url(${picRight})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: !!dense ? 'contain' : 'cover',
            backgroundSize: 'cover',
            backgroundPosition: !!dense ? 'top center' : 'center center'
          }} />
        </Grid>}
        {!!picLeft && <Grid item xs={12} sx={{
          display: {
            xs: 'block',
            lg: 'none'
          },
          marginTop: 2.5
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            minHeight: !!noMinHeight ? '200px' : '400px',
            borderRadius: '16px',
            background: `url(${picLeft})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: !!dense ? 'contain' : 'cover',
            backgroundSize: 'cover',
            backgroundPosition: !!dense ? 'top center' : 'center center'
          }} />
        </Grid>}
      </Grid>
    </Container>
  </Box>);
}