import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import QRCode from "react-qr-code";

import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  FormControlLabel,
  Grid2 as Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  Cta,
  ProfileBox
} from '../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  Page,
  Select,
  TextInput,
  Typography
} from '../../components/v2/styled';
import { ErrorBox } from '../../components/v2/styled/ErrorBox';
import { OtpCode } from '../../components/v2/styled/OtpCode';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../models/User';

var he = require('he');
// const { DateTime } = require("luxon");

type Props = {}

export const Profile: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [user, setUser] = useState<User>();
  const [oldMfaStatus, setOldMfaStatus] = useState<number>(0);
  const [oldSecQStatus, setOldSecQStatus] = useState<number>(0);
  const [otp, setOtp] = useState<string>('');
  

  const {
    data: securityQuestions,
    // isLoading: fullBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: securityQuestionsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "sec_q"],
    queryFn: () =>
      fetch_all<{
        id: number;
        question_fr: string;
        question_nl: string;
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'security_question',
          fields: ['id', 'question_nl', 'question_fr']
        },
        login
      ),
    enabled: !!login?.id,
  });
  const {
    data: tempUser,
    // isLoading: fullBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: tempUserSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "user", login?.id],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'mfa_status', 'otp_provisioning', 'sec_q_status', 'max_shares_left'],
          id: login?.id
        },
        login
      ),
    enabled: !!login?.id,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.formData.class_action === "custom_api_mfa_status") {
        setOldMfaStatus(1);
      }
      if (variables.formData.class_action === "custom_api_save_profile") {
        window.scrollTo(0, 0);
        setOldMfaStatus(user?.mfa_status || 0);
        setOldSecQStatus(user?.sec_q_status || 0);
      }
    },
  });
  






  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    if (!!tempUser) {
      setUser({
        ...tempUser
      });
      setOldMfaStatus(tempUser?.mfa_status || 0);
      setOldSecQStatus(tempUser?.sec_q_status || 0);
    }
  }, [tempUser]);

  return <Page sx={{paddingTop: '100px', backgroundColor: theme.palette.background.default}}>
    <Grid container spacing={2.5} sx={{
      width: {
        xs: '100%',
        lg: '900px'
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
      marginBottom: 8
    }}>
      {(user?.max_shares_left || 0) > 0 && <Grid size={{ xs: 12 }}>
        <ErrorBox
          type="success"
          sx={{
            maxWidth: {
              lg: '100%'
            },
            width: '100%'
          }}
          content={<>
            <Typography variant="h4">{t("nw.profile.emission.open_title")}</Typography>
            <LightGreenButton
              id="member"
              label={t("nw.profile.button.buy")}
              sx={{
                marginTop: 1,
                display: {
                  xs: 'block',
                  md: 'inline-flex'
                }
              }}
              path="/buy"
            />
          </>}
        />
      </Grid>}
      <Grid size={{ xs: 12, md: 6 }}>
        <Typography variant="body1">{t("nw.profile.hello").replace(/<[^>]*>?/gm, '')} {login?.first_name} {login?.last_name}</Typography>
        <Typography variant="body1">{t("nw.profile.vennoot_number").replace(/<[^>]*>?/gm, '')} {login?.vennoot_number}</Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }} sx={{
        textAlign: {
          xs: 'left',
          md: 'right'
        }
      }}>
        <Typography variant="body2" sx={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
          navigate("/my_transactions");
        }}>{t("nw.profile.check_transactions")}</Typography>
        <Typography variant="body1">{t("nw.menu.current_saldo").replace(/<[^>]*>?/gm, '')} {login?.stock_number_amount} EUR</Typography>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ProfileBox
          isPlus
          title={t("nw.profile.block.benefits.title")}
          text={t("nw.profile.block.benefits.text")}
          cta={t("nw.profile.block.benefits.cta")}
          onClick={() => {
            navigate('/benefits');
            window.scrollTo(0, 0);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ProfileBox
          isHouse
          title={t("nw.profile.block.data.title")}
          text={t("nw.profile.block.data.text")}
          cta={t("nw.profile.block.data.cta")}
          onClick={() => {
            navigate('/edit_profile');
            window.scrollTo(0, 0);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ProfileBox
          isClover
          title={t("nw.profile.block.documents.title")}
          text={t("nw.profile.block.documents.text")}
          cta={t("nw.profile.block.documents.cta")}
          onClick={() => {
            navigate('/my_documents');
            window.scrollTo(0, 0);
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ProfileBox
          isGraph
          title={t("nw.profile.block.transactions.title")}
          text={t("nw.profile.block.transactions.text")}
          cta={t("nw.profile.block.transactions.cta")}
          onClick={() => {
            navigate('/my_transactions');
            window.scrollTo(0, 0);
          }}
        />
      </Grid>

      <Grid size={12} sx={{marginTop: 4}}>
        <Cta>
          <Grid container spacing={0}>
            <Grid size={12} sx={{marginTop: 2}}>
              <Typography variant="h4">{t("nw.profile.security.mfa.title")}</Typography>
              <Typography html fuck_p_tags>{t("nw.profile.security.mfa.explanation")}</Typography>
            </Grid>
            <Grid size={12}>
              <RadioGroup value={user?.mfa_status?.toString() || '0'} onChange={(e) => {
                setUser({
                  ...({
                    auth_token: '',
                    email: ''
                  }),
                  ...user,
                  mfa_status: parseInt(e.target.value || '', 10)
                });
              }}>
                <FormControlLabel value="1" control={<Radio />} label={he.decode(t("nw.profile.security.mfa.options.auth").replace(/<[^>]*>?/gm, ''))} />
                {/* <FormControlLabel value="2" control={<Radio />} label={he.decode(t("nw.profile.security.mfa.options.sms").replace(/<[^>]*>?/gm, ''))} /> */}
                <FormControlLabel value="0" control={<Radio />} label={he.decode(t("nw.profile.security.mfa.options.off").replace(/<[^>]*>?/gm, ''))} />
              </RadioGroup>
              {oldMfaStatus !== (user?.mfa_status || 0) && (user?.mfa_status || 0) === 0 && <Box sx={{marginTop: 4}}>
                <DarkGreenButton
                  id="save"
                  label={t("nw.profile.security.mfa.cta.update")}
                  onClick={() => {
                    mutationCustomAction.mutate({
                      formData: {
                        object: 'user',
                        class_action: 'custom_api_save_profile',
                        id: login?.id,
                        ob: {
                          id: login?.id,
                          mfa_status: 0
                        }
                      }
                    });
                  }}
                />
              </Box>}
              {oldMfaStatus !== (user?.mfa_status || 0) && (user?.mfa_status || 0) === 1 && <Box sx={{marginTop: 4}}>
                <Typography html fuck_p_tags>{t("nw.profile.security.mfa.auth_explanation.initial_explanation")}</Typography>
                {!!user?.otp_provisioning && <QRCode value={user?.otp_provisioning} />}
                <Typography html fuck_p_tags sx={{fontSize: '0.9rem', marginBottom: 2, marginTop: 2}}>{t("nw.profile.security.mfa.auth_explanation.input_explanation")}</Typography>
                <OtpCode
                  value={otp || ''}
                  onChange={(v) => {
                    setOtp(v.target.value);
                    if (parseInt(v.target.value.replace(" ", ""), 10).toString().length === 6) {
                      mutationCustomAction.mutate({
                        formData: {
                          object: 'user',
                          class_action: 'custom_api_mfa_status',
                          id: login?.id,
                          verify_mfa_status: 1,
                          otp_code: parseInt(v.target.value.replace(" ", ""), 10).toString()
                        }
                      });
                    }
                  }}
                  starred
                  caption={t("nw.public.otp_code")}
                  // placeholder={t("nw.contact.form.placeholder.first_name")}
                  sx={{
                    borderRadius: "8px",
                    width: '100%',
                    border: "2px solid transparent",
                    borderColor: theme.palette.primary.dark,
                    paddingTop: 0.25,
                    paddingBottom: 0.25,
                    height: '40px',
                    backgroundColor: 'transparent',
                  }}
                />
              </Box>}
            </Grid>
          </Grid>
        </Cta>
      </Grid>

      <Grid size={12} sx={{marginTop: 4}}>
        <Cta>
          <Grid container spacing={0}>
            <Grid size={12} sx={{marginTop: 2}}>
              <Typography variant="h4">{t("nw.profile.security.sec_q.title")}</Typography>
              <Typography html fuck_p_tags>{t("nw.profile.security.sec_q.explanation")}</Typography>
            </Grid>
            <Grid size={12}>
              <RadioGroup value={user?.sec_q_status?.toString() || '0'} onChange={(e) => {
                setUser({
                  ...({
                    auth_token: '',
                    email: ''
                  }),
                  ...user,
                  sec_q_status: parseInt(e.target.value || '', 10)
                });
              }}>
                <FormControlLabel value="1" control={<Radio />} label={he.decode(t("nw.profile.security.sec_q.options.on").replace(/<[^>]*>?/gm, ''))} />
                <FormControlLabel value="0" control={<Radio />} label={he.decode(t("nw.profile.security.sec_q.options.off").replace(/<[^>]*>?/gm, ''))} />
              </RadioGroup>
              {oldSecQStatus !== (user?.sec_q_status || 0) && (user?.sec_q_status || 0) === 0 && <Box sx={{marginTop: 4}}>
                <DarkGreenButton
                  id="save"
                  label={t("nw.profile.security.mfa.cta.update")}
                  onClick={() => {
                    mutationCustomAction.mutate({
                      formData: {
                        object: 'user',
                        class_action: 'custom_api_save_profile',
                        id: login?.id,
                        ob: {
                          id: login?.id,
                          sec_q_status: 0
                        }
                      }
                    });
                  }}
                />
              </Box>}
              {oldSecQStatus !== (user?.sec_q_status || 0) && (user?.sec_q_status || 0) === 1 && <Box sx={{marginTop: 4}}>
                <Typography html fuck_p_tags>{t("nw.profile.security.sec_q.initial_explanation")}</Typography>
                <Box sx={{marginTop: 3}}>
                  <Select
                    id="sec1"
                    caption={t("nw.profile.security.sec_q.question_1")}
                    fullWidth
                    value={user?.sq1_id?.toString() || ''}
                    setValue={(v) => {
                      setUser({
                        ...({auth_token: '', email: ''}),
                        ...user,
                        sq1_id: !!v ? parseInt(v.toString()) : undefined
                      });
                    }}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      height: '40px',
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      backgroundColor: 'transparent',
                      "&:hover fieldset": {
                        borderColor: 'transparent !important'
                      }
                    }}
                  >
                    {!!securityQuestionsSuccess && securityQuestions.filter(fff => fff.id !== user?.sq2_id && fff.id !== user?.sq3_id).map(securityQuestion => <MenuItem value={securityQuestion.id}>{he.decode(i18n.resolvedLanguage === 'fr' ? securityQuestion.question_fr : securityQuestion.question_nl).replace(/<[^>]*>?/gm, '')}</MenuItem>)}
                  </Select>
                  <TextInput
                    value={user?.sq1_answer || ''}
                    onChange={(v) => {
                      setUser({
                        ...({auth_token: '', email: ''}),
                        ...user,
                        sq1_answer: v
                      });
                    }}
                    starred
                    caption={t("nw.profile.security.sec_q.question_1_answer")}
                    // placeholder={t("nw.contact.form.placeholder.email")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                      marginTop: 1
                    }}
                  />
                </Box>
                <Box sx={{marginTop: 3}}>
                  <Select
                    id="sec2"
                    caption={t("nw.profile.security.sec_q.question_2")}
                    fullWidth
                    value={user?.sq2_id?.toString() || ''}
                    setValue={(v) => {
                      setUser({
                        ...({auth_token: '', email: ''}),
                        ...user,
                        sq2_id: !!v ? parseInt(v.toString()) : undefined
                      });
                    }}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      height: '40px',
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      backgroundColor: 'transparent',
                      "&:hover fieldset": {
                        borderColor: 'transparent !important'
                      }
                    }}
                  >
                    {!!securityQuestionsSuccess && securityQuestions.filter(fff => fff.id !== user?.sq1_id && fff.id !== user?.sq3_id).map(securityQuestion => <MenuItem value={securityQuestion.id}>{he.decode(i18n.resolvedLanguage === 'fr' ? securityQuestion.question_fr : securityQuestion.question_nl).replace(/<[^>]*>?/gm, '')}</MenuItem>)}
                  </Select>
                  <TextInput
                    value={user?.sq2_answer || ''}
                    onChange={(v) => {
                      setUser({
                        ...({auth_token: '', email: ''}),
                        ...user,
                        sq2_answer: v
                      });
                    }}
                    starred
                    caption={t("nw.profile.security.sec_q.question_2_answer")}
                    // placeholder={t("nw.contact.form.placeholder.email")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                      marginTop: 1
                    }}
                  />
                </Box>
                <Box sx={{marginTop: 3}}>
                  <Select
                    id="sec3"
                    caption={t("nw.profile.security.sec_q.question_3")}
                    fullWidth
                    value={user?.sq3_id?.toString() || ''}
                    setValue={(v) => {
                      setUser({
                        ...({auth_token: '', email: ''}),
                        ...user,
                        sq3_id: !!v ? parseInt(v.toString()) : undefined
                      });
                    }}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      height: '40px',
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      backgroundColor: 'transparent',
                      "&:hover fieldset": {
                        borderColor: 'transparent !important'
                      }
                    }}
                  >
                    {!!securityQuestionsSuccess && securityQuestions.filter(fff => fff.id !== user?.sq2_id && fff.id !== user?.sq1_id).map(securityQuestion => <MenuItem value={securityQuestion.id}>{he.decode(i18n.resolvedLanguage === 'fr' ? securityQuestion.question_fr : securityQuestion.question_nl).replace(/<[^>]*>?/gm, '')}</MenuItem>)}
                  </Select>
                  <TextInput
                    value={user?.sq3_answer || ''}
                    onChange={(v) => {
                      setUser({
                        ...({auth_token: '', email: ''}),
                        ...user,
                        sq3_answer: v
                      });
                    }}
                    starred
                    caption={t("nw.profile.security.sec_q.question_3_answer")}
                    // placeholder={t("nw.contact.form.placeholder.email")}
                    sx={{
                      borderRadius: "8px",
                      width: '100%',
                      border: "2px solid transparent",
                      borderColor: theme.palette.primary.dark,
                      paddingTop: 0.25,
                      paddingBottom: 0.25,
                      height: '40px',
                      backgroundColor: 'transparent',
                      marginTop: 1
                    }}
                  />
                </Box>
                <Box sx={{marginTop: 4}}>
                  <DarkGreenButton
                    id="save"
                    label={t("nw.profile.security.mfa.cta.update")}
                    disabled={!user?.sq1_id || !user?.sq1_answer || !user?.sq2_id || !user?.sq2_answer || !user?.sq3_id || !user?.sq3_answer}
                    onClick={() => {
                      mutationCustomAction.mutate({
                        formData: {
                          object: 'user',
                          class_action: 'custom_api_save_profile',
                          id: login?.id,
                          ob: {
                            id: login?.id,
                            sec_q_status: 1,
                            sq1_id: user?.sq1_id,
                            sq1_answer: user?.sq1_answer,
                            sq2_id: user?.sq2_id,
                            sq2_answer: user?.sq2_answer,
                            sq3_id: user?.sq3_id,
                            sq3_answer: user?.sq3_answer
                          }
                        }
                      });
                    }}
                  />
                </Box>
              </Box>}
            </Grid>
          </Grid>
        </Cta>
      </Grid>
    </Grid>
  </Page>;
}

