import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";
// @ts-ignore
import HeroImg from "../../../img/cut/ArgenCo_Investeren_Intro_1024px.jpg";
// @ts-ignore
import HoeKopenImg from "../../../img/cut/ArgenCo_Itsme_Visual.jpg";
// @ts-ignore
import TipsImg from "../../../img/cut/ArgenCo_Hoe_Investeren_Tips_1024px.jpg";

import {
  Box,
  List,
  ListItem,
} from '@mui/material';

import {
  Cta,
  Footer,
  Hero,
  InputButton,
  Section,
  Waitlist,
  WhiteSection
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const StockHow: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  const liArrow = () => {
    return <Box sx={{
      "& svg": {
        transform: "rotate(90deg)",
        width: '24px',
        marginBottom: -1
      },
      marginRight: 2.5
    }}><Arrow /></Box>
  }
  const numCircle = (num:number) => {
    return <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '40px',
        padding: 1,
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'white',
        marginRight: 2.5
      }}
    >
      {num}
    </Box>;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      picRight={HeroImg}
      children={<>
        <Typography variant="h5" html fuck_p_tags>{t("nw.reclame")}</Typography>
        <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.stock.how.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{marginBottom: 4, color: theme.palette.primary.dark}}>{t("nw.stock.how.hero.subsection")}</Typography>
        <Box>
          <DarkGreenButton
            label={t("nw.stock.how.hero.cta.subscribe")}
            id="sub_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
              display: {
                xs: 'block',
                md: 'inline-flex'
              }
            }}
            path="/faq"
          />
          <LightGreenButton
            label={t("nw.stock.how.hero.cta.how")}
            id="how_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              marginLeft: {
                xs: 0,
                md: 2.5
              },
              marginTop: {
                xs: 2.5,
                md: 0
              }
            }}
            path="/buy"
          />
        </Box>
      </>}
    />

    <WhiteSection picLeft={HoeKopenImg}>
      <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.stock.how.howto.title")}</Typography>
      <List sx={{
        paddingLeft: 0,
        listStyle: 'none',
        marginBottom: 4,
        "& li": {
          paddingLeft: 0
        }
      }} component="ol">
        <ListItem>{numCircle(1)} <Typography variant="subtitle1">{t("nw.stock.how.howto.li1")}</Typography></ListItem>
        <ListItem>{numCircle(2)} <Typography variant="subtitle1">{t("nw.stock.how.howto.li2")}</Typography></ListItem>
        <ListItem>{numCircle(3)} <Typography variant="subtitle1">{t("nw.stock.how.howto.li3")}</Typography></ListItem>
        <ListItem>{numCircle(4)} <Typography variant="subtitle1">{t("nw.stock.how.howto.li4")}</Typography></ListItem>
        <ListItem>{numCircle(5)} <Typography variant="subtitle1">{t("nw.stock.how.howto.li5")}</Typography></ListItem>
      </List>
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        padding: 2.5,
        borderRadius: '6px'
      }}>
        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("nw.stock.how.howto.end_title")}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks>{t("nw.stock.how.howto.end_body")}</Typography>
      </Box>
    </WhiteSection>

    <WhiteSection picRight={TipsImg}>
      <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.stock.how.tips.title")}</Typography>
      <List sx={{
        paddingLeft: 0,
        listStyle: 'none',
        "& li": {
          paddingLeft: 0
        }
      }} component="ul">
        <ListItem>{liArrow()} <Typography variant="subtitle1">{t("nw.stock.how.tips.li1")}</Typography></ListItem>
        <ListItem>{liArrow()} <Typography variant="subtitle1">{t("nw.stock.how.tips.li2")}</Typography></ListItem>
        <ListItem>{liArrow()} <Typography variant="subtitle1">{t("nw.stock.how.tips.li3")}</Typography></ListItem>
        <ListItem>{liArrow()} <Typography variant="subtitle1">{t("nw.stock.how.tips.li4")}</Typography></ListItem>
        <ListItem>{liArrow()} <Typography variant="subtitle1">{t("nw.stock.how.tips.li5")}</Typography></ListItem>
        <ListItem>{liArrow()} <Typography variant="subtitle1">{t("nw.stock.how.tips.li6")}</Typography></ListItem>
      </List>
    </WhiteSection>

    <Section normalPage>
      <Waitlist />
    </Section>

    <Section normalPage>
      <Box sx={{
        padding: 4,
        border: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px'
      }}>
        <Typography variant="body1" html fuck_p_tags sx={{
          textAlign: 'center',
          fontSize: '0.8rem',
          lineHeight: '18px'
        }}>{t("nw.disclaimer_long")}</Typography>
      </Box>
    </Section>

    <Footer normalPage />
  </Page>;
}

