import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Cta,
  FaqBlock,
  Footer,
  InputButton,
  Section,
  WhiteSection
} from '../../components/v2/custom';
import {
  LightGreenButton,
  Page,
  Typography
} from '../../components/v2/styled';

import { Faq as FaqItem } from '../../models/Faq';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const Faqs: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [searchParams, setSearchParams] = useSearchParams();

  const [faqList, setFaqList] = useState<{id: number, weight: number, name: {[z:string]: string}, faqs: FaqItem[]}[]>([]);
  const [currentFilter, setCurrentFilter] = useState<number>(0);
  const [currentSearch, setCurrentSearch] = useState<string>(searchParams.get("s") || '');
  const [currentSearchInput, setCurrentSearchInput] = useState<string>(searchParams.get("s") || '');
  const [page, setPage] = useState<number>(0);
  let count = 0;

  const {
    data: faqs,
    isLoading: faqsLoading,
    // isError: adviceUploadsError,
    isSuccess: faqsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "faqs", page, {search: currentSearch, filter: currentFilter}],
    queryFn: () =>
      fetch_all_with_count<FaqItem>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'faq',
          fields: ['id', 'created_at', 'alias_title', 'alias_body', 'tag|id/name/weight'],
          per_page: 1000,
          page: page,
          order: 'id ASC',
          filter: {
            advanced: {
              enabled: 1,
              catch_all: currentSearch
            }
          }
        },
        undefined
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    // enabled: false,
  });







  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    if (!!faqs) {
      let res:any = {};
      faqs.forEach(faq => {
        res[faq.tag_name?.nl || 'x'] ||= {
          id: faq.tag_id || 0,
          name: faq.tag_name || 'x',
          faqs: []
        }
        res[faq.tag_name?.nl || 'x'].faqs.push(faq);
      });
      setFaqList(Object.values(res));
    }
  }, [faqs]);

  return <Page>
    <Section normalPage>
      <Cta>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.faqs.hero.title")}</Typography>
        <Box sx={{
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          <InputButton
            value={currentSearchInput}
            setValue={(v) => {
              setCurrentSearchInput(v);
            }}
            btnLabel={t("nw.faq.search.cta_subscribe")}
            placeholder={t("nw.faq.search.cta_placeholder")}
            onClick={() => {
              setCurrentSearch(currentSearchInput);
            }}
            sx={{
              width: {
                xs: '100%',
                md: '400px'
              },
              marginTop: -0.25
            }}
          />
        </Box>
        <Typography variant="body2" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.faqs.hero.subsection")}</Typography>
      </Cta>
    </Section>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        {!faqsLoading && faqsSuccess && faqList.sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0)).map(faq => <Grid item xs={12} md={6} lg={4}>
          <FaqBlock
            id={faq.id}
            name={faq.name[i18n.resolvedLanguage || 'nl']}
            faqs={faq.faqs}
            search={currentSearchInput}
          />
        </Grid>)}
      </Grid>
    </WhiteSection>

    <Section normalPage>
      <Cta>
        <Typography variant="h2" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.faqs.bottom.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.faqs.bottom.subsection")}</Typography>
        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          <LightGreenButton
            id="goto_contact"
            label={t("nw.faqs.bottom.cta")}
            path="/contact"
          />
        </Box>
      </Cta>
    </Section>

    <Footer normalPage />
  </Page>;
}

