import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom, do_login_otp } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { DropzoneArea } from 'mui-file-dropzone';
import QRCode from "react-qr-code";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCodeIcon from '@mui/icons-material/QrCode';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import {
  WhiteSection
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  ShareShower,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { EmissionKeyAlt1 } from '../../../components/v2/custom/EmissionKeyAlt1';
import { WideButton } from '../../../components/v2/styled/WideButton';
import { ErrorBox } from '../../../components/v2/styled/ErrorBox';

import { EmissionKey } from '../../../models/EmissionKey';
import { Guardian } from '../../../models/Guardian';
import { User } from '../../../models/User';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {}

export const EmissionStocks: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const { state } = useLocation();
  const params = useParams();

  const [stockId, setStockId] = useState<string | undefined>(params.id);
  const [step, setStep] = useState<number>(!!state?.step ? state.step : -1);
  const [emissionKeys, setEmissionKeys] = useState<EmissionKey[]>([]);
  const [activeKey, setActiveKey] = useState<EmissionKey>();
  const [guardian, setGuardian] = useState<Guardian>();
  const [user, setUser] = useState<User>({
    id: undefined,
    auth_token: '',
    email: ''
  });
  const [showError, setShowError] = useState<boolean>(false);
  const [showQr, setShowQr] = useState<boolean>(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
  const [showBorderelSuccess, setShowBorderelSuccess] = useState<boolean>(false);

  const testingNewUserLimit = 1;
  const testing = 0;

  // STEP




  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      step: number;
      extra?: string;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      console.log(data);
      // console.log(variables);
      setShowError(false);
      // setActiveKey(undefined);

      if (variables.step === -1) {
        // initial fetch
        if (!!data.custom_result.enabled) {
          setStep(0);
        } else {
          setStep(999);
        }
      } else {
        if (variables.formData.mode === 'itsme_borderel') {
          if (!!variables.formData.amount) {
            // @ts-ignore
            if (!!window.dataLayer) window.dataLayer.push({
              event: 'emission',
              eventProps: {
                category: 'stocks',
                action: 'bought',
                label: 'success',
                value: variables.formData.amount
              }
            });
          }
          window.location = data.custom_result.active_key.itsme_url;
        } else {
          if (variables.formData.mode === 'save_amount') {
            setActiveKey(undefined);
            mutationCustomAction.mutate({
              formData: {
                object: 'emission',
                class_action: 'custom_api_get_info',
                testing: testing,
                stock_id: stockId,
                e_keys: 1
              },
              step: step
            });
            if (!!variables.formData.amount) {
              // @ts-ignore
              if (!!window.dataLayer) window.dataLayer.push({
                event: 'emission',
                eventProps: {
                  category: 'stocks',
                  action: 'bought',
                  label: 'success',
                  value: variables.formData.amount
                }
              });
            }
          } else {
            if (variables.extra === 'save_borderel') {
              setActiveKey(undefined);
              mutationCustomAction.mutate({
                formData: {
                  object: 'emission',
                  class_action: 'custom_api_get_info',
                  testing: testing,
                  stock_id: stockId,
                  e_keys: 1
                },
                step: step
              });
            } else {
              if (variables.formData.mode === 'cancel') {
                setActiveKey(undefined);
                mutationCustomAction.mutate({
                  formData: {
                    object: 'emission',
                    class_action: 'custom_api_get_info',
                    testing: testing,
                    stock_id: stockId,
                    e_keys: 1
                  },
                  step: step
                });
              } else {
      
              }
            }
          }
        }
      }

      if (!!data.custom_result.user) {
        setUser({
          ...data.custom_result.user,
          birth_date: DateTime.fromISO(data.custom_result.user?.birth_date).toFormat("dd/LL/y"),
          vennoot_number: data.custom_result.user?.vennoot_number?.indexOf("-") > -1 ? data.custom_result.user?.vennoot_number : ''
        });
      }
      if (!!data.custom_result.guardian) {
        setGuardian({
          ...data.custom_result.guardian
        });
      }
      if (!!data.custom_result.emission_keys) {
        setEmissionKeys(data.custom_result.emission_keys);
      }
      if (!data.custom_result.success) {
        setShowError(true);
      }
      if (!!data.custom_result.open_active_key) {
        setActiveKey(data.custom_result.active_key);
      }
    },
  });








  const confirm_with_borderel = (generate_borderel:boolean) => {
    mutationCustomAction.mutate({
      formData: {
        object: 'emission',
        class_action: 'custom_api_get_info',
        testing: testing,
        mode: 'save_amount',
        active_key: activeKey?.id,
        amount: activeKey?.proposed_amount,
        generate_borderel: !!generate_borderel ? 1 : 0,
        stock_id: stockId
      },
      step: 100
    });
    // this.setState({loading: true}, () => {
    //   event.preventDefault();

    //   let formData = new FormData();
    //   formData.append('active_key', this.state.stock_step.active_key.id);
    //   formData.append('amount', this.state.stock_step.active_key.proposed_amount);
    //   formData.append('generate_borderel', (generate_borderel ? 1 : 0));
    //   formData.append('step', 'save_amount');
    //   formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
    //   formData.append('testing', (!!this.state.testing ? 1 : 0));
    //   formData.append('sub', this.state.sub);
    //   formData.append('stock_id', this.state.stock_id);

    //   this.props.helpers.user.emission_information({
    //     locale: this.props.i18n.language,
    //     login: this.props.login,
    //     callback: (r, full_data) => {
    //       this.setState({user: r.user, emission_information: r, loading: false, stock_step: {...this.state.stock_step, active_key: r.active_key, show_step_success: true}}, () => {
    //         this.props.onChangeState(this.state);
    //         window.scrollTo(0, 0);
    //       });
    //     },
    //     error_callback: (r) => {
    //       this.setState({show_error: true, loading: false}, () => {
    //         this.props.onChangeState(this.state);
    //       });
    //     },
    //     parameters: formData
    //   });
    // });
  }

  const save_itsme_borderel = () => {
    mutationCustomAction.mutate({
      formData: {
        object: 'emission',
        class_action: 'custom_api_get_info',
        testing: testing,
        mode: 'itsme_borderel',
        active_key: activeKey?.id,
        amount: activeKey?.proposed_amount,
        stock_id: stockId
      },
      step: 100
    });

    // this.setState({loading: true}, () => {
    //   event.preventDefault();

    //   let formData = new FormData();
    //   formData.append('active_key', this.state.stock_step.active_key.id);
    //   formData.append('amount', this.state.stock_step.active_key.proposed_amount);
    //   formData.append('step', 'itsme_borderel');
    //   formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
    //   formData.append('testing', (!!this.state.testing ? 1 : 0));
    //   formData.append('sub', this.state.sub);
    //   formData.append('stock_id', this.state.stock_id);

    //   this.props.helpers.user.emission_information({
    //     locale: this.props.i18n.language,
    //     login: this.props.login,
    //     callback: (r, full_data) => {
    //       window.location = r.active_key.itsme_url;
    //     },
    //     error_callback: (r) => {
    //       this.setState({show_error: true, loading: false}, () => {
    //         this.props.onChangeState(this.state);
    //       });
    //     },
    //     parameters: formData
    //   });
    // });
  }

  const save_borderel = () => {
    // formdata
    const ob = {
      object: 'emission',
      class_action: 'custom_api_get_info',
      testing: testing,
      mode: 'save_borderel',
      active_key: activeKey?.id,
      item: activeKey?.item,
      stock_id: stockId
    };
    const formData = new FormData();
    Object.keys(ob).forEach(key => {
      // @ts-ignore
      formData.append(key, ob[key]);
    });

    mutationCustomAction.mutate({
      formData: formData,
      step: 100,
      extra: 'save_borderel'
    });

    // this.setState({loading: true}, () => {
    //   event.preventDefault();

    //   let formData = new FormData();
    //   formData.append('active_key', this.state.stock_step.active_key.id);
    //   formData.append('item', this.state.stock_step.active_key.item);
    //   formData.append('step', 'save_borderel');
    //   formData.append('emission_id', (!!this.state.testing ? this.state.testing_id : this.state.emission.id));
    //   formData.append('testing', (!!this.state.testing ? 1 : 0));
    //   formData.append('sub', this.state.sub);
    //   formData.append('stock_id', this.state.stock_id);

    //   this.props.helpers.user.emission_information({
    //     locale: this.props.i18n.language,
    //     login: this.props.login,
    //     callback: (r, full_data) => {
    //       this.setState({user: r.user, emission_information: r, loading: false, stock_step: {...this.state.stock_step, active_key: r.active_key}}, () => {
    //         this.props.onChangeState(this.state);
    //         window.scrollTo(0, 0);
    //       });
    //     },
    //     error_callback: (r) => {
    //       this.setState({show_error: true, loading: false}, () => {
    //         this.props.onChangeState(this.state);
    //       });
    //     },
    //     parameters: formData
    //   });
    // });
  }

  const tableRow = (label: string, content: string|undefined, children: any, divider: boolean, copyable?: boolean, lessPadding?: boolean) => {
    return <>
      <Grid item xs={4} sx={{
        paddingTop: !lessPadding ? {
          xs: 1,
          md: 2,
          lg: 3
        } : 0,
        paddingBottom: !lessPadding ? {
          xs: 1,
          md: 2,
          lg: 3
        } : 0,
        paddingLeft: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }}>
        <Typography sx={{
          textTransform: 'uppercase',
          color: theme.palette.primary.light
        }}>{label}</Typography>
      </Grid>
      <Grid item xs={8} sx={{
        paddingTop: !lessPadding ? {
          xs: 1,
          md: 2,
          lg: 3
        } : 0,
        paddingBottom: !lessPadding ? {
          xs: 1,
          md: 2,
          lg: 3
        } : 0,
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        position: 'relative'
      }}>
        {!!content && <Typography variant="body1" html fuck_p_tags>{content}</Typography>}
        {!!children && children}
        {!!copyable && <IconButton
          color="primary"
          sx={{
            position: 'absolute',
            top: {
              xs: !!lessPadding ? -8 : -4,
              md: !!lessPadding ? -8 : 12
            },
            right: 8
          }}
          title={he.decode(t("nw.emission.steps.stock.order_detail.cta_hint.copy").replace(/<[^>]*>?/gm, ''))}
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(he.decode((content || '').replace(/<[^>]*>?/gm, '')));
          }}
        >
          <ContentCopyIcon />
        </IconButton>}
      </Grid>

      {!!divider && <Grid item xs={12}>
        <Divider />
      </Grid>}
    </>;
  }
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    // console.log(step, state);
    mutationCustomAction.mutate({
      formData: {
        object: 'emission',
        class_action: 'custom_api_get_info',
        testing: testing,
        stock_id: stockId,
        e_keys: 1
      },
      step: step
    });
  }, []);

  return <Page>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      },
      "& > div > div > div > div": {
        minHeight: 20,
        height:'auto'
      }
    }}>
      <Grid container spacing={0} sx={{
        width: {
          xs: '100%',
          lg: '900px'
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 8,
        marginBottom: 8
      }}>
        {step === 0 && !user?.id && <Grid item xs={12}>
          <Typography html fuck_p_tags>{t("nw.emission.stocks.error_no_user")}</Typography>
          <TextButton
            id="back_btn"
            label={t("nw.emission.forgot.cta_back")}
            onClick={() => {
              navigate('/');
              window.scrollTo(0, 0);
            }}
            sx={{
              marginLeft: 1,
              marginRight: 1,
              marginTop: 1
            }}
          />
        </Grid>}
        {step === 0 && !!user?.id && <>
          {!activeKey && <>
            <Grid item xs={12} sx={{
              marginBottom: 2.5
            }}>
              <Typography variant="h2">{t("nw.emission.stocks.my_orders")}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{
              marginBottom: 2.5
            }}>
              <Typography variant="body1">{t("nw.profile.hello").replace(/<[^>]*>?/gm, '')} {user?.first_name} {user?.last_name}</Typography>
              <Typography variant="body1">{t("nw.profile.vennoot_number").replace(/<[^>]*>?/gm, '')} {user?.vennoot_number}</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{
              marginBottom: 2.5,
              textAlign: {
                xs: 'left',
                md: 'right'
              }
            }}>
              {!guardian && <Typography variant="body1">&nbsp;</Typography>}
              {!!guardian && <Typography variant="body1">{t("nw.emission.stocks.subtitle_guardian").replace(/<[^>]*>?/gm, '')} {guardian.first_name} {guardian.last_name}</Typography>}
              <Typography variant="body1">{t("nw.menu.current_saldo").replace(/<[^>]*>?/gm, '')} {user?.stock_number_amount} EUR</Typography>
            </Grid>
            <Grid item xs={12} sx={{
              marginBottom: 2.5
            }}>
              <Typography variant="body1" html fuck_p_tags>{t("nw.emission.stocks.subtitle")}</Typography>
              {emissionKeys.length > 0 && <Typography variant="body1" html fuck_p_tags>{t("nw.emission.stocks.subtitle_asn", {asn: user.main_asn_key})}</Typography>}
            </Grid>
          </>}

          {!activeKey && <>
            <Grid item xs={12} md={6} lg={6} sx={{
              marginBottom: 2.5,
              paddingLeft: {
                xs: 0,
                md: 1.25
              },
              paddingRight: {
                xs: 0,
                md: 1.25
              }
            }}>
              <EmissionKeyAlt1
                isNew
                shareTypeTag={user.share_type_tag as 'A' | 'B'}
                onClick={() => {
                  setActiveKey({
                    proposed_amount: 0,
                    status_key: 'open'
                  });
                }}
              />
            </Grid>
          </>}
          {!activeKey && emissionKeys.length > 0 && emissionKeys.map(emissionKey => <>
            <Grid item xs={12} md={6} lg={6} sx={{
              marginBottom: 2.5,
              paddingLeft: {
                xs: 0,
                md: 1.25
              },
              paddingRight: {
                xs: 0,
                md: 1.25
              }
            }}>
              <EmissionKeyAlt1
                emissionKey={emissionKey}
                shareTypeTag={user.share_type_tag as 'A' | 'B'}
                isApproved={emissionKey.status_key === 'approved'}
                onClick={() => {
                  setActiveKey(emissionKey);
                }}
              />
            </Grid>
          </>)}

          {!!activeKey && <>
            {!activeKey.id && <Grid item xs={12} sx={{
              marginBottom: 2.5
            }}>
              <Typography variant="h2">{t("nw.emission.stocks.new_order.title")}</Typography>
            </Grid>}
            {!!activeKey.id && <Grid item xs={12} sx={{
              marginBottom: 2.5
            }}>
              <Typography variant="h2" sx={{textAlign: 'center'}}>{t("nw.emission.stocks.existing_order.title", {date: activeKey.time_requested})}</Typography>
            </Grid>}

            {activeKey.status_key !== 'open' && <>
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                <Box
                  sx={{
                    width: {
                      xs: '100%',
                      md: '100%',
                      lg: '670px'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: 'white'
                  }}
                >
                  <Grid container spacing={0}>
                    {tableRow(
                      t("nw.emission.steps.stock.order_detail.time_requested"), 
                      activeKey.time_requested, 
                      undefined, 
                      true
                    )}

                    {tableRow(
                      t("nw.emission.steps.stock.order_detail.amount"), 
                      undefined, 
                      <ShareShower sx={{fontWeight: {xs: 'bold', md: 'normal'}}} amount={activeKey.proposed_amount} share={user.share_type_tag as 'A' | 'B'} />, 
                      true
                    )}

                    {(user.stock_number || 0) < 1 && !activeKey.borderel_signed_updated_at && activeKey.status_key === 'borderel' && tableRow(
                      t("nw.emission.steps.stock.order_detail.borderel_uploaded"), 
                      !activeKey.borderel_signed_updated_at ? t("emission.steps.stock.order_detail.borderel_uploaded_not") : t("emission.steps.stock.order_detail.borderel_uploaded_yes", {time: activeKey.borderel_signed_updated_at}), 
                      undefined, 
                      true
                    )}

                    {activeKey.status_key === 'awaiting_payment' && tableRow(
                      t("nw.emission.steps.stock.order_detail.payment"), 
                      t("nw.emission.steps.stock.order_detail.payment_not_received"), 
                      undefined, 
                      true
                    )}

                    {activeKey.status_key === 'awaiting_payment' && tableRow(
                      t("nw.emission.steps.stock.order_detail.payment_title.amount"), 
                      `${parseInt(activeKey.proposed_value?.toString() || '-1', 10)} EUR`, 
                      <IconButton color="primary" sx={{position: 'absolute', top: 8, right: 8}} title={he.decode(t("nw.emission.steps.stock.order_detail.cta_hint.qr_code").replace(/<[^>]*>?/gm, ''))} onClick={(e) => {
                        setShowQr(true);
                      }}>
                        <QrCodeIcon />
                      </IconButton>, 
                      false,
                      false
                    )}

                    {activeKey.status_key === 'awaiting_payment' && tableRow(
                      t("nw.emission.steps.stock.order_detail.payment_title.account"), 
                      t("nw.emission.steps.stock.order_detail.payment_title.account_value"), 
                      undefined, 
                      false,
                      true,
                      true
                    )}

                    {activeKey.status_key === 'awaiting_payment' && tableRow(
                      t("nw.emission.steps.stock.order_detail.payment_title.strucmed"), 
                      user.main_asn_key, 
                      undefined, 
                      true,
                      true
                    )}

                    {(activeKey.status_key === 'paid' || !!activeKey.fake_paid_status) && tableRow(
                      t("nw.emission.steps.stock.order_detail.payment"), 
                      t("nw.emission.steps.stock.order_detail.payment_received"), 
                      undefined, 
                      true
                    )}

                    {activeKey.status_key === 'approved' && tableRow(
                      t("nw.emission.steps.stock.order_detail.assigned_on"), 
                      activeKey.assigned_on, 
                      undefined, 
                      true
                    )}

                    {activeKey.status_key !== 'approved' && !!activeKey.id && tableRow(
                      `&nbsp;`, 
                      undefined, 
                      <TextButton
                        startIcon={<DeleteForeverIcon />}
                        onClick={(e) => {
                          setShowConfirmCancel(true);
                        }}
                        id="cancel"
                        label={t("nw.emission.steps.stock.cta.cancel_order")}
                        sx={{
                          color: 'red',
                          paddingLeft: 0,
                          paddingRight: 0,
                          width: 'auto'
                        }}
                      />, 
                      false
                    )}
                  </Grid>
                </Box>
              </Grid>
            </>}

            {activeKey.status_key === 'borderel' && showBorderelSuccess && <>
              <Grid item xs={12} sx={{marginBottom: 2, marginTop: 2}}>
                <ErrorBox
                  type="success"
                  text={t("nw.emission.steps.stock.msg_success.borderel")}
                />
              </Grid>
            </>}

            {activeKey.status_key === 'open' && <>
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                {user.share_type_tag !== 'A' && <Typography variant="body1" html sx={{
                  marginTop: -1
                }}>{t("nw.emission.steps.stock.order_extra")}</Typography>}
                {user.share_type_tag === 'A' && <Typography variant="body1" html sx={{
                  marginTop: -1
                }}>{t("nw.emission.steps.stock_for_a.order_extra")}</Typography>}
              </Grid>
              <Grid item xs={12} sx={{marginTop: -1, marginBottom: 2.5}}>
                <Typography variant="caption">{t(`nw.emission.steps.stock.field.amount`)}</Typography>
              </Grid>
              <Grid item xs={6} sx={{
                marginBottom: 2.5
              }}>
                <TextInput
                  value={activeKey.proposed_amount?.toString() || ''}
                  id='amount'
                  onChange={(v) => {
                    let a = parseInt(v, 10);
                    if (!a || a < 0 || a > (user.available_stock_number || 0)) a = 0;
                    setActiveKey({
                      ...activeKey,
                      proposed_amount: a
                    });
                  }}
                  sx={{
                    borderRadius: "8px",
                    width: '100%',
                    border: "2px solid transparent",
                    borderColor: theme.palette.primary.dark,
                    paddingTop: 0.25,
                    paddingBottom: 0.25,
                    height: '40px',
                    backgroundColor: 'transparent',
                    marginTop: -1
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{alignSelf: 'center',
                marginBottom: 2.5,
                paddingLeft: 2.5
              }}>
                <Typography variant="body1">{t("nw.emission.steps.stock.field.amount_calculated", {
                  amount: ((activeKey.proposed_amount || 0) * (user.share_type_tag === "A" ? 100 : 50)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                })}</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                <Typography variant="body1" sx={{
                  fontStyle: 'italic',
                  fontSize: '0.8rem',
                  marginTop: -1
                }}>{t("nw.emission.steps.stock.max_amount_note", {amount: (user.available_stock_number?.toString() || '200')})}</Typography>
              </Grid>
            </>}

            {activeKey.status_key === 'open' && (user.stock_number || 0) < testingNewUserLimit && <>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative',
                marginBottom: 2.5
              }}>
                <Typography variant="h2">{t("nw.emission.steps.stock.title.conditions")}</Typography>
              </Grid>
              <Grid item xs={2} sx={{marginTop: -0.5,
                marginBottom: 2.5
              }}>
                <Checkbox
                  checked={!!activeKey.conditions}
                  onChange={(v) => {
                    setActiveKey({
                      ...activeKey,
                      conditions: v.target.checked
                    });
                  }}
                />
              </Grid>
              <Grid item xs={10} sx={{
                marginBottom: 2.5,
                paddingLeft: 2.5
              }}>
                <Typography variant="body1" html fuck_p_tags onClick={() => {
                  setActiveKey({
                    ...activeKey,
                    conditions: !activeKey.conditions
                  });
                }}>{t("nw.emission.steps.stock.title.conditions_checkbox")}</Typography>
              </Grid>
            </>}

            {activeKey.status_key === 'open' && (user.stock_number || 0) < testingNewUserLimit && <>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative',
                marginBottom: 2.5
              }}>
                <Typography variant="h2">{t("nw.emission.steps.stock.title.confirm")}</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                {!(!!user.itsme_sub && !user.has_guardian) && <Typography variant="body1" html fuck_p_tags>{t("nw.emission.steps.stock.confirm_extra")}</Typography>}
                {!!(!!user.itsme_sub && !user.has_guardian) && <Typography variant="body1" html fuck_p_tags>{t("nw.emission.steps.stock.confirm_extra_itsme")}</Typography>}
              </Grid>
              {!user.has_guardian && <Grid item xs={12} md={6} sx={{padding: 1,
                marginBottom: 2.5
              }}>
                <WideButton
                  disabled={!activeKey.conditions || !activeKey.proposed_amount}
                  label={t("nw.emission.steps.stock.borderel.itsme")}
                  id="borderel_itsme"
                  itsme
                  // is_confirm
                  onClick={() => {
                    if (!(!activeKey.conditions || !activeKey.proposed_amount)) save_itsme_borderel();
                  }}
                />
              </Grid>}
              <Grid item xs={12} md={6} sx={{padding: 1,
                marginBottom: 2.5
              }}>
                <WideButton
                  disabled={!!mutationCustomAction.isPending || !activeKey.conditions || !activeKey.proposed_amount}
                  print
                  loading={mutationCustomAction.isPending}
                  label={t("nw.emission.steps.stock.borderel.not_itsme")}
                  id="borderel_print"
                  onClick={(e) => {
                    // this.setState({stock_step: {...this.state.stock_step, active_key: {...this.state.stock_step.active_key, use_itsme: 2}}}, () => {
                    //   this.props.onChangeState(this.state);
                      if (!(!!mutationCustomAction.isPending || !activeKey.conditions || !activeKey.proposed_amount)) confirm_with_borderel(true);
                    // });
                  }}
                />
              </Grid>
            </>}

            {activeKey.status_key === 'open' && (user.stock_number || 0) >= testingNewUserLimit && <>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative',
                marginBottom: 2.5
              }}>
                <Typography variant="h2">{t("nw.emission.steps.stock.title.confirm_existing")}</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                <Typography variant="body1" html fuck_p_tags>{t("nw.emission.steps.stock.confirm_extra_existing")}</Typography>
              </Grid>
              <Grid item xs={12} sx={{textAlign: 'center',
                marginBottom: 2.5
              }}>
                <DarkGreenButton
                  disabled={!!mutationCustomAction.isPending || !activeKey.proposed_amount}
                  loading={mutationCustomAction.isPending}
                  label={t("nw.emission.steps.stock.confirm")}
                  id="do_borderel"
                  onClick={(e) => {
                    // this.setState({loading: true}, () => {
                    //   this.props.onChangeState(this.state);
                    confirm_with_borderel(false);
                    // });
                  }}
                  sx={{
                    color: 'white',
                    "&.Mui-disabled": {
                      color: 'white',
                    }
                  }}
                />
              </Grid>
            </>}

            {activeKey.status_key === 'borderel' && <>
              <Grid item xs={12} sx={{marginTop: 4, position: 'relative',
                marginBottom: 2.5
              }}>
                <Typography variant="h2">{t("nw.emission.steps.stock.title.borderel")}</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                <Typography html fuck_p_tags>{t("nw.emission.steps.stock.title.borderel_intro")}</Typography>
              </Grid>
              {(activeKey.borderel_blank || 'missing.png').indexOf("missing.png") < 0 && <Grid item xs={12} sx={{marginTop: -3, marginBottom: 2.5}}>
                <DarkGreenButton
                  label={t("nw.emission.steps.stock.detail.cta.blank_document").replace(/<[^>]*>?/gm, '')}
                  sx={{marginTop: 1}}
                  id="borderel"
                  onClick={(e) => {
                    window.open(activeKey.borderel_blank, '_BLANK');
                  }}
                />
              </Grid>}
              <Grid item xs={12} sx={{
                marginBottom: 2.5
              }}>
                <Typography html fuck_p_tags>{t("nw.emission.steps.stock.title.borderel_extra")}</Typography>
              </Grid>
              <Grid item xs={12} sx={{
                display: {
                  xs: 'none', md: 'flex'
                },
                "& .cust_dropzonearea > div > div": {
                  flexBasis: 'auto !important',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                },
                marginBottom: 2.5
              }}>
                <DropzoneArea
                  acceptedFiles={['image/gif', 'image/jpeg', 'image/png', '.pdf']}
                  filesLimit={1}
                  fileObjects={[]}
                  dropzoneText={he.decode(t('nw.general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
                  onChange={(files) => {
                    setActiveKey({
                      ...activeKey,
                      item: files[0]
                    });
                  }}
                  maxFileSize={3000000}
                  previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                  dropzoneClass="cust_dropzonearea"
                />
              </Grid>
              <Grid item xs={12} sx={{
                display: {
                  xs: 'flex', md: 'none'
                },
                "& .cust_dropzonearea > div > div": {
                  flexBasis: 'auto !important',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                },
                marginBottom: 2.5
              }}>
                <DropzoneArea
                  acceptedFiles={['image/gif', 'image/jpeg', 'image/png', '.pdf']}
                  filesLimit={1}
                  fileObjects={[]}
                  dropzoneText={he.decode(t('nw.general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
                  onChange={(files) => {
                    setActiveKey({
                      ...activeKey,
                      item: files[0]
                    });
                  }}
                  maxFileSize={3000000}
                  previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
                  dropzoneClass="cust_dropzonearea"
                />
              </Grid>
            </>}

            {showError && <Grid item xs={12} sx={{
              marginBottom: 2.5
            }}>
              <Typography variant="body1" html fuck_p_tags>{t("nw.emission.steps.stock.generic_error")}</Typography>
            </Grid>}

            {activeKey.status_key === 'borderel' && <>
              <Grid item xs={12} sx={{marginTop: 4,
                marginBottom: 2.5
              }}>
                <DarkGreenButton
                  disabled={!activeKey.item || mutationCustomAction.isPending}
                  loading={mutationCustomAction.isPending}
                  onClick={(e) => {
                    // this.setState({loading: true}, () => {
                    //   this.props.onChangeState(this.state);
                      save_borderel();
                    // });
                  }}
                  id="save_borderel"
                  label={t("nw.emission.steps.stock.cta.upload_borderel")}
                  sx={{
                    color: 'white',
                    "&.Mui-disabled": {
                      color: 'white',
                    }
                  }}
                />
              </Grid>
            </>}



            <Grid item xs={12} sx={{textAlign: 'right', marginBottom: 8}}>
              <Box sx={{
                display: 'block',
                width: '100%',
                maxWidth: {
                  xs: '100%',
                  lg: '440px'
                },
                marginTop: 2,
                marginRight: 'auto',
                marginLeft: 'auto',
                textAlign: 'right'
              }}>
                <Box sx={{
                  display: 'inline-block',
                  width: '100%',
                  textAlign: 'center',
                }}>
                  <TextButton
                    label={t("nw.emission.forgot.cta_back")}
                    id="sub_btn"
                    onClick={() => {
                      setActiveKey(undefined);
                    }}
                    sx={{
                      marginBottom: 1,
                      color: theme.palette.primary.dark,
                      textDecoration: 'underline',
                      "&:hover": {
                        color: theme.palette.primary.light,
                        backgroundColor: 'transparent'
                      },
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </>}
        </>}
      </Grid>
    </WhiteSection>

    <Dialog
      open={showQr}
      disableScrollLock
      onClose={() => {
        setShowQr(false);
      }}
    >
      <DialogTitle><Typography>{t("nw.emission.steps.stock.qr_confirm.title")}</Typography></DialogTitle>
      <DialogContent>
        <DialogContentText sx={{textAlign: 'center'}}>
          <Typography sx={{textAlign: 'left'}} html>{t("nw.emission.steps.stock.qr_confirm.body")}</Typography>
          {!!activeKey && <QRCode value={`BCD\n001\n1\nSCT\nARSPBE22\nArgen-Co CV\nBE84973171450059\nEUR${activeKey.proposed_value}\n\n${user?.main_asn_key}\n\n`} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setShowQr(false);
        }}>{he.decode(t("nw.emission.steps.stock.qr_confirm.cta_ok").replace(/<[^>]*>?/gm, ''))}</Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={showConfirmCancel}
      disableScrollLock
      onClose={() => {
        setShowConfirmCancel(false);
      }}
    >
      <DialogTitle><Typography>{t("nw.emission.steps.stock.cancel_order.title")}</Typography></DialogTitle>
      <DialogContent>
        <DialogContentText sx={{textAlign: 'center'}}>
          <Typography sx={{textAlign: 'left'}} html>{t("nw.emission.steps.stock.cancel_order.body")}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setShowConfirmCancel(false);
        }}>{he.decode(t("nw.emission.steps.stock.cancel_order.cta_back").replace(/<[^>]*>?/gm, ''))}</Button>
        <Button onClick={() => {
          mutationCustomAction.mutate({
            formData: {
              object: 'emission',
              class_action: 'custom_api_get_info',
              testing: testing,
              mode: 'cancel',
              active_key: activeKey?.id,
              stock_id: stockId
            },
            step: 100
          });
        }} sx={{
          color: 'red'
        }}>{he.decode(t("nw.emission.steps.stock.cancel_order.cta_delete").replace(/<[^>]*>?/gm, ''))}</Button>
      </DialogActions>
    </Dialog>
  </Page>;
}

