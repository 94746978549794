import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  Grid,
  MenuItem,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  Select,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Birthdate } from '../../../components/v2/styled/Birthdate';
import { ErrorBox } from '../../../components/v2/styled/ErrorBox';
import { ZichtAccount } from '../../../components/v2/styled/ZichtAccount';
import { DropzoneArea } from 'mui-file-dropzone';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
  user?: User;
  setUser: (u:User) => void;
  onSubmit: () => void;
  loading?: boolean;
}

export const EmissionChoiceMeNewNew: FunctionComponent<Props> = ({setStep, user, setUser, onSubmit, loading}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [isOkay, setIsOkay] = useState<boolean>(false);







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_personal")}</Typography>
    </Grid>
    {/* <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me.subsection")}</Typography>
    </Grid> */}
    <Grid item xs={5}>
      <TextInput
        value={user?.first_name || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            first_name: v.toString()
          });
        }}
        starred
        caption={t("nw.user_info.first_name")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={7}>
      <TextInput
        value={user?.last_name || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            last_name: v.toString()
          });
        }}
        starred
        caption={t("nw.user_info.last_name")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={7}>
      <Birthdate
        value={user?.birth_date || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            birth_date: v.target.value
          });
        }}
        starred
        caption={t("nw.user_info.birth_date")}
        // placeholder={t("nw.contact.form.placeholder.first_name")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={5}>
      <Select
        id="subjects"
        caption={t("nw.user_info.gender")}
        fullWidth
        value={user?.gender || "M"}
        setValue={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            gender: v.toString()
          });
        }}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          height: '40px',
          paddingTop: 0.25,
          paddingBottom: 0.25,
          backgroundColor: 'transparent',
          "&:hover fieldset": {
            borderColor: 'transparent !important'
          }
        }}
      >
        <MenuItem value="M">{he.decode((t("nw.user_info.genders.male")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
        <MenuItem value="V">{he.decode((t("nw.user_info.genders.female")).replace(/<[^>]*>?/gm, ''))}</MenuItem>
      </Select>
    </Grid>
    {!!user?.birth_date && DateTime.fromFormat(user?.birth_date, "dd/LL/y") > DateTime.now().minus({year: 18}) && <Grid item xs={12}>
      <ErrorBox
        type="error"
        noTitle
        text={t("nw.emission.choice_me_new_itsme.error_age")}
      />
    </Grid>}
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="subtitle1" sx={{
        display: 'inline-block',
        fontStyle: 'italic'
      }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra_email")}</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.email || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            email: v
          });
        }}
        starred
        caption={t("nw.user_info.email")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.repeat_email || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            repeat_email: v
          });
        }}
        starred
        caption={t("nw.user_info.repeat_email")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <TextInput
        value={user?.phone || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            phone: v.toString()
          });
        }}
        starred
        caption={t("nw.user_info.phone")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_address")}</Typography>
    </Grid>
    <Grid item xs={8}>
      <TextInput
        value={user?.street || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            street: v
          });
        }}
        starred
        caption={t("nw.user_info.street")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        value={user?.number || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            number: v
          });
        }}
        starred
        caption={t("nw.user_info.number")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        value={user?.zip || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            zip: v
          });
        }}
        starred
        caption={t("nw.user_info.zip")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={8}>
      <TextInput
        value={user?.city || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            city: v
          });
        }}
        starred
        caption={t("nw.user_info.city")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_banking")}</Typography>
    </Grid>
    <Grid item xs={12}>
      <ZichtAccount
        value={user?.zicht_account || ''}
        onChange={(v) => {
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            zicht_account: v.target.value
          });
        }}
        setExternalIsOkay={setIsOkay}
        starred
        caption={t("nw.user_info.zicht_account")}
        sx={{
          borderRadius: "8px",
          width: '100%',
          border: "2px solid transparent",
          borderColor: theme.palette.primary.dark,
          paddingTop: 0.25,
          paddingBottom: 0.25,
          height: '40px',
          backgroundColor: 'transparent',
        }}
      />
    </Grid><Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_identity")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{
      display: {
        xs: 'none', md: 'flex'
      },
      "& .cust_dropzonearea > div > div": {
        flexBasis: 'auto !important',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }}>
      <DropzoneArea
        acceptedFiles={['image/gif', 'image/jpeg', 'image/png', '.pdf']}
        filesLimit={1}
        fileObjects={[]}
        dropzoneText={he.decode(t('nw.general.dropzone_text').replace(/<[^>]*>?/gm, ''))}
        onChange={(files) => {
          // this.setState({user: {...this.state.user, identity: files[0]}});
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            identity: files[0]
          });
        }}
        maxFileSize={3000000}
        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
        dropzoneClass="cust_dropzonearea"
      />
    </Grid>
    <Grid item xs={12} sx={{
      display: {
        xs: 'flex', md: 'none'
      },
      "& .cust_dropzonearea > div > div": {
        flexBasis: 'auto !important',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }}>
      <DropzoneArea
        acceptedFiles={['image/gif', 'image/jpeg', 'image/png', '.pdf']}
        filesLimit={1}
        fileObjects={[]}
        dropzoneText={he.decode(t('nw.general.dropzone_text_mobile').replace(/<[^>]*>?/gm, ''))}
        onChange={(files) => {
          // this.setState({user: {...this.state.user, identity: files[0]}});
          setUser({
            ...(user || {
              auth_token: '',
              email: ''
            }),
            identity: files[0]
          });
        }}
        maxFileSize={3000000}
        previewGridProps={{item: {style: {maxWidth: '100%', flexBasis: '100%'}}}}
        dropzoneClass="cust_dropzonearea"
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_overview")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'left'}}>
      {!(!user?.first_name || !user.last_name || !user.birth_date || !user.gender || !DateTime.fromFormat(user?.birth_date, "dd/LL/y").isValid || DateTime.fromFormat(user?.birth_date, "dd/LL/y") > DateTime.now().minus({year: 18})) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
      {!!(!user?.first_name || !user.last_name || !user.birth_date || !user.gender || !DateTime.fromFormat(user?.birth_date, "dd/LL/y").isValid || DateTime.fromFormat(user?.birth_date, "dd/LL/y") > DateTime.now().minus({year: 18})) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
      <Typography variant="subtitle1" sx={{
        marginTop: 1,
        display: 'inline-block',
        color: (!user?.first_name || !user.last_name || !user.birth_date || !user.gender || !DateTime.fromFormat(user?.birth_date, "dd/LL/y").isValid || DateTime.fromFormat(user?.birth_date, "dd/LL/y") > DateTime.now().minus({year: 18})) ? theme.palette.warning.main : theme.palette.primary.light,
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_personal")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'left'}}>
      {!(!user?.phone || user?.email !== user.repeat_email) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
      {!!(!user?.phone || user?.email !== user.repeat_email) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
      <Typography variant="subtitle1" sx={{
        marginTop: 1,
        display: 'inline-block',
        color: (!user?.phone || user?.email !== user.repeat_email) ? theme.palette.warning.main : theme.palette.primary.light,
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'left'}}>
      {!(!user?.street || !user.number || !user.zip || !user.city) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
      {!!(!user?.street || !user.number || !user.zip || !user.city) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
      <Typography variant="subtitle1" sx={{
        marginTop: 1,
        display: 'inline-block',
        color: (!user?.street || !user.number || !user.zip || !user.city) ? theme.palette.warning.main : theme.palette.primary.light,
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_address")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'left'}}>
      {!(!user?.zicht_account || !isOkay) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
      {!!(!user?.zicht_account || !isOkay) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
      <Typography variant="subtitle1" sx={{
        marginTop: 1,
        display: 'inline-block',
        color: (!user?.zicht_account || !isOkay) ? theme.palette.warning.main : theme.palette.primary.light,
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_banking")}</Typography>
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'left'}}>
      {!(!user?.identity) && <CheckCircleOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.primary.light}} />}
      {!!(!user?.identity) && <ErrorOutlineIcon sx={{marginRight: 2, marginBottom: -1, color: theme.palette.warning.main}} />}
      <Typography variant="subtitle1" sx={{
        marginTop: 1,
        display: 'inline-block',
        color: (!user?.identity) ? theme.palette.warning.main : theme.palette.primary.light,
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_itsme.title_identity")}</Typography>
    </Grid>

    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
      <TextButton
        id="back_btn"
        label={t("nw.emission.forgot.cta_back")}
        onClick={() => {
          setStep(7);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
      <DarkGreenButton
        id="next_btn"
        label={t("nw.emission.choice_me_new_itsme.cta_next")}
        loading={!!loading}
        disabled={
          !user?.first_name || !user.last_name || !user.phone || !user.zicht_account || !isOkay || !user.street || !user.number || !user.zip || !user.city || !user.birth_date || !user.identity || user?.email !== user.repeat_email || !DateTime.fromFormat(user?.birth_date, "dd/LL/y").isValid || DateTime.fromFormat(user?.birth_date, "dd/LL/y") > DateTime.now().minus({year: 18})
        }
        onClick={() => {
          onSubmit();
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1,
          color: 'white !important'
        }}
      />
    </Grid>
  </>;
}

