import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

// @ts-ignore
import HeroImg from "../../../img/cut/landing_pi2025_hero.jpg";
// @ts-ignore
import Explosion1 from "../../../img/explosion/brooke-cagle-a1keXHJZAac-unsplash.jpg";
// @ts-ignore
import Explosion2 from "../../../img/explosion/christopher-campbell-rDEOVtE7vOs-unsplash.jpg";
// @ts-ignore
import Explosion3 from "../../../img/explosion/clint-mckoy-_J2JFRHLijI-unsplash.jpg";
// @ts-ignore
import Explosion4 from "../../../img/explosion/esther-ann-glpYh1cWf0o-unsplash.jpg";
// @ts-ignore
import Explosion5 from "../../../img/explosion/linkedin-sales-solutions-Kfzfd8ksE10-unsplash.jpg";
// @ts-ignore
import Explosion6 from "../../../img/explosion/stefan-stefancik-QXevDflbl8A-unsplash.jpg";
// @ts-ignore
import {ReactComponent as Graph} from "../../../img/ArgenCo_icon_graph_rgb.svg";
// @ts-ignore
import {ReactComponent as Plus} from "../../../img/ArgenCo_icon_plus_rgb.svg";
// @ts-ignore
import {ReactComponent as Windmill} from "../../../img/ArgenCo_icon_windmill_rgb.svg";
// @ts-ignore
import Invest1 from "../../../img/cut/appolinary-kalashnikova-WYGhTLym344-unsplash.jpg";
// @ts-ignore
import Invest2 from "../../../img/cut/biel-morro-HCha-UHkIg8-unsplash.jpg";
// @ts-ignore
import Invest3 from "../../../img/cut/shane-rounce-DNkoNXQti3c-unsplash.jpg";

import {
  Avatar,
  Box,
  Grid,
  Stack,
} from '@mui/material';

import {
  Benefit,
  Cta,
  Footer,
  Hero,
  Section,
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  Page,
  Typography
} from '../../../components/v2/styled';

import { Benefit as Ben } from '../../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");
var he = require('he');

type Props = {}

export const LandingProductinformatie2025: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);


  const {
    data: benefits,
    // isLoading: adviceUploadsLoading,
    // isError: adviceUploadsError,
    isSuccess: benefitsSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "benefits"],
    queryFn: () =>
      fetch_all<Ben>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'v2_benefit',
          fields: ['id', 'created_at', 'nw_timing', 'nw_discount_tag', 'discount_name', 'get_item'],
          per_page: 4,
          order: 'weight_homepage DESC',
          filter: {
            advanced: {
              enabled: 1
            }
          }
        },
        undefined
      ),
    // enabled: false,
  });









  

  const data = [
    {
      name: '2019',
      div: 3.1,
    },
    {
      name: '2020',
      div: 1.5,
    },
    {
      name: '2021',
      div: 3.25,
    },
    {
      name: '2022',
      div: 3.30,
    },
    {
      name: '2023',
      div: 4.10,
    },
    {
      name: '2024',
      div: 4.10,
    },
  ];

  const LamzakkerijAvatar = (src: any, zIndex: number) => {
    return <Avatar
      src={src}
      sx={{
        display: 'inline-block',
        border: '3px solid transparent',
        borderColor: theme.palette.primary.light,
        position: 'relative',
        zIndex: zIndex,
        marginLeft: zIndex === 1000 ? 0 : -2,
        marginBottom: -2,
        marginRight: zIndex === 995 ? 1 : 0
      }}
    />;
  }

  const whiteBox = (Icon:any, title:string) => {
    return <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: 4,
        width: '100%',
        height: '100%',
        position: 'relative',
        paddingBottom: 8
      }}
    >
      <Icon style={{height: '64px'}} />
      <Typography variant="h3" sx={{marginTop: 4}}>{title}</Typography>
    </Box>;
  }

  const whiteBoxWithImage = (ImgSrc:any, text:string) => {
    return <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: 1,
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Grid container spacing={0} sx={{height: '100%'}}>
        <Grid item xs={6}>
          <Box sx={{
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            backgroundImage: `url(${ImgSrc})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center'
          }} />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{
            height: '200px',
            width: '100%',
            padding: 2,
            display: 'table-cell',
            verticalAlign: 'middle'
          }}>
            <Typography variant="subtitle1" html fuck_p_tags altLinks>{text}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      picRight={HeroImg}
      children={<>
        <Typography variant="h5" html fuck_p_tags>{t("nw.reclame")}</Typography>
        <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.landing.productinformatie2025.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{color: theme.palette.primary.dark}}>{t("nw.landing.productinformatie2025.hero.subsection")}</Typography>
        <Typography variant="subtitle1" sx={{color: theme.palette.primary.dark, marginTop: 3}}>
          {LamzakkerijAvatar(Explosion2, 1000)}
          {LamzakkerijAvatar(Explosion4, 999)}
          {LamzakkerijAvatar(Explosion3, 998)}
          {LamzakkerijAvatar(Explosion6, 997)}
          {LamzakkerijAvatar(Explosion5, 996)}
          {LamzakkerijAvatar(Explosion1, 995)}
          {he.decode(he.decode(t("nw.landing.productinformatie2025.hero.and_50k_others").replace(/<[^>]*>?/gm, '')))}
        </Typography>
        <Box>
          <DarkGreenButton
            label={t("nw.landing.productinformatie2025.hero.cta.subscribe")}
            id="sub_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              marginTop: 4,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
            }}
            onClick={() => {
              navigate('/buy');
            }}
          />
        </Box>
      </>}
    />

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h2" html fuck_p_tags sx={{
            textAlign: 'center',
            marginTop: 10
          }}>{t("nw.landing.productinformatie2025.blocks.title")}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {whiteBox(
            Graph,
            t("nw.landing.productinformatie2025.graph.title")
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {whiteBox(
            Windmill,
            t("nw.landing.productinformatie2025.windmill.title")
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          {whiteBox(
            Plus,
            t("nw.landing.productinformatie2025.plus.title")
          )}
        </Grid>
      </Grid>
    </Section>

    <Section normalPage>
      <Cta>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.landing.productinformatie2025.chart.title")}</Typography>
        <Box sx={{
          marginTop: 5,
          display: {
            xs: 'none',
            lg: 'block'
          }
        }}>
          <LineChart
            width={800}
            height={400}
            data={data}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} tickFormatter={(tick) => `${tick}.00%   `} />
            <Line type="linear" dataKey="div" stroke="#6FCADF" activeDot={{ r: 8 }} />
          </LineChart>
        </Box>
        <Box sx={{
          marginTop: 5,
          display: {
            xs: 'block',
            lg: 'none'
          },
          marginLeft: {
            xs: -2,
            md: 0
          }
        }}>
          <LineChart
            width={300}
            height={200}
            data={data}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} tickFormatter={(tick) => `${tick}.00%   `} />
            <Line type="linear" dataKey="div" stroke="#6FCADF" activeDot={{ r: 8 }} />
          </LineChart>
        </Box>
        <Typography sx={{
          marginTop: 5,
          textAlign: 'center'
        }} variant="body2" html fuck_p_tags>{t("nw.landing.productinformatie2025.chart.text")}</Typography>

      </Cta>
    </Section>

    <Section normalPage>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h2" html fuck_p_tags sx={{
            textAlign: 'center',
            marginTop: 10
          }}>{t("nw.landing.productinformatie2025.invest.title")}</Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          {whiteBoxWithImage('https://cdn.argenco.be/2dcv7okbn61efbftbwidxjfmlnqd', t("nw.landing.productinformatie2025.invest.argenta"))}
        </Grid>
        <Grid item xs={12} lg={6}>
          {whiteBoxWithImage(Invest3, t("nw.landing.productinformatie2025.invest.duurzaam"))}
        </Grid>
        <Grid item xs={12} lg={6}>
          {whiteBoxWithImage(Invest2, t("nw.landing.productinformatie2025.invest.zon"))}
        </Grid>
        <Grid item xs={12} lg={6}>
          {whiteBoxWithImage(Invest1, t("nw.landing.productinformatie2025.invest.wind"))}
        </Grid>
      </Grid>
    </Section>

    <Section sx={{paddingBottom: 0, backgroundColor: 'transparent'}}>
      <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.landing.productinformatie2025.benefits.title")}</Typography>
      <Stack direction="row" spacing={2} sx={{
        overflowX: 'auto',
        marginTop: 10,
        paddingBottom: 2,
        "&::-webkit-scrollbar": {
          height: '4px',
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "8px",
          boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: theme.palette.primary.light
        },
      }}>
        {benefitsSuccess && benefits.map(benefit => <Benefit benefit={benefit} sx={{
          minWidth: {xs: '90%', md: '49%', lg: '23.5%'},
          maxWidth: {xs: '90%', md: '49%', lg: '23.5%'}
        }} />)}
      </Stack>
    </Section>

    <Section normalPage>
      <Cta>
        <Typography variant="h2" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.landing.productinformatie2025.cta.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.landing.productinformatie2025.cta.text")}</Typography>
        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: 'center'
        }}>
          <LightGreenButton
            id="goto_contact"
            label={t("nw.landing.productinformatie2025.cta.cta")}
            path="/contact"
          />
        </Box>
      </Cta>
    </Section>

    <Section normalPage>
      <Box sx={{
        padding: 4,
        border: '2px solid transparent',
        borderColor: theme.palette.primary.main,
        borderRadius: '10px'
      }}>
        <Typography variant="body1" html fuck_p_tags sx={{
          textAlign: 'center',
          fontSize: '0.8rem',
          lineHeight: '18px'
        }}>{t("nw.disclaimer_long")}</Typography>
      </Box>
    </Section>

    <Footer normalPage />
  </Page>;
}

