import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom, do_logout } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

// @ts-ignore
import LogoGreen from '../../../img/Argenco_logo_green_rgb.svg';
// @ts-ignore
import LogoWhite from '../../../img/Argenco_logo_white_rgb.svg';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';

import {
  Box,
  Divider,
  Grid,
} from '@mui/material';

import {
  LightGreenButton,
  TextButton,
  TransparentButton,
  Typography,
} from '../../../components/v2/styled';

// import { User, loginFields } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  LanguagePicker: (b:boolean) => ReactElement;
  scrolled: boolean;
}

export const MenuMobile: FunctionComponent<Props> = ({LanguagePicker, scrolled}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [mobileAboutOpen, setMobileAboutOpen] = useState<boolean>(false);
  const [mobileInvestOpen, setMobileInvestOpen] = useState<boolean>(false);
  const whiteMode = window.location.pathname.indexOf("/about/mission") > -1 && !scrolled && !mobileOpen;

  // const {
  //   data: usr,
  //   // isLoading: adviceUploadsLoading,
  //   // isError: adviceUploadsError,
  //   // isSuccess: adviceUploadsSuccess,
  //   // refetch: adviceUploadsRefetch,
  // } = useQuery({
  //   queryKey: ["full_user", login?.id],
  //   queryFn: () =>
  //     fetch_one<User>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'user',
  //         fields: [...loginFields, 'last_diary_entry'],
  //         id: login?.id,
  //         ob: {
  //           id: login?.id
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!login,
  // });

  







  return <>
    <Box sx={{
      display: {
        xs: 'block',
        md: 'none',
        lg: 'none'
      },
      position: 'fixed',
      backgroundColor: (scrolled ? 'white' : 'transparent'),
      zIndex: 1299,
      width: '100%',
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 2
    }}>
      <Grid container spacing={0}>
        {/* MOBILE */}
        <Grid item xs={12} sx={{marginTop: 2, textAlign: 'right'}}>
          {/* <img src={LogoWhite} style={{width: '200px', display: (scrolled ? 'none' : 'inline-block')}} />
          <img src={LogoGreen} style={{width: '200px', display: (scrolled ? 'inline-block' : 'none')}} /> */}
          <img src={whiteMode ? LogoWhite : LogoGreen} style={{width: '200px', float: 'left', cursor: 'pointer'}} onClick={() => {navigate('/'); window.scrollTo(0, 0);}} />

          <TransparentButton
            id="mobile_menu"
            label={t("nw.menu.tablet_menu")}
            dark
            endIcon={<MenuIcon sx={{
              ...(!!whiteMode ? {
                color: 'white',
              } : {})
            }} />}
            sx={{
              marginTop: 0,
              borderColor: 'transparent',
              width: 'auto',
              ...(!!whiteMode ? {
                color: 'white',
                borderColor: 'white'
              } : {})
            }}
            onClick={() => {
              setMobileOpen(!mobileOpen);
              window.scrollTo(0, 0);
            }}
          />
        </Grid>
      </Grid>
    </Box>
    <Box sx={{
      backgroundColor: 'white',
      width: '100%',
      display: (!!mobileOpen ? 'inline-block' : 'none'),
      padding: 4,
      paddingTop: '122px',
      textAlign: 'center'
    }}>
      {!!login && <Typography variant="body2" sx={{
        textAlign: 'left'
      }}>{login?.first_name} {login?.last_name} ({login?.vennoot_number})</Typography>}
      {/* <Divider /> */}
      <TextButton
        id="invest"
        label={t("nw.menu.invest")}
        endIcon={<ExpandMoreIcon />}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          width: '100%'
        }}
        onClick={(e) => {
          setMobileInvestOpen(!mobileInvestOpen);
        }}
      />
      {!!mobileInvestOpen && <Box sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: 1
      }}>
        <TextButton
          id="invest_why"
          label={t("nw.footer.menu.invest_why")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/stock/why"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="invest_how"
          label={t("nw.footer.menu.invest_how")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/stock/how"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="transfer"
          label={t("nw.footer.menu.transfer")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/stock/transfer"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="dividends"
          label={t("nw.footer.menu.dividends")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/stock/dividend"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
      </Box>}
      <Divider />
      <TextButton
        id="benefits"
        label={t("nw.menu.benefits")}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          width: '100%'
        }}
        path="/benefits"
        onClick={(e) => {
          setMobileOpen(!mobileOpen);
          window.scrollTo(0, 0);
        }}
      />
      <Divider />
      <TextButton
        id="about"
        label={t("nw.menu.about")}
        endIcon={<ExpandMoreIcon />}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          width: '100%'
        }}
        onClick={(e) => {
          setMobileAboutOpen(!mobileAboutOpen);
        }}
      />
      {!!mobileAboutOpen && <Box sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: 1
      }}>
        <TextButton
          id="about_mission_values"
          label={t("nw.footer.menu.mission_values")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/about/mission"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="about_team_rvb"
          label={t("nw.footer.menu.team_rvb")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/about/team"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="about_history"
          label={t("nw.footer.menu.history")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/about/history"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="about_investments"
          label={t("nw.footer.menu.investments")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/about/investments"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="about_transparency"
          label={t("nw.footer.menu.transparency")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/about/transparency"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
        <TextButton
          id="about_sustainability"
          label={t("nw.footer.menu.sustainability")}
          sx={{
            marginTop: 1,
            marginBottom: 1,
            color: 'white',
            width: '100%'
          }}
          path="/about/sustainability"
          onClick={(e) => {
            setMobileOpen(!mobileOpen);
            window.scrollTo(0, 0);
          }}
        />
      </Box>}
      <Divider />
      <TextButton
        id="faq"
        label={t("nw.menu.faq")}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          width: '100%'
        }}
        path="/faq"
        onClick={(e) => {
          setMobileOpen(!mobileOpen);
          window.scrollTo(0, 0);
        }}
      />
      <Divider />
      <TextButton
        id="news"
        label={t("nw.menu.news")}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          width: '100%'
        }}
        path="/news"
        onClick={(e) => {
          setMobileOpen(!mobileOpen);
          window.scrollTo(0, 0);
        }}
      />
      <Divider />
      <TextButton
        id="contact"
        label={t("nw.menu.contact")}
        sx={{
          marginTop: 1,
          marginBottom: 1,
          width: '100%'
        }}
        path="/contact"
        onClick={(e) => {
          setMobileOpen(!mobileOpen);
          window.scrollTo(0, 0);
        }}
      />

      {!login && <TransparentButton
        id="login"
        label={t("nw.menu.login")}
        dark
        sx={{
          marginTop: 3,
          width: '100%'
        }}
        path="/login"
        onClick={() => {
          setMobileOpen(!mobileOpen);
          window.scrollTo(0, 0);
        }}
      />}
      {!login && <LightGreenButton
        id="register"
        label={t("nw.menu.register")}
        sx={{
          marginTop: 2,
          width: '100%'
        }}
        path="/buy"
        onClick={() => {
          setMobileOpen(!mobileOpen);
          window.scrollTo(0, 0);
        }}
      />}
      {!!login && <TransparentButton
        id="log_out"
        label={t("nw.menu.logout")}
        sx={{
          marginTop: 3,
          marginLeft: 'auto',
          width: '100%'
        }}
        onClick={() => {
          do_logout((result) => {
            setLogin(result);
            window.location.reload();
          });
        }}
      />}
      {!!login && <LightGreenButton
        id="my_account"
        label={t("nw.menu.my_account")}
        sx={{
          marginTop: 2,
          width: '100%'
        }}
        path="/profile"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      />}
      <Box sx={{
        marginTop: 2,
        width: '100%',
        textAlign: 'left'
      }}>
        <Typography variant="body2">{t("nw.menu.mobile.lang_choice").replace(/<[^>]*>?/gm, '')}&nbsp;&nbsp;&nbsp;&nbsp;{LanguagePicker(false)}</Typography>
      </Box>
      <TextButton
        id="close"
        label={t("nw.menu.close")}
        startIcon={<CloseIcon />}
        sx={{
          margin: 1
        }}
        onClick={(e) => {
          setMobileOpen(false);
        }}
      />
    </Box>
  </>;
}

