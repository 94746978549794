import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom, do_logout } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

// @ts-ignore
import LogoGreen from '../../../img/Argenco_logo_green_rgb.svg';
// @ts-ignore
import LogoWhite from '../../../img/Argenco_logo_white_rgb.svg';
// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_green_rgb.svg";

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';

import {
  Box,
  Divider,
  Grid,
} from '@mui/material';

import {
  LightGreenButton,
  TextButton,
  TransparentButton,
  Typography
} from '../../../components/v2/styled';

// import { User, loginFields } from '../../../models/User';

// const { DateTime } = require("luxon");

type Props = {
  LanguagePicker: (b:boolean, white?: boolean) => ReactElement;
  scrolled: boolean;
}

export const MenuMobileInternal: FunctionComponent<Props> = ({LanguagePicker, scrolled}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [tabletOpen, setTabletOpen] = useState<boolean>(false);
  const [tabletAboutOpen, setTabletAboutOpen] = useState<boolean>(false);
  const [tabletInvestOpen, setTabletInvestOpen] = useState<boolean>(false);
  const whiteMode = window.location.pathname.indexOf("/about/mission") > -1 && !scrolled;

  // const {
  //   data: usr,
  //   // isLoading: adviceUploadsLoading,
  //   // isError: adviceUploadsError,
  //   // isSuccess: adviceUploadsSuccess,
  //   // refetch: adviceUploadsRefetch,
  // } = useQuery({
  //   queryKey: ["full_user", login?.id],
  //   queryFn: () =>
  //     fetch_one<User>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'user',
  //         fields: [...loginFields, 'last_diary_entry'],
  //         id: login?.id,
  //         ob: {
  //           id: login?.id
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!login,
  // });

  







  return <>
    <Box sx={{
      display: {
        xs: 'block',
        md: 'none',
        lg: 'none'
      },
      position: 'fixed',
      backgroundColor: theme.palette.background.default,
      zIndex: 1299,
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0
    }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sx={{
          textAlign: 'left',
          backgroundColor: theme.palette.secondary.light,
          height: '40px',
          lineHeight: '40px',
          paddingLeft: 3,
        }}>
          <Box sx={{
            display: 'inline-block',
            cursor: 'pointer',
            "& svg": {
              transform: `rotate(-90deg)`,
              height: '26px',
              marginRight: 1,
              marginBottom: '-8px'
            }
          }} onClick={() => {
            navigate("/");
          }}><Arrow /></Box>
          <Typography variant="body2" onClick={() => {
            navigate("/");
          }} sx={{
            display: 'inline',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}>{t("nw.menu.go_back_to_homepage")}</Typography>
        </Grid>
        {/* <Grid item xs={8} sx={{
          textAlign: 'right',
          height: '40px',
          lineHeight: '40px',
          paddingLeft: 1,
          paddingRight: 3,
          backgroundColor: theme.palette.secondary.light
        }}>
          {LanguagePicker(tabletOpen, !!whiteMode)}
        </Grid> */}
        {/* TABLET */}
        <Grid item xs={12} sx={{marginTop: 1.5, textAlign: 'right', paddingLeft: 3, paddingRight: 3}}>
          {/* <img src={LogoWhite} style={{width: '200px', display: (scrolled ? 'none' : 'inline-block')}} />
          <img src={LogoGreen} style={{width: '200px', display: (scrolled ? 'inline-block' : 'none')}} /> */}
          <img src={whiteMode ? LogoWhite : LogoGreen} style={{width: '200px', float: 'left', cursor: 'pointer'}} onClick={() => {navigate('/'); window.scrollTo(0, 0);}} />

          <TransparentButton
            id="tablet_menu"
            label={t("nw.menu.tablet_menu")}
            dark
            endIcon={<MenuIcon sx={{
              ...(!!whiteMode ? {
                color: 'white',
              } : {})
            }} />}
            sx={{
              marginTop: -1,
              marginBottom: 1,
              marginLeft: 1,
              ...(!!whiteMode ? {
                color: 'white',
                borderColor: 'white'
              } : {}),
              width: 'auto',
              borderColor: 'white'
            }}
            onClick={() => {
              setTabletOpen(!tabletOpen);
              window.scrollTo(0, 0);
            }}
          />
        </Grid>
      </Grid>
    </Box>
    <Box sx={{
      backgroundColor: 'white',
      width: '100%',
      display: (!!tabletOpen ? 'inline-block' : 'none'),
      padding: 4,
      paddingTop: '122px',
      textAlign: 'center'
    }}>
      <Divider />
      <TextButton
        id="overview"
        label={t("nw.menu.profile.overview")}
        sx={{
          margin: 1,
          width: '100%'
        }}
        path="/profile"
      />
      <Divider />
      <TextButton
        id="benefits"
        label={t("nw.menu.profile.benefits")}
        sx={{
          margin: 1,
          width: '100%'
        }}
        path="/benefits"
      />
      <Divider />
      <TextButton
        id="data"
        label={t("nw.menu.profile.data")}
        sx={{
          margin: 1,
          width: '100%'
        }}
        path="/edit_profile"
      />
      <Divider />
      <TextButton
        id="documents"
        label={t("nw.menu.profile.documents")}
        sx={{
          margin: 1,
          width: '100%'
        }}
        path="/my_documents"
      />
      <Divider />
      <TextButton
        id="transactions"
        label={t("nw.menu.profile.transactions")}
        sx={{
          margin: 1,
          width: '100%'
        }}
        path="/my_transactions"
      />
      <Divider />
      <TextButton
        id="close"
        label={t("nw.menu.close")}
        startIcon={<CloseIcon />}
        sx={{
          margin: 1,
          width: '100%'
        }}
        onClick={(e) => {
          setTabletOpen(false);
        }}
      />
      <Box sx={{
        marginTop: 2,
        width: '100%',
        textAlign: 'left'
      }}>
        <Typography variant="body2">{t("nw.menu.mobile.lang_choice").replace(/<[^>]*>?/gm, '')}&nbsp;&nbsp;&nbsp;&nbsp;{LanguagePicker(false)}</Typography>
      </Box>
    </Box>
  </>;
}

