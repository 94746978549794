import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { fetch_all_with_count, fetch_all } from "../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';

// import Lenis from "lenis";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { gsap } from "gsap";

import {
  CircularProgress,
  Grid,
} from '@mui/material';
// import {
//   WhiteSection
// } from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  TextButton,
  TextInput,
  TransparentButton,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';
import { WideButton } from '../../../components/v2/styled/WideButton';

// const { DateTime } = require("luxon");

type Props = {
  setStep: (n:number) => void;
  user?: User;
}

export const EmissionChoiceMeNew: FunctionComponent<Props> = ({setStep, user}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  return <>
    <Grid item xs={12} sx={{textAlign: 'center'}}>
      <Typography variant="h3" sx={{
        marginTop: 4,
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me_new_user.title")}</Typography>
    </Grid>
    {/* <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
      <Typography variant="subtitle1" html fuck_p_tags sx={{
        display: 'inline-block',
        // maxWidth: {
        //   xs: '100%',
        //   lg: '440px'
        // }
      }}>{t("nw.emission.choice_me.subsection")}</Typography>
    </Grid> */}
    <Grid item xs={12}>
      <WideButton
        label={t("nw.emission.choice_me_new.itsme")}
        id="new_itsme"
        itsme
        onClick={(e) => {
          //staging
          //@ts-ignore
          // window.location = `https://oidc.e2e.itsme.services/clientsecret-oidc/csapi/v0.1/connect/authorize?client_id=VPBa0vzFYw&response_type=code&scope=openid service:ARGENCOSB_SHAREDATA profile email address phone&redirect_uri=https://api.argenco.be/itsme/redirection&ui_locales=${((i18n.resolvedLanguage || 'nl').length < 1 ? 'nl' : (i18n.resolvedLanguage || 'nl'))}`;
          //production
          //@ts-ignore
          window.location = `https://oidc.prd.itsme.services/clientsecret-oidc/csapi/v0.1/connect/authorize?client_id=fqmwcZDgzF&response_type=code&scope=openid service:ARGENCOPRD_SHAREDATA profile email address phone&redirect_uri=https://api.argenco.be/itsme/redirection&ui_locales=${((i18n.resolvedLanguage || 'nl').length < 1 ? 'nl' : (i18n.resolvedLanguage || 'nl'))}&state=`;
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <WideButton
        label={t("nw.emission.choice_me_new.no_itsme")}
        id="new_itsme"
        onClick={(e) => {
          setStep(9);
          window.scrollTo(0, 0);
          // @ts-ignore
          if (!!window.dataLayer) window.dataLayer.push({
            event: 'emission',
            eventProps: {
              category: 'buying_for_me',
              action: 'not_a_member_no_itsme',
              label: 'success',
              value: 1
            }
          });
        }}
      />
    </Grid>
    <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
      <TextButton
        id="back_btn"
        label={t("nw.emission.forgot.cta_back")}
        onClick={() => {
          setStep(5);
          window.scrollTo(0, 0);
        }}
        sx={{
          marginLeft: 1,
          marginRight: 1,
          marginTop: 1
        }}
      />
    </Grid>
  </>;
}

