import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import {
  Box,
  Divider,
  Grid,
  MenuItem,
} from '@mui/material';
import {
  ProfileBox
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  IconButton,
  Page,
  Select,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';
import { Birthdate } from '../../../components/v2/styled/Birthdate';
import { ErrorBox } from '../../../components/v2/styled/ErrorBox';
import { ZichtAccount } from '../../../components/v2/styled/ZichtAccount';
import { CloudDownload } from '@mui/icons-material';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {}

export const ProfileMyDocuments: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [user, setUser] = useState<User>();
  

  const {
    data: tempUser,
    // isLoading: fullBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: tempUserSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "user", 'docs', login?.id],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'files_nw'],
          id: login?.id
        },
        login
      ),
    enabled: !!login?.id,
  });







  const tableRow = (label: string, created: string, url: string, year: number|undefined, divider: boolean) => {
    return <>
      <Grid item xs={4} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'left'
      }}>
        <Typography sx={{
          textTransform: 'uppercase',
          color: theme.palette.primary.light
        }}>{created}</Typography>
      </Grid>
      <Grid item xs={6} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        },
        textAlign: 'left'
      }}>
        <Typography variant="body1">{he.decode(label.replace(/<[^>]*>?/gm, ''))} {!!year ? year : ''}</Typography>
      </Grid>
      <Grid item xs={2} sx={{
        paddingTop: {
          xs: 1,
          md: 1,
          lg: 2
        },
        paddingBottom: {
          xs: 1,
          md: 1,
          lg: 2
        },
        paddingRight: {
          xs: 1,
          md: 1,
          lg: 2
        },
        textAlign: 'right'
      }}>
        <IconButton
          onClick={() => {
            window.open(url, "_BLANK");
          }}
        >
          <CloudDownload />
        </IconButton>
      </Grid>

      {!!divider && <Grid item xs={12}>
        <Divider />
      </Grid>}
    </>;
  }
  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    if (!!tempUser) setUser(tempUser);
  }, [tempUser]);

  return <Page sx={{paddingTop: '100px', backgroundColor: theme.palette.background.default}}>
    <Grid container spacing={2.5} sx={{
      width: {
        xs: '100%',
        lg: '900px'
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
      marginBottom: 8
    }}>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Grid container spacing={2.5} sx={{
          filter: !login?.id ? 'blur(4px)' : 'blur(0)',
          position: 'relative',
          zIndex: 1100
        }}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '100%',
                  lg: '670px'
                },
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: 'white'
              }}
            >
              <Grid container spacing={0}>
                {(user?.files_nw || []).map(file => tableRow(t(file[1]), file[0], file[2], file[3], true))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Page>;
}

