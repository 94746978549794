import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import HeroImg from "../../../img/cut/ArgenCo_Investeren_Aandelen_Overdragen_1024px.jpg";
// @ts-ignore
import StappenImg from "../../../img/cut/ArgenCo_AandelenOverdragen_Stappen_1024px.jpg";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
} from '@mui/material';

import {
  Cta,
  Footer,
  Hero,
  Section,
  WhiteSection
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  LightGreenButton,
  Page,
  TransparentButton,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

var he = require('he');
// const { DateTime } = require("luxon");

type Props = {}

export const StockTransfer: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [isOpen, setIsOpen] = useState(false);






  const greenBox = (num:number, title:string, text:string) => {
    return <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '10px',
        padding: 4,
        width: '100%',
        height: '100%',
        position: 'relative'
      }}
    >
      <Typography variant="h3" sx={{
        fontSize: '78px',
        lineHeight: 'normal',
        marginBottom: 4
      }}>{num}</Typography>
      <Typography variant="h3" sx={{marginBottom: 4}}>{title}</Typography>
      <Typography variant="body1" sx={{color: theme.palette.primary.dark}}>{text}</Typography>
    </Box>;
  }
  const numCircle = (num:number) => {
    return <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '40px',
        padding: 1,
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        lineHeight: '16px',
        textAlign: 'center',
        color: 'white',
        marginRight: 2.5
      }}
    >
      {num}
    </Box>;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      picLeft={HeroImg}
      children={<>
        <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.stock.transfer.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{marginBottom: 4, color: theme.palette.primary.dark}}>{t("nw.stock.transfer.hero.subsection")}</Typography>
        <Box>
          <DarkGreenButton
            label={t("nw.stock.transfer.hero.cta.subscribe")}
            id="sub_btn"
            sx={{
              borderColor: theme.palette.primary.dark,
              "&:hover": {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
              },
            }}
            onClick={() => {
              // navigate('/login');
              // TODO
              setIsOpen(true);
            }}
          />
        </Box>
      </>}
    />

    <WhiteSection picRight={StappenImg}>
      <Typography variant="h1" sx={{marginBottom: 4}}>{t("nw.stock.transfer.steps.title")}</Typography>
      <List sx={{
        paddingLeft: 0,
        listStyle: 'none',
        marginBottom: 4,
        "& li": {
          paddingLeft: 0
        }
      }} component="ol">
        <ListItem>{numCircle(1)} <Typography variant="subtitle1">{t("nw.stock.transfer.steps.li1")}</Typography></ListItem>
        <ListItem>{numCircle(2)} <Typography variant="subtitle1">{t("nw.stock.transfer.steps.li2")}</Typography></ListItem>
        <ListItem>{numCircle(3)} <Typography variant="subtitle1">{t("nw.stock.transfer.steps.li3")}</Typography></ListItem>
        <ListItem>{numCircle(4)} <Typography variant="subtitle1">{t("nw.stock.transfer.steps.li4")}</Typography></ListItem>
        <ListItem>{numCircle(5)} <Typography variant="subtitle1">{t("nw.stock.transfer.steps.li5")}</Typography></ListItem>
      </List>
    </WhiteSection>

    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
          <Typography variant="h2" sx={{marginTop: 8}}>{t("nw.stock.transfer.why.title")}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {greenBox(
            1,
            t("nw.stock.transfer.why_1.title"),
            t("nw.stock.transfer.why_1.text")
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {greenBox(
            2,
            t("nw.stock.transfer.why_2.title"),
            t("nw.stock.transfer.why_2.text")
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          {greenBox(
            3,
            t("nw.stock.transfer.why_3.title"),
            t("nw.stock.transfer.why_3.text")
          )}
        </Grid>
      </Grid>
    </WhiteSection>

{/* TODO */}
    <Section normalPage>
      <Cta>
        <Typography variant="h2" html fuck_p_tags sx={{textAlign: 'center'}}>{t("nw.stock.transfer.sharing.title")}</Typography>
        <Typography sx={{
          marginTop: {
            xs: 2,
            md: 3
          },
          textAlign: 'center'
        }} variant="body1" html fuck_p_tags>{t("nw.stock.transfer.sharing.text")}</Typography>

        <Box sx={{
          marginTop: 4,
          width: '100%',
          textAlign: {
            xs: 'left',
            md: 'center'
          }
        }}>
          {!login && <LightGreenButton
            id="sharing_login"
            label={t("nw.stock.transfer.sharing.cta_login")}
            path="/login"
          />}
          {!login && <TransparentButton
            id="sharing_register"
            label={t("nw.stock.transfer.sharing.cta_register")}
            sx={{
              marginLeft: {
                xs: 0,
                md: 2.5
              },
              marginTop: {
                xs: 2.5,
                md: 0
              }
            }}
            path="/first_time"
          />}
          {!!login && <LightGreenButton
            id="sharing_cta"
            label={t("nw.stock.transfer.sharing.sharing_cta")}
            onClick={() => {
              setIsOpen(true);
            }}
          />}
        </Box>
      </Cta>
    </Section>

    <Footer normalPage />

    <Dialog
      open={isOpen}
      disableScrollLock
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogTitle><Typography>{t("nw.general.dialog.under_construction_title")}</Typography></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography html>{t("nw.general.dialog.under_construction_text")}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setIsOpen(false);
        }}>{he.decode(t("nw.general.dialog.under_construction_cta").replace(/<[^>]*>?/gm, ''))}</Button>
      </DialogActions>
    </Dialog>
  </Page>;
}

