import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";
// @ts-ignore
import {ReactComponent as ArrowDark} from "../../../img/Argenco_pijl_green_rgb.svg";
// @ts-ignore
import ImgLevi from "../../../img/cut/ArgenCo_Levi_CEO.jpg";
// @ts-ignore
import {ReactComponent as Plus} from "../../../img/ArgenCo_icon_plus_rgb.svg";
// @ts-ignore
import {ReactComponent as Graph} from "../../../img/ArgenCo_icon_graph_rgb.svg";
// @ts-ignore
import {ReactComponent as House} from "../../../img/ArgenCo_icon_house_rgb.svg";
// @ts-ignore
import {ReactComponent as Clover} from "../../../img/ArgenCo_icon_clover_rgb.svg";

import {
  Box
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  MutedGreenButton,
  ShareShower,
  Typography
} from '../styled';

import { EmissionKey as EK } from '../../../models/EmissionKey';


type Props = {
  onClick: () => void;
  isPlus?: boolean;
  isHouse?: boolean;
  isGraph?: boolean;
  isClover?: boolean;
  title?: string;
  text?: string;
  cta?: string;
  sx?: any;
};

export const ProfileBox: FunctionComponent<Props> = ({sx, onClick, isPlus, isHouse, isGraph, isClover, title, text, cta}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [stopped, setStopped] = useState(true);

  return (<>
    <Box
      sx={{
        position: 'relative',
        zIndex: 1200,
        cursor: 'pointer',
        ...sx
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
    >
      <Lottie
        options={{
          loop: false,
          autoplay: false, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{
          position: 'absolute',
          right: 32,
          bottom: 32,
          zIndex: 1205
        }}
        height={34}
        width={34}
        isClickToPauseDisabled
        isStopped={stopped}
      />

      <Box sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        width: '100%',
        padding: 4,
        height: '370px',
        // background: `no-repeat url(${ImgLevi})`,
        // backgroundSize: 'cover',
        backgroundColor: 'white'
      }}>
        {!!isPlus && <Plus style={{height: '48px'}} />}
        {!!isGraph && <Graph style={{height: '48px'}} />}
        {!!isHouse && <House style={{height: '48px'}} />}
        {!!isClover && <Clover style={{height: '48px'}} />}
        <Typography variant="h3" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'initial',
          display: '-webkit-box',
          '-webkit-line-clamp': '3',
          '-webkit-box-orient': 'vertical',
          textTransform: 'none',
          marginTop: 2
        }}>{title}</Typography>
        <Typography variant="body1" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'initial',
          display: '-webkit-box',
          '-webkit-line-clamp': '3',
          '-webkit-box-orient': 'vertical',
          textTransform: 'none',
          marginTop: 2
        }}>{text}</Typography>

        <MutedGreenButton
          id="goto"
          label={cta || ''}
          onClick={() => {
            onClick();
          }}
          sx={{
            position: 'absolute',
            bottom: 32,
            left: 32
          }}
        />
      </Box>
    </Box>
  </>);
}