export const LOGIN_URL = '/v3/users/login';
export const LOGOUT_URL = '/v3/users/logout';
export const RENEW_URL = '/v3/users/renew_sign_in';
//
export const loginFields = [
  'id', 'first_name', 'last_name', 'auth_token', 'email', 'extra_data', 'type', 'mfa_status', 'vennoot_number', 'stock_number_amount'
];

export type User = {
  id?: number;
  auth_token: string;
  created_at?: string;
  email: string;
  repeat_email?: string;
  first_name?: string;
  last_name?: string;
  vennoot_number?: string;
  birth_date?: string;
  phone?: string;
  mobile?: string;
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  zicht_account?: string;
  gender?: string;
  identity?: File;
  office_number?: string;
  fsma_number?: string;
  is_office?: boolean;
  share_type_tag?: 'A'| 'B';
  stock_number_amount?: number;
  available_stock_number?: number;
  stock_number?: number;
  cor_street?: string;
  cor_number?: string;
  cor_zip?: string;
  cor_city?: string;
  commercial_mail_enabled?: boolean;
  username?: string;

  itsme_sub?: string;
  has_guardian?: boolean;

  max_shares_left?: number;

  mfa_status?: number;
  otp_provisioning?: string;
  sec_q_status?: number;
  sq1_id?: number;
  sq1_answer?: string;
  sq2_id?: number;
  sq2_answer?: string;
  sq3_id?: number;
  sq3_answer?: string;

  readonly files_nw?: [date:string, name:string, url: string, year?: number][];
  readonly transactions?: any[];

  readonly main_asn_key?: string;
}
