import React, { ChangeEvent, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import InputMask from "@mona-health/react-input-mask";

import HelpIcon from '@mui/icons-material/Help';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  TextInput,
  Typography,
} from '../styled';
import { Icon, InputAdornment, InputBase, TextFieldProps, Tooltip } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

var he = require('he');

type Props = {
  noCaption?: boolean;
  backend?: boolean;
  starred?: boolean;
  caption?: string;
  placeholder?: string;
  helperIcon?: string | ReactElement;
  type?: string;
  sx?: object;
  icon?: string;
  endIcon?: ReactElement;
  muiIcon?: ReactElement;
  error?: string;
  helperText?: string;
  id?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  value: string;
  ref?: any;
  margin?: 'dense'|undefined;
  onChange: (z:ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (z:any) => void;
  multiline?: boolean;
  setExternalIsOkay?: (b:boolean) => void;
};

export const ZichtAccount: FunctionComponent<Props> = ({onKeyDown, starred, endIcon, ref, disabled, required, margin, muiIcon, value, onChange, autoFocus, autoComplete, id, noCaption, backend, caption, placeholder, helperIcon, type, sx, icon, error, helperText, multiline, setExternalIsOkay}) => {
  const theme = useTheme();
  
  const [actualType, setActualType] = useState<string>(type || 'text');
  const [isOkay, setIsOkay] = useState<boolean>(false);

  useEffect(() => {
    setIsOkay(false);
    if (!!setExternalIsOkay) setExternalIsOkay(false);

    let iban = value.toUpperCase().replace(/[^A-Z0-9]/g, '');
    let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    let digits;

    if (!!code && !!code[1] && !!code[2] && !!code[3]) {
      // @ts-ignore
      digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
      });
      var checksum = digits.slice(0, 2), fragment;
      for (var offset = 2; offset < digits.length; offset += 7) {
        fragment = String(checksum) + digits.substring(offset, offset + 7);
        // @ts-ignore
        checksum = parseInt(fragment, 10) % 97;
      }
      // @ts-ignore
      if (checksum === 1) {
        setIsOkay(true);
        if (!!setExternalIsOkay) setExternalIsOkay(true);
      }
    }
  }, [value]);

  return (<>
    {!noCaption && !!backend && (<Typography variant="caption" sx={{
      marginTop: 1,
      marginRight: 1
    }}>{he.decode(((!!caption ? caption : placeholder) || '').replace(/<[^>]*>?/gm, ''))}{starred && " *"}</Typography>)}

    {!noCaption && !backend && !!caption && (<Typography variant="caption" sx={{opacity: 0.5}}>{he.decode((caption).replace(/<[^>]*>?/gm, ''))}{starred && " *"}</Typography>)}

    {!!helperIcon && (<Tooltip title={helperIcon}><HelpIcon sx={{
      float: 'right',
      color: theme.palette.primary.main
    }} /></Tooltip>)}

    <InputMask
      mask={[/[A-Z]/i, /[A-Z]/i, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      value={value}
      onChange={onChange}
    >
      <InputBase
        id={id}
        name={id}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        placeholder={he.decode((placeholder || '').replace(/<[^>]*>?/gm, ''))}
        onKeyDown={(e) => {if (!!onKeyDown) onKeyDown(e);}}
        margin={margin}
        required={required}
        multiline={multiline}
        rows={3}
        ref={ref}
        // {..._.omit(this.props, "icon", "error", "helperText", "type")}
        type={actualType}
        sx={{
          border: "0.5px solid transparent",
          backgroundColor: "rgb(244,248,247)",
          padding: `8px 16px`,
          width: "100%",
          borderRadius: "8px",
          marginTop: 3,
          "&.error": {
            border: "0.5px solid rgba(255, 0, 0, 0.5)"
          },
          ...(!!caption ? {
            marginTop: 0
          } : {}),
          ...(!!error ? {
            borderColor: theme.palette.secondary.main
          } : {}),
          ...sx
        }}
        endAdornment={<InputAdornment position="end">
          {isOkay && <CheckCircleOutline
            sx={{
              color: theme.palette.primary.light
            }}
          />}
          {!isOkay && <ErrorOutline
            sx={{
              color: theme.palette.warning.main
            }}
          />}
          </InputAdornment>}
        ></InputBase>
    </InputMask>

  {!!error && <Typography variant="caption" sx={{
    color: theme.palette.secondary.main,
    marginTop: 1,
    marginRight: 1
  }}>{error}</Typography>}

  {!!helperText && <Typography variant="caption" sx={{
    marginTop: 1,
    marginRight: 1
  }}>{helperText}</Typography>}
  </>);
}