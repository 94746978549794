import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";
// @ts-ignore
import {ReactComponent as ArrowDark} from "../../../img/Argenco_pijl_green_rgb.svg";
// @ts-ignore
import ImgLevi from "../../../img/cut/ArgenCo_Levi_CEO.jpg";
// @ts-ignore
import {ReactComponent as Plus} from "../../../img/ArgenCo_icon_plus_rgb.svg";
// @ts-ignore
import {ReactComponent as Graph} from "../../../img/ArgenCo_icon_graph_rgb.svg";
// @ts-ignore
import {ReactComponent as House} from "../../../img/ArgenCo_icon_house_rgb.svg";
// @ts-ignore
import {ReactComponent as Clover} from "../../../img/ArgenCo_icon_clover_rgb.svg";

import {
  Box
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  MutedGreenButton,
  ShareShower,
  Typography
} from '../styled';

import { EmissionKey as EK } from '../../../models/EmissionKey';


type Props = {
  emissionKey?: EK;
  isNew?: boolean;
  isApproved?: boolean;
  onClick: () => void;
  shareTypeTag: 'A' | 'B';
  sx?: any;
};

export const EmissionKeyAlt1: FunctionComponent<Props> = ({emissionKey, isNew, isApproved, sx, onClick, shareTypeTag}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [stopped, setStopped] = useState(true);

  return (<>
    <Box
      sx={{
        position: 'relative',
        zIndex: 1200,
        cursor: 'pointer',
        ...sx
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
    >
      <Lottie
        options={{
          loop: false,
          autoplay: false, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{
          position: 'absolute',
          right: 32,
          bottom: 32,
          zIndex: 1205
        }}
        height={34}
        width={34}
        isClickToPauseDisabled
        isStopped={stopped}
      />

      {/* {!!(emissionKey?.status || {})[i18n.resolvedLanguage || 'nl'] && <Box sx={{
        padding: '8px 12px',
        backgroundColor: theme.palette.primary.light,
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 1204,
        borderRadius: '8px'
      }}>
        <Typography variant="body2" sx={{
          textTransform: 'uppercase'
        }}>{(benefit?.nw_discount_tag || {})[i18n.resolvedLanguage || 'nl']}</Typography>
      </Box>} */}

      {/* {!!(benefit?.nw_timing || {})[i18n.resolvedLanguage || 'nl'] && <Box sx={{
        padding: '16px',
        position: 'absolute',
        top: 47,
        left: 0,
        zIndex: 1204,
        borderRadius: '8px',
        height: 193,
        width: 193,
        overflow: 'hidden'
      }}>
        <Box sx={{
          position: 'absolute',
          bottom: -275,
          left: -212,
          "& svg": {
            width: '400px'
          },
          zIndex: 1203,
          transform: "rotate(90deg)"
        }}><Arrow /></Box>
        <Box sx={{
          position: 'absolute',
          top: 70,
          left: 8,
          "& svg": {
            width: '34px',
            height: '34px'
          },
          zIndex: 1205,
          transform: "rotate(90deg)"
        }}><ArrowDark /></Box>
        <Typography variant="body1" sx={{
          textTransform: 'uppercase',
          marginTop: '112px',
          width: '75%',
          position: 'relative',
          zIndex: 1205,
        }}>{(benefit?.nw_timing || {})[i18n.resolvedLanguage || 'nl']}</Typography>
      </Box>} */}

      <Box sx={{
        borderRadius: '10px',
        overflow: 'hidden',
        width: '100%',
        padding: 4,
        height: '370px',
        // background: `no-repeat url(${ImgLevi})`,
        // backgroundSize: 'cover',
        backgroundColor: 'white'
      }}>
        {!!isNew && <Plus style={{height: '48px'}} />}
        {!isNew && <Graph style={{height: '48px'}} />}
        {/* {!!isApproved && <House style={{height: '48px'}} />} */}
        {!!isApproved && <Clover style={{height: '48px'}} />}
        {!!isNew && <Typography variant="h3" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'initial',
          display: '-webkit-box',
          '-webkit-line-clamp': '3',
          '-webkit-box-orient': 'vertical',
          textTransform: 'none',
          marginTop: 1
        }}>{t("nw.emission.stocks.add_order")}</Typography>}

        {!isNew && !!emissionKey && <>
          <Typography variant="h5" sx={{
            marginTop: 1
          }}>{t("nw.emission.stocks.card.order_time")}</Typography>
          <Typography variant="body1" sx={{
            marginTop: 0,
            paddingLeft: 2
          }}>{emissionKey.time_requested}</Typography>

          <Typography variant="h5" sx={{
            marginTop: 1
          }}>{t("nw.emission.stocks.card.amount")}</Typography>
          <ShareShower variant="body1" amount={emissionKey.proposed_amount || 0} share={shareTypeTag} sx={{
            marginTop: 0,
            paddingLeft: 2
          }} />

          <Typography variant="h5" sx={{
            marginTop: 1
          }}>{t("nw.emission.stocks.card.status")}</Typography>
          <Typography variant="body1" sx={{
            marginTop: 0,
            paddingLeft: 2
          }}>{emissionKey.status}</Typography>

          <Typography variant="body2" sx={{
            marginTop: 1,
            paddingLeft: 2
          }}>{emissionKey.status_updated}</Typography>
        </>}

        <MutedGreenButton
          id="goto"
          label={!!isNew ? t("nw.emission.stocks.card.new_order") : t("nw.emission.stocks.card.view_order")}
          onClick={() => {
            onClick();
          }}
          sx={{
            position: 'absolute',
            bottom: 32,
            left: 32
          }}
        />
      </Box>
    </Box>
  </>);
}