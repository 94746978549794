import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../../img/lottie/240187_ARG_Website_Arrow.json'

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";

import {
  Box,
  List,
  ListItem
} from '@mui/material';

// import {
//   Cta,
//   Footer,
//   Section
// } from '../custom';
import {
  TransparentButton,
  Typography
} from '../styled';

import { Faq } from '../../../models/Faq';

type Props = {
  id: number;
  name: string;
  faqs: Faq[];
  sx?: any;
  search?: string;
};

export const FaqBlock: FunctionComponent<Props> = ({id, name, faqs, sx, search}) => {
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();

  const [stopped, setStopped] = useState(true);
  const [ctrlActive, setCtrlActive] = useState(false);
  let path = `/faq/${id}`;
  if (!!search) path = `${path}?s=${search}`

  const liArrow = () => {
    return <Box sx={{
      "& svg": {
        transform: "rotate(90deg)",
        width: '24px',
        marginBottom: -1
      },
      marginRight: 2.5
    }}><Arrow /></Box>
  }

  return (<>
    <Box
      sx={{
        width: '100%',
        borderRadius: '10px',
        backgroundColor: 'white',
        padding: 4,
        position: 'relative',
        cursor: 'pointer',
        zIndex: 1200,
        ...sx
      }}
      onMouseEnter={() => { setStopped(false) }} 
      onMouseLeave={() => { setStopped(true) }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!path) {
          if (ctrlActive || e.ctrlKey || e.button === 1) {
            if (!!path) window.open(path, "_BLANK");
          } else {
            if (!!path) navigate(path);
          }
          window.scrollTo(0, 0);
          setCtrlActive(false);
        }
      }} 
      onAuxClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (!!path) window.open(path, "_BLANK");
      }}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.button == 1) {
          if (!!path) window.open(path, "_BLANK");
        }
      }}
      onKeyUp={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(false);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Meta") {
          setCtrlActive(true);
        }
      }}
    >
      <Lottie
        options={{
          loop: false,
          autoplay: false, 
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        style={{
          position: 'absolute',
          right: 32,
          bottom: 32,
          zIndex: 1205
        }}
        height={34}
        width={34}
        isClickToPauseDisabled
        isStopped={stopped}
      />

      <Typography variant="h3" sx={{height: '48px'}}>{name}</Typography>
      <Box sx={{
        marginTop: 1,
        height: '176px',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1101
      }}>
        <Box sx={{
          width: '100%',
          height: '100%',
          background: `linear-gradient(180deg, transparent 0%, transparent 60%, white 90%, white 100%)`,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1101
        }} />
        <List sx={{
          paddingLeft: 0,
          listStyle: 'none',
          "& li": {
            paddingLeft: 0
          }
        }} component="ul">
          {faqs.map(fff => <ListItem>{liArrow()} <Typography variant="body1">{fff.alias_title}</Typography></ListItem>)}
        </List>
      </Box>
      <TransparentButton
        id="check_all"
        label={t("nw.cta.check_all")}
        sx={{
          marginTop: 4,
          backgroundColor: theme.palette.secondary.light,
          borderColor: 'transparent',
          "&:hover": {
            backgroundColor: theme.palette.secondary.light
          }
        }}
        path={path}
      />
    </Box>
  </>);
}