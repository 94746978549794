import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

import { fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

import { Add, Edit } from '@mui/icons-material';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import {
  ProfileBox
} from '../../../components/v2/custom';
import {
  DarkGreenButton,
  Page,
  Select,
  TextButton,
  TextInput,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
import { User } from '../../../models/User';
import { Birthdate } from '../../../components/v2/styled/Birthdate';
import { ErrorBox } from '../../../components/v2/styled/ErrorBox';
import { ZichtAccount } from '../../../components/v2/styled/ZichtAccount';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {}

export const ProfileEditProfile: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [user, setUser] = useState<User>();
  const [hasCor, setHasCor] = useState<boolean>(false);
  const [editingAddress, setEditingAddress] = useState<boolean>(false);
  const [editingCorAddress, setEditingCorAddress] = useState<boolean>(false);
  const [isOkay, setIsOkay] = useState<boolean>(false);
  

  const {
    data: tempUser,
    // isLoading: fullBenefitLoading,
    // isError: adviceUploadsError,
    isSuccess: tempUserSuccess,
    // refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["nw", "user", 'data', login?.id],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'commercial_mail_enabled', 'username', 'first_name', 'last_name', 'birth_date', 'email', 'phone', 'mobile', 'zicht_account', 'street', 'number', 'zip', 'city', 'cor_street', 'cor_number', 'cor_zip', 'cor_city'],
          id: login?.id
        },
        login
      ),
    enabled: !!login?.id,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: any
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      navigate('/profile');
    },
  });








  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);
  useEffect(() => {
    if (!!tempUser) {
      setUser({
        ...tempUser,
        birth_date: DateTime.fromISO(tempUser?.birth_date).toFormat("dd/LL/y")
      });
      setHasCor(!!tempUser?.cor_street && !!tempUser?.cor_number);
    }
  }, [tempUser]);

  return <Page sx={{paddingTop: '100px', backgroundColor: theme.palette.background.default}}>
    <Grid container spacing={2.5} sx={{
      width: {
        xs: '100%',
        lg: '900px'
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
      marginBottom: 8
    }}>
      <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_personal")}</Typography>
      </Grid>
      {/* <Grid item xs={12} sx={{textAlign: 'center', paddingTop: 4}}>
        <Typography variant="subtitle1" html fuck_p_tags sx={{
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me.subsection")}</Typography>
      </Grid> */}
      <Grid item xs={5} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.first_name || ''}
          disabled
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              first_name: v.toString()
            });
          }}
          starred
          caption={t("nw.user_info.first_name")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={7} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.last_name || ''}
          disabled
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              last_name: v.toString()
            });
          }}
          starred
          caption={t("nw.user_info.last_name")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={7} sx={{paddingRight: 2.5}}>
        <Birthdate
          value={user?.birth_date || ''}
          disabled
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              birth_date: v.target.value
            });
          }}
          starred
          caption={t("nw.user_info.birth_date")}
          // placeholder={t("nw.contact.form.placeholder.first_name")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
        <Typography variant="subtitle1" sx={{
          display: 'inline-block',
          fontStyle: 'italic'
        }}>{t("nw.emission.choice_me_new_itsme.title_contact_extra_email")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.email || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              email: v
            });
          }}
          starred
          caption={t("nw.user_info.email")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.phone || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              phone: v.toString()
            });
          }}
          starred
          caption={t("nw.user_info.phone")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.mobile || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              mobile: v.toString()
            });
          }}
          starred
          caption={t("nw.user_info.mobile")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{paddingRight: 2.5}}>
        <FormControlLabel sx={{
          marginTop: 2.5
        }} control={<Checkbox
          checked={!!user?.commercial_mail_enabled}
          onChange={(e) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              commercial_mail_enabled: !!e.target.checked
            });
          }}
        />} label={t("nw.edit_profile.newsletter").replace(/<[^>]*>?/gm, '')} />
      </Grid>
      <Grid item xs={12} md={6} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.username || ''}
          disabled
          onChange={(v) => {
            
          }}
          starred
          caption={t("nw.user_info.username")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_address")}</Typography>
        {!editingAddress && <Edit onClick={() => {
          setEditingAddress(true);
        }} sx={{
          marginLeft: 2,
          marginBottom: -0.5,
          cursor: 'pointer'
        }} />}
      </Grid>
      <Grid item xs={8} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.street || ''}
          disabled={!editingAddress}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              street: v
            });
          }}
          starred
          caption={t("nw.user_info.street")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.number || ''}
          disabled={!editingAddress}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              number: v
            });
          }}
          starred
          caption={t("nw.user_info.number")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={4} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.zip || ''}
          disabled={!editingAddress}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              zip: v
            });
          }}
          starred
          caption={t("nw.user_info.zip")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      <Grid item xs={8} sx={{paddingRight: 2.5}}>
        <TextInput
          value={user?.city || ''}
          disabled={!editingAddress}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              city: v
            });
          }}
          starred
          caption={t("nw.user_info.city")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>
      {!!hasCor && <>
        <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
          <Typography variant="h3" sx={{
            marginTop: 4,
            display: 'inline-block',
            // maxWidth: {
            //   xs: '100%',
            //   lg: '440px'
            // }
          }}>{t("nw.emission.choice_me_new_itsme.title_cor_address")}</Typography>
          {!editingCorAddress && <Edit onClick={() => {
            setEditingCorAddress(true);
          }} sx={{
            marginLeft: 2,
            marginBottom: -0.5,
            cursor: 'pointer'
          }} />}
        </Grid>
        <Grid item xs={8} sx={{paddingRight: 2.5}}>
          <TextInput
            value={user?.cor_street || ''}
            disabled={!editingCorAddress}
            onChange={(v) => {
              setUser({
                ...(user || {
                  auth_token: '',
                  email: ''
                }),
                cor_street: v
              });
            }}
            starred
            caption={t("nw.user_info.street")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={4} sx={{paddingRight: 2.5}}>
          <TextInput
            value={user?.cor_number || ''}
            disabled={!editingCorAddress}
            onChange={(v) => {
              setUser({
                ...(user || {
                  auth_token: '',
                  email: ''
                }),
                cor_number: v
              });
            }}
            starred
            caption={t("nw.user_info.number")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={4} sx={{paddingRight: 2.5}}>
          <TextInput
            value={user?.cor_zip || ''}
            disabled={!editingCorAddress}
            onChange={(v) => {
              setUser({
                ...(user || {
                  auth_token: '',
                  email: ''
                }),
                cor_zip: v
              });
            }}
            starred
            caption={t("nw.user_info.zip")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
        <Grid item xs={8} sx={{paddingRight: 2.5}}>
          <TextInput
            value={user?.cor_city || ''}
            disabled={!editingCorAddress}
            onChange={(v) => {
              setUser({
                ...(user || {
                  auth_token: '',
                  email: ''
                }),
                cor_city: v
              });
            }}
            starred
            caption={t("nw.user_info.city")}
            sx={{
              borderRadius: "8px",
              width: '100%',
              border: "2px solid transparent",
              borderColor: theme.palette.primary.dark,
              paddingTop: 0.25,
              paddingBottom: 0.25,
              height: '40px',
              backgroundColor: 'transparent',
            }}
          />
        </Grid>
      </>}
      {!hasCor && <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
        <TextButton
          id="add_cor"
          label={t("nw.edit_profile.cta_add_cor")}
          startIcon={<Add />}
          onClick={() => {
            setHasCor(true);
            setEditingCorAddress(true);
          }}
        />
      </Grid>}
      <Grid item xs={12} sx={{textAlign: 'center', paddingRight: 2.5}}>
        <Typography variant="h3" sx={{
          marginTop: 4,
          display: 'inline-block',
          // maxWidth: {
          //   xs: '100%',
          //   lg: '440px'
          // }
        }}>{t("nw.emission.choice_me_new_itsme.title_banking")}</Typography>
      </Grid>
      <Grid item xs={12} sx={{paddingRight: 2.5}}>
        <ZichtAccount
          value={user?.zicht_account || ''}
          onChange={(v) => {
            setUser({
              ...(user || {
                auth_token: '',
                email: ''
              }),
              zicht_account: v.target.value
            });
          }}
          setExternalIsOkay={setIsOkay}
          starred
          caption={t("nw.user_info.zicht_account")}
          sx={{
            borderRadius: "8px",
            width: '100%',
            border: "2px solid transparent",
            borderColor: theme.palette.primary.dark,
            paddingTop: 0.25,
            paddingBottom: 0.25,
            height: '40px',
            backgroundColor: 'transparent',
          }}
        />
      </Grid>





      <Grid item xs={12} sx={{textAlign: 'center', marginTop: 4, paddingRight: {xs: 0, md: 2.5}}}>
        <TextButton
          id="back_btn"
          label={t("nw.emission.forgot.cta_back")}
          path="/profile"
          sx={{
            marginLeft: 1,
            marginRight: 1,
            marginTop: 1,
            width: 'auto'
          }}
        />
        <DarkGreenButton
          id="next_btn"
          label={t("nw.emission.choice_me_new_itsme.cta_next")}
          loading={!!mutationCustomAction.isPending}
          disabled={
            !user?.phone || !user.zicht_account || !isOkay || !user.street || !user.number || !user.zip || !user.city
          }
          onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'user',
                class_action: 'custom_api_save_profile',
                id: login?.id,
                ob: {
                  id: login?.id,
                  street: user?.street,
                  number: user?.number,
                  zip: user?.zip,
                  city: user?.city,
                  cor_street: user?.cor_street,
                  cor_number: user?.cor_number,
                  cor_zip: user?.cor_zip,
                  cor_city: user?.cor_city,
                  email: user?.email,
                  phone: user?.phone,
                  mobile: user?.mobile,
                  zicht_account: user?.zicht_account,
                  commercial_mail_enabled: user?.commercial_mail_enabled
                }
              }
            });
          }}
          sx={{
            marginLeft: 1,
            marginRight: 1,
            marginTop: 1,
            color: 'white !important'
          }}
        />
      </Grid>
    </Grid>
  </Page>;
}

