import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { getCookie, setCookie } from 'react-use-cookie';

// import { loginAtom, renew_sign_in } from '../../lib/auth';
// import { useAtom } from 'jotai';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Box,
  Checkbox,
  Divider
} from '@mui/material';
import {
  WhiteSection
} from '../custom';
import {
  DarkGreenButton,
  TransparentButton,
  Typography
} from '../styled';

// const { DateTime } = require("luxon");

type Props = {
  onChosen?: (style: string, isCkAnal: boolean, isCkFunc: boolean, isCkMark: boolean) => void;
};

export const CookieNotice: FunctionComponent<Props> = ({onChosen}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);
  
  const [isOpen, setIsOpen] = useState(false);
  const [isCkAnal, setIsCkAnal] = useState(false);
  const [isCkFunc, setIsCkFunc] = useState(false);
  const [isCkMark, setIsCkMark] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isStyle, setIsStyle] = useState('');







  const setCustom = () => {
    // // @ts-ignore
    // if (!!window.dataLayer) {
    //   // @ts-ignore
    //   window.dataLayer.push({
    //     security_storage: true,
    //     analytics_storage: !!isCkAnal ? true : 'denied', // anal
    //     functionality_storage: !!isCkFunc ? true : 'denied', // func
    //     personalization_storage: !!isCkFunc ? true : 'denied', // func
    //     ad_storage: !!isCkMark ? true : 'denied', // mark
    //     ad_userdata: !!isCkMark ? true : 'denied', // mark
    //     ad_personalization: !!isCkMark ? true : 'denied' // mark
    //   });
    // }
    // setCookie('argenco_www_cookie', 'custom');
    // setIsStyle('custom');
    if (!!onChosen) onChosen(`custom|${!!isCkAnal ? 1 : 0}|${!!isCkFunc ? 1 : 0}|${!!isCkMark ? 1 : 0}`, !!isCkAnal, !!isCkFunc, !!isCkMark);
    setIsHidden(true);
  }
  const setEssential = () => {
    // // @ts-ignore
    // if (!!window.dataLayer) {
    //   // @ts-ignore
    //   window.dataLayer.push({
    //     security_storage: true,
    //     analytics_storage: 'denied', // anal
    //     functionality_storage: 'denied', // func
    //     personalization_storage: 'denied', // func
    //     ad_storage: 'denied', // mark
    //     ad_userdata: 'denied', // mark
    //     ad_personalization: 'denied' // mark
    //   });
    // }
    // setCookie('argenco_www_cookie', 'essential');
    // setIsStyle('essential');
    if (!!onChosen) onChosen('essential', false, false, false);
    setIsHidden(true);
  }
  const setFull = () => {
    // // @ts-ignore
    // if (!!window.dataLayer) {
    //   // @ts-ignore
    //   window.dataLayer.push({
    //     security_storage: true,
    //     analytics_storage: true, // anal
    //     functionality_storage: true, // func
    //     personalization_storage: true, // func
    //     ad_storage: true, // mark
    //     ad_userdata: true, // mark
    //     ad_personalization: true // mark
    //   });
    // }
    // setCookie('argenco_www_cookie', 'full');
    // setIsStyle('full');
    if (!!onChosen) onChosen('full', true, true, true);
    setIsHidden(true);
  }


  return (<Box data-lenis-prevent sx={{
    ...(!!isHidden ? {
      display: 'none'
    } : {}),
    maxWidth: {
      xs: '100%',
      lg: '480px'
    },
    maxHeight: {
      xs: '100dvh',
      md: '450px'
    },
    height: {
      xs: '100dvh',
      md: '450px'
    },
    overflowY: 'scroll',
    width: '480px',
    padding: 4,
    borderRadius: '10px',
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    left: {xs: 0, md: '50%'},
    marginLeft: {xs: 0, md: '-240px'},
    zIndex: 9999,
    ...(!isOpen ? {
      paddingBottom: '90px'
    } : {
      paddingTop: '90px'
    })
  }}>
    {!isOpen && <>
      <Typography variant="h3">{t("nw.cookie_notice.title")}</Typography>
      <Typography variant="body1" html altLinks sx={{
        marginTop: 2,
        marginBottom: 2
      }}>{t("nw.cookie_notice.text")}</Typography>
      <DarkGreenButton
        id='continue_full'
        label={t("nw.cookie_notice.cta_full")}
        onClick={() => {
          setFull();
        }}
        sx={{
          width: '100%'
        }}
      />
      <TransparentButton
        id='continue_ess'
        label={t("nw.cookie_notice.cta_essential")}
        onClick={() => {
          setEssential();
        }}
        sx={{
          width: '100%',
          marginTop: 1
        }}
      />
    </>}

    <Box sx={{
      position: 'absolute',
      ...(!isOpen ? {
        bottom: 0,
        borderRadius: '0px 0px 10px 10px'
      } : {
        top: 0,
        borderRadius: '10px 10px 0px 0px'
      }),
      left: 0,
      padding: 2,
      textAlign: 'center',
      width: '100%',
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.light
    }} onClick={() => {
      setIsOpen(!isOpen);
    }}>
      <Typography variant="body1">{t("nw.cookie_notice.cta_configure")}</Typography>
      {!isOpen && <ExpandMoreIcon sx={{
        position: 'absolute',
        bottom: 16,
        right: 20
      }} />}
      {!!isOpen && <ExpandLessIcon sx={{
        position: 'absolute',
        bottom: 16,
        right: 20
      }} />}
    </Box>

    {!!isOpen && <Box sx={{
      height: 'auto',
      // overflowY: 'scroll'
    }}>
      <Box sx={{
        position: 'relative',
        paddingRight: 2
      }}>
        <Typography variant="h3">{t("nw.cookie_notice.title_ess")}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks sx={{
          marginTop: 2,
        }}>{t("nw.cookie_notice.text_ess")}</Typography>
        <Checkbox disabled checked sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }} checkedIcon={<CheckIcon />} />
      </Box>

      <Divider sx={{
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
        marginTop: 2,
        marginBottom: 2
      }} />

      <Box sx={{
        position: 'relative',
        paddingRight: 2
      }}>
        <Typography variant="h3">{t("nw.cookie_notice.title_anal")}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks sx={{
          marginTop: 2,
        }}>{t("nw.cookie_notice.text_anal")}</Typography>
        <Checkbox sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }} onChange={(v) => {
          setIsCkAnal(!!v.target.checked);
        }} checked={!!isCkAnal} checkedIcon={<CheckIcon />} icon={<CheckBoxOutlineBlankIcon sx={{color: theme.palette.secondary.light}} />} />
      </Box>

      <Divider sx={{
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
        marginTop: 2,
        marginBottom: 2
      }} />

      <Box sx={{
        position: 'relative',
        paddingRight: 2
      }}>
        <Typography variant="h3">{t("nw.cookie_notice.title_func")}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks sx={{
          marginTop: 2,
        }}>{t("nw.cookie_notice.text_func")}</Typography>
        <Checkbox sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }} onChange={(v) => {
          setIsCkFunc(!!v.target.checked);
        }} checked={!!isCkFunc} checkedIcon={<CheckIcon />} icon={<CheckBoxOutlineBlankIcon sx={{color: theme.palette.secondary.light}} />} />
      </Box>

      <Divider sx={{
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
        marginTop: 2,
        marginBottom: 2
      }} />

      <Box sx={{
        position: 'relative',
        paddingRight: 2
      }}>
        <Typography variant="h3">{t("nw.cookie_notice.title_mark")}</Typography>
        <Typography variant="body1" html fuck_p_tags altLinks sx={{
          marginTop: 2,
        }}>{t("nw.cookie_notice.text_mark")}</Typography>
        <Checkbox sx={{
          position: 'absolute',
          top: 0,
          right: 0
        }} onChange={(v) => {
          setIsCkMark(!!v.target.checked);
        }} checked={!!isCkMark} checkedIcon={<CheckIcon />} icon={<CheckBoxOutlineBlankIcon sx={{color: theme.palette.secondary.light}} />} />
      </Box>

      <DarkGreenButton
        id='save_cstm'
        label={t("nw.cookie_notice.cta_save")}
        onClick={() => {
          setCustom();
        }}
        sx={{
          width: '100%',
          marginTop: 2
        }}
      />
    </Box>}
  </Box>);
}
