import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import JumboDesktop from "../../../img/cut/Argenco_Missie_1024px.jpg";
// @ts-ignore
import ImgConventional from "../../../img/cut/ArgenCo_Argenta_Hoofdkantoor.jpg";
// @ts-ignore
// import ImgKapitaal from "../../../img/cut/ArgenCo_Missie_Voor_Iedereen_1024px.jpg";
// @ts-ignore
import ImgKapitaal from "../../../img/Free stock images/People/jason-goodman-CDq4ChZouXw-unsplash.jpg"
// @ts-ignore
import ImgMission from "../../../img/cut/ArgenCo_Ecologische_Initiatieven.jpg";
// @ts-ignore
import ImgSpecialist from "../../../img/cut/ArgenCo_Levi_CEO.jpg";
// @ts-ignore
import ImgSpecialistMd from "../../../img/levi_md.jpg";

import {
  Box,
  Container,
  Grid,
} from '@mui/material';

import {
  Footer,
  WhiteSection
} from '../../../components/v2/custom';
import {
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AboutMission: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page jumbo={<>
    <Grid item xs={12} sx={{padding: '0px !important', position: 'relative', height: {xs: '1000px', md: '100dvh'}}}>
      <Box sx={{
        display: {xs: 'block', md: 'none', lg: 'none'},
        background: `no-repeat url(${JumboDesktop})`,
        backgroundSize: 'cover',
        width: '100vw',
        height: '265px',
        backgroundPositionY: '50%',
        borderRadius: '0px 0px 16px 16px',
        position: 'relative',
        zIndex: 1201
      }} />
      <Box sx={{display: {xs: 'none', md: 'block', lg: 'none'}}}><img src={JumboDesktop} style={{minWidth: '100vw', minHeight: '100dvh', width: '100vw'}} /></Box>
      <Box sx={{display: {xs: 'none', md: 'none', lg: 'block'}}}><img src={JumboDesktop} style={{minWidth: '100vw', minHeight: '100dvh', width: '100vw'}} /></Box>
      <Container sx={{
        position: 'absolute', 
        // bottom: {
        //   xs: '',
        //   md: 500
        // }, 
        top: {
          xs: 255,
          md: 5
        },
        left: 0, 
        zIndex: 1200, 
        width: '100%', 
        maxWidth: '100% !important', 
        backgroundColor: {xs: theme.palette.secondary.light, md: 'transparent'},
        paddingBottom: {
          xs: 15,
          md: 0
        },
        borderRadius: {
          xs: '0px 0px 16px 16px',
          md: 0
        },
      }}>
        <Container fixed>
          <Typography sx={{
            marginTop: {
              xs: 10,
              md: 24
            },
            color: {
              xs: theme.palette.primary.main,
              md: 'white'
            }
          }} variant="h1" html fuck_p_tags>{t("nw.about.mission.jumbo.title")}</Typography>
          <Typography sx={{
            marginTop: {
              xs: 2,
              md: 3
            },
            color: {
              xs: theme.palette.primary.main,
              md: 'white'
            },
            width: {
              xs: '100%',
              md: '60%',
              lg: '40%'
            }
          }} variant="subtitle1" html fuck_p_tags>{t("nw.about.mission.jumbo.intro")}</Typography>
        </Container>
      </Container>
    </Grid>
  </>}>
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      },
      "& > div > div > div > div": {
        minHeight: '200px'
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="subtitle1" html fuck_p_tags sx={{
            display: 'inline-block',
            paddingTop: {
              xs: 4,
              lg: 8
            },
            paddingBottom: {
              xs: 4,
              lg: 8
            },
            maxWidth: {
              xs: '100%',
              lg: '670px'
            }
          }}>{t("nw.about.mission.section.intro")}</Typography>
        </Grid>
      </Grid>
    </WhiteSection>

    <WhiteSection picLeft={ImgKapitaal}>
      <Typography variant="h1" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{t("nw.about.mission.capital.title")}</Typography>
      <Typography variant="subtitle1" html fuck_p_tags altLinks>{t("nw.about.mission.capital.body")}</Typography>
    </WhiteSection>

    <WhiteSection picRight={ImgConventional}>
      <Typography variant="h1" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{t("nw.about.mission.conventional.title")}</Typography>
      <Typography variant="subtitle1" html fuck_p_tags altLinks>{t("nw.about.mission.conventional.body")}</Typography>
    </WhiteSection>

    <WhiteSection picLeft={ImgSpecialistMd} sx={{
      display: {
        xs: 'none',
        md: 'block',
        lg: 'none'
      }
    }}>
      <Typography variant="h1" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{t("nw.about.mission.specialist.title")}</Typography>
      <Typography variant="subtitle1" html fuck_p_tags altLinks>{t("nw.about.mission.specialist.body")}</Typography>
    </WhiteSection>
    <WhiteSection picLeft={ImgSpecialist} sx={{
      display: {
        xs: 'block',
        md: 'none',
        lg: 'block'
      }
    }}>
      <Typography variant="h1" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{t("nw.about.mission.specialist.title")}</Typography>
      <Typography variant="subtitle1" html fuck_p_tags altLinks>{t("nw.about.mission.specialist.body")}</Typography>
    </WhiteSection>

    <WhiteSection picRight={ImgMission}>
      <Typography variant="h1" sx={{marginBottom: 4, color: theme.palette.primary.light}}>{t("nw.about.mission.mission.title")}</Typography>
      <Typography variant="subtitle1" html fuck_p_tags altLinks>{t("nw.about.mission.mission.body")}</Typography>
    </WhiteSection>

    <Footer normalPage />
  </Page>;
}

