import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// import { loginAtom, renew_sign_in } from '../../lib/auth';
// import { useAtom } from 'jotai';

import {
  Box
} from '@mui/material';
import {
  WhiteSection
} from '../custom';
import {
  Typography
} from '../styled';

// const { DateTime } = require("luxon");

type Props = {
  text?: string;
  content?: any;
  type: 'success' | 'error';
  noTitle?: boolean;
  sx?: any;
};

export const ErrorBox: FunctionComponent<Props> = ({text, type, noTitle, content, sx}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  const theme = useTheme();
  const {t, i18n} = useTranslation(['translations']);

  return (<Box sx={{
    maxWidth: {
      xs: '100%',
      lg: '440px'
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 2.5,
    borderRadius: '6px',
    backgroundColor: type === 'error' ? "#FBEFF0":  theme.palette.secondary.light,
    ...sx
  }}>
    {!noTitle && type === 'error' && <Typography variant="subtitle1" sx={{marginBottom: 1, fontWeight: 'bold'}}>{t("nw.public.general_error_title")}</Typography>}
    <Typography variant="body1" html fuck_p_tags altLinks>{text}</Typography>
    {!!content && content}
  </Box>);
}
