import React, { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { getCookie } from 'react-use-cookie';
import {Helmet} from "react-helmet";

import { loginAtom } from '../lib/auth';

import '../i18n.ts';

import { AboutHistory } from './pub/about/History';
import { AboutInvestmentDetail } from './pub/about/InvestmentDetail';
import { AboutInvestments } from './pub/about/Investments';
import { AboutMission } from './pub/about/Mission';
import { AboutSustainability } from './pub/about/Sustainability';
import { AboutTeam } from './pub/about/Team';
import { AboutTransparency } from './pub/about/Transparency';
import { BenefitDetail } from './pub/BenefitDetail';
import { Benefits } from './pub/Benefits';
import { Contact } from './pub/Contact';
import { Emission } from './pub/Emission';
import { EmissionLoader } from './pub/emission/Loader';
import { EmissionStocks } from './pub/emission/Stocks';
import { FaqDetail } from './pub/FaqDetail';
import { Faqs } from './pub/Faqs';
import { Homepage } from './pub/Homepage';
import { LandingProductinformatie2025 } from './pub/landing/Productinformatie2025';
import { Login } from './pub/Login';
import { NewsDetail } from './pub/NewsDetail';
import { News } from './pub/News';
import { Profile } from './pub/Profile';
import { ProfileEditProfile } from './pub/profile/EditProfile';
import { ProfileMyDocuments } from './pub/profile/MyDocuments';
import { ProfileMyTransactions } from './pub/profile/MyTransactions';
import { Raffle } from './pub/Raffle';
import { Register } from './pub/Register';
import { StockDividend } from './pub/stock/Dividend';
import { StockHow } from './pub/stock/How';
import { StockTransfer } from './pub/stock/Transfer';
import { StockWhy } from './pub/stock/Why';

import { SharedImpersonateLoader } from './shared/ImpersonateLoader';
import { SharedLayout } from './shared/Layout';

import {
  Box,
  CssBaseline
} from '@mui/material';

// import { Practice } from '../models/Practice';

type Props = {}

export const Main: FunctionComponent<Props> = () => {
  const login = useAtomValue(loginAtom);

  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="google" content="notranslate" />
      <meta name="robots" content="notranslate" />
      <meta name="googlebot" content="notranslate" />
      <title>Argen-Co</title>
      <meta property="og:url" content="https://www.argenco.be/" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Argenta Coöperatieve CV" />
      <meta property="og:description" content="Argen-co investeert vandaag in lokale, duurzame projecten die onze wereld voorbereiden op de uitdagingen van morgen. Onze vennoten helpen ons daarbij, alle 60.000." />
      <meta property="og:image" content="https://old.argenco.be/cdn/support/assets/items/000/000/744/original/jumbotron.jpg" />
    </Helmet>
    <CssBaseline></CssBaseline>
    {/* <Box sx={{
      display: "flex",
      // minHeight: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}></Box> */}

    <SharedLayout loginId={login?.id}>
      <Routes>
        <Route path='/' element={<Homepage />} />
        
        <Route path='/stock/why' element={<StockWhy />} />
        <Route path='/stock/how' element={<StockHow />} />
        <Route path='/stock/transfer' element={<StockTransfer />} />
        <Route path='/stock/dividend' element={<StockDividend />} />
        <Route path='/stock' element={<Navigate to="/stock/why" replace />} />

        <Route path='/benefits' element={<Benefits />} />
        <Route path='/benefit/:id' element={<BenefitDetail />} />

        <Route path='/about/mission' element={<AboutMission />} />
        <Route path='/about/team' element={<AboutTeam />} />
        <Route path='/about/history' element={<AboutHistory />} />
        <Route path='/about/investments' element={<AboutInvestments />} />
        <Route path='/about/investment/:id' element={<AboutInvestmentDetail />} />
        <Route path='/about/transparency' element={<AboutTransparency />} />
        <Route path='/about/sustainability' element={<AboutSustainability />} />

        <Route path='/faq' element={<Faqs />} />
        <Route path='/faq/:id' element={<FaqDetail />} />

        <Route path='/news' element={<News />} />
        <Route path='/news/:id' element={<NewsDetail />} />

        <Route path='/contact' element={<Contact />} />

        {!login?.id && <Route path='/login' element={<Login />} />}
        {!login?.id && <Route path='/first_time' element={<Register />} />}
        {!login?.id && <Route path='/impersonate/:token' element={<SharedImpersonateLoader />} />}

        {!!login?.id && <Route path='/profile' element={<Profile />} />}
        {!!login?.id && <Route path='/edit_profile' element={<ProfileEditProfile />} />}
        {!!login?.id && <Route path='/my_documents' element={<ProfileMyDocuments />} />}
        {!!login?.id && <Route path='/my_transactions' element={<ProfileMyTransactions />} />}
        <Route path='/buy' element={<Emission />} />
        <Route path='/emission' element={<Emission />} />
        <Route path='/emissions/registered/:sub' element={<EmissionLoader />} />
        <Route path='/emissions/stocks/:id' element={<EmissionStocks />} />

        <Route path='/raffle/:id' element={<Raffle />} />

        <Route path='/productinformatie2025' element={<LandingProductinformatie2025 />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </SharedLayout>
  </>);
}

