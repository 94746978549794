import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import { useQuery } from "@tanstack/react-query";
// import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_all } from "../../../lib/v31lib";
// import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../lib/practice';

import Lenis from "lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";

// @ts-ignore
import {ReactComponent as Arrow} from "../../../img/Argenco_pijl_eco_rgb.svg";
// @ts-ignore
import ImgBabs from "../../../img/babs.jpeg";
// @ts-ignore
import ImgGael from "../../../img/groep_gael.jpeg";
// @ts-ignore
import ImgLevi from "../../../img/cut/ArgenCo_Levi_CEO.jpg";
// @ts-ignore
import ImgBabsMd from "../../../img/babs_md.png";
// @ts-ignore
import ImgGaelMd from "../../../img/groep_gael_md.jpeg";
// @ts-ignore
import ImgLeviMd from "../../../img/levi_md.jpg";

import {
  Box,
  Divider,
  Grid,
} from '@mui/material';

import {
  Footer,
  Hero,
  WhiteSection
} from '../../../components/v2/custom';
import {
  Page,
  Typography
} from '../../../components/v2/styled';

// import { Benefit as Ben } from '../../models/Benefit';
// import { NewsItem as NI } from '../../models/NewsItem';
// import { User, loginFields } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AboutTeam: FunctionComponent<Props> = ({}) => {
  // const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);







  const tableRow = (label: string, content: string, divider: boolean) => {
    return <>
      <Grid item xs={4} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingLeft: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }}>
        <Typography variant="body1" sx={{
          color: theme.palette.primary.main,
          fontWeight: 'bold'
        }}>{label}</Typography>
      </Grid>
      <Grid item xs={8} sx={{
        paddingTop: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingBottom: {
          xs: 1,
          md: 2,
          lg: 3
        },
        paddingRight: {
          xs: 1,
          md: 2,
          lg: 3
        }
      }}>
      <Typography variant="body2" sx={{
        color: theme.palette.primary.main,
        textAlign: 'right'
      }}>{content}</Typography>
      </Grid>

      {!!divider && <Grid item xs={12}>
        <Divider />
      </Grid>}
    </>;
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lenis = new Lenis({
      // duration: 2,
    });
    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  }, []);

  return <Page>
    <Hero
      children={<>
        <Typography variant="h1" sx={{
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.about.team.hero.title")}</Typography>
        <Typography variant="subtitle1" sx={{
          color: theme.palette.primary.dark,
          marginTop: 4,
          maxWidth: {
            xs: '100%',
            lg: '670px'
          },
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>{t("nw.about.team.hero.subsection")}</Typography>
      </>}
    />
    
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h2" html fuck_p_tags sx={{
            textAlign: 'center',
            marginTop: 10
          }}>{t("nw.about.team.section.team.title")}</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box sx={{
            width: '100%',
            height: {
              xs: '400px',
              md: '600px',
              lg: '400px'
            },
            backgroundSize: 'cover',
            borderRadius: '16px',
            backgroundImage: {
              xs: `url(${ImgLevi})`,
              md: `url(${ImgLeviMd})`,
              lg: `url(${ImgLevi})`
            }
          }} />
          <Typography variant="h2" html fuck_p_tags sx={{
            marginTop: 2,
            color: theme.palette.primary.light,
            height: '67px'
          }}>{t("nw.about.team.section.team.levi.name")}</Typography>
          <Typography variant="body1" html fuck_p_tags sx={{
            marginTop: 1
          }}>{t("nw.about.team.section.team.levi.title")}</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box sx={{
            width: '100%',
            height: {
              xs: '400px',
              md: '600px',
              lg: '400px'
            },
            backgroundSize: 'cover',
            borderRadius: '16px',
            backgroundImage: {
              xs: `url(${ImgGael})`,
              md: `url(${ImgGaelMd})`,
              lg: `url(${ImgGael})`
            }
          }} />
          <Typography variant="h2" html fuck_p_tags sx={{
            marginTop: 2,
            color: theme.palette.primary.light,
            height: '67px'
          }}>{t("nw.about.team.section.team.gael.name")}</Typography>
          <Typography variant="body1" html fuck_p_tags sx={{
            marginTop: 1
          }}>{t("nw.about.team.section.team.gael.title")}</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box sx={{
            width: '100%',
            height: {
              xs: '400px',
              md: '600px',
              lg: '400px'
            },
            backgroundSize: 'cover',
            borderRadius: '16px',
            backgroundImage: {
              xs: `url(${ImgBabs})`,
              md: `url(${ImgBabsMd})`,
              lg: `url(${ImgBabs})`
            }
          }} />
          <Typography variant="h2" html fuck_p_tags sx={{
            marginTop: 2,
            color: theme.palette.primary.light,
            height: '67px'
          }}>{t("nw.about.team.section.team.babs.name")}</Typography>
          <Typography variant="body1" html fuck_p_tags sx={{
            marginTop: 1
          }}>{t("nw.about.team.section.team.babs.title")}</Typography>
        </Grid>
      </Grid>
    </WhiteSection>
    
    <WhiteSection isTransparent sx={{
      "& > div > div": {
        padding: 0
      }
    }}>
      <Grid container spacing={2.5}>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
          <Typography variant="h2" html fuck_p_tags sx={{
            textAlign: 'center',
            marginTop: 6
          }}>{t("nw.about.team.section.rvb.title")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '100%',
                lg: '670px'
              },
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <Grid container spacing={0}>
              {tableRow(t("nw.about.team.section.rvb.voorzitter.name"), t("nw.about.team.section.rvb.voorzitter.title"), true)}
              {tableRow(t("nw.about.team.section.rvb.bestuurder1.name"), t("nw.about.team.section.rvb.bestuurder1.title"), true)}
              {tableRow(t("nw.about.team.section.rvb.bestuurder2.name"), t("nw.about.team.section.rvb.bestuurder2.title"), true)}
              {tableRow(t("nw.about.team.section.rvb.bestuurder3.name"), t("nw.about.team.section.rvb.bestuurder3.title"), true)}
              {tableRow(t("nw.about.team.section.rvb.bestuurder4.name"), t("nw.about.team.section.rvb.bestuurder4.title"), false)}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </WhiteSection>

    <Footer normalPage />
  </Page>;
}

