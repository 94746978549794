import { FunctionComponent } from 'react';

// import { loginAtom, renew_sign_in } from '../../lib/auth';
// import { useAtom } from 'jotai';
import React from 'react';

import {
  Typography
} from '../styled';

var he = require('he');
const { DateTime } = require("luxon");

type Props = {
  variant?: 'subtitle1' | 'body1';
  share: 'A' | 'B';
  asString?: boolean;
  amount?: number;
  sx?: any;
};

export const ShareShower: FunctionComponent<Props> = ({variant, share, asString, amount, sx}) => {
  // const [login, setLogin] = useAtom(loginAtom);

  let unit_price = {
    "A": 100,
    "B": 50
  }[share]

  return (!!asString ? he.decode(`${(amount || 0)} (${(((amount || 0) * unit_price))} EUR)`) : (<Typography sx={{...sx}} variant={variant || 'body1'}>
    {he.decode(`${(amount || 0)} (${(((amount || 0) * unit_price))} EUR)`)}
  </Typography>));
}
